import DetailCorporateList from '@/delivery/ui/admin/data_corporate/corporate_list/detail/detail_corporate_list.tsx';
import { CorporateDetailProvider } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_detail_context.tsx';

const DetailCorporatePage = () => {
  return (
    <div>
      <CorporateDetailProvider>
        <DetailCorporateList />
      </CorporateDetailProvider>
    </div>
  );
};

export default DetailCorporatePage;
