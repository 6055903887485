import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import { useCorporateDashboardContext } from '@/delivery/ui/corporate/dashboard/context/corporate_dashboard_context.tsx';
import CorporateDashboard from '@/delivery/ui/corporate/dashboard/corporate_dashboard.tsx';

const CorporateDashboardPage = () => {
  const { corporateDashboard } = useCorporateDashboardContext();
  return (
    <div>
      <Navbar label={corporateDashboard?.name ? `Selamat datang, ${corporateDashboard?.name}` : 'Corporate'}></Navbar>
      <CorporateDashboard />
    </div>
  );
};

export default CorporateDashboardPage;
