import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { UserDashboardRepository } from '@/domain/repository/user_dashboard.ts';
import { UserDashboardModel } from '@/domain/model/user_dashboard_profile_model.ts';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { UserRepository } from '@/domain/repository/user_repository.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { AffiliateByEmailInt } from '@/domain/repository/interface/affiliate_interface.ts';

interface ReferralContextProps {
  children: ReactNode;
}

interface ReferralParams {
  user_uid?: string;
  uid?: string;
  uuid?: string;
  page?: number;
  limit?: number;
  search?: string | null;
}

interface FormReferral {
  parent_email?: string;
  child_email?: string;
}

interface ReferralContextValue {
  handleAddChildReferralModal: ModalObject;
  handleAddParentReferralModal: ModalObject;
  formReferral: FormReferral;
  setFormReferral: Dispatch<SetStateAction<FormReferral>>;
  profile?: ProfileModel;
  userDashboard?: UserDashboardModel;
  affiliate: AffiliateModel[];
  handleShowReferral: (_data: ReferralParams) => void;
  handleGetDataUserParentAffiliate: (_data: ReferralParams) => void;
  handleGetDataUserChildAffiliate: (_data: ReferralParams) => void;
  handleFetchUser: (_data: ReferralParams) => void;
  loading: boolean;
  users: UserModel[];
  handleSelectChildReferral: (_data: AffiliateByEmailInt) => void;
  handleSelectParentReferral: (_data: AffiliateByEmailInt) => void;
}

const ReferralContext = createContext<ReferralContextValue | null>(null);

const affiliateRep = new AffiliateRepository();
const userDashboardRep = new UserDashboardRepository();
const profileRep = new ProfileRepository();
const userRepository = new UserRepository();

const ReferralProvider: React.FC<ReferralContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [affiliate, setAffiliate] = useState<AffiliateModel[]>([]);
  const [userDashboard, setUserDashboard] = useState<UserDashboardModel>();
  const [profile, setProfile] = useState<ProfileModel>();

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserModel[]>([]);

  const [formReferral, setFormReferral] = useState<FormReferral>({});

  const handleAddChildReferralModal = useVisibleComponent(false);
  const handleAddParentReferralModal = useVisibleComponent(false);

  //HANDLE SHOW CORPORATE
  const handleShowReferral = (_data: ReferralParams) => {
    const param = {
      user_uid: _data.user_uid
    };
    affiliateRep
      .fetchAffiliateCount(param)
      .then((result: any) => {
        setAffiliate(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE GET DATA USER PARENT AFFILIATE
  const handleGetDataUserParentAffiliate = (_data: ReferralParams) => {
    userDashboardRep
      .showUserDashboardProfile({ user_uid: _data.user_uid })
      .then((result: any) => {
        setUserDashboard(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE GET DATA GET DATA CHILD AFFILIATE
  const handleGetDataUserChildAffiliate = (_data: ReferralParams) => {
    profileRep
      .showProfile({ uuid: _data.user_uid })
      .then((result: any) => {
        setProfile(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //FETCH USER TRANSACTION SEARCH
  const handleFetchUser = (_data: ReferralParams) => {
    setLoading(true);
    const params = {
      search: _data.search
      // type: MEMBER_TYPE.USER
    };
    userRepository
      .fetchUser(params)
      .then((result: any) => {
        setLoading(false);
        setUsers(result.data);
      })
      .catch(error => {
        setLoading(false);
        alertToast.errorAlert(error);
      });
  };

  const handleSelectChildReferral = (_data: AffiliateByEmailInt) => {
    const x = alertToast.loadingAlert('');

    const params: AffiliateByEmailInt = {
      parent_email: _data.parent_email,
      child_email: _data.child_email
    };

    affiliateRep
      .userAffiliateByEmail(params)
      .then(() => {
        alertToast.successAlert();
        alertToast.updateLoading(x);
        handleAddChildReferralModal.setState(false);
        handleGetDataUserChildAffiliate({ user_uid: _data.user_uid });
      })
      .catch(() => {
        alertToast.updateLoading(x);
      });
  };

  const handleSelectParentReferral = (_data: AffiliateByEmailInt) => {
    const x = alertToast.loadingAlert('');

    const params: AffiliateByEmailInt = {
      parent_email: _data.parent_email,
      child_email: _data.child_email
    };

    affiliateRep
      .userAffiliateByEmail(params)
      .then(() => {
        alertToast.successAlert();
        alertToast.updateLoading(x);
        handleAddParentReferralModal.setState(false);
        handleGetDataUserParentAffiliate({ user_uid: _data.user_uid });
      })
      .catch(() => {
        alertToast.updateLoading(x);
      });
  };

  const contextValue: ReferralContextValue = {
    handleSelectChildReferral,
    handleSelectParentReferral,
    handleAddChildReferralModal,
    handleAddParentReferralModal,
    formReferral,
    setFormReferral,
    profile,
    affiliate,
    handleShowReferral,
    userDashboard,
    handleGetDataUserParentAffiliate,
    handleGetDataUserChildAffiliate,
    handleFetchUser,
    loading,
    users
  };

  return <ReferralContext.Provider value={contextValue}>{children}</ReferralContext.Provider>;
};

const useReferralContext = (): ReferralContextValue => {
  const context = useContext(ReferralContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a ReferralProvider');
  }
  return context;
};

export { ReferralProvider, useReferralContext };
