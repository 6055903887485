import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import { Table, Tbody, TBox, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { useBusinessVerifyContext } from '@/delivery/ui/admin/data_corporate/business_verify/context/business_verify_context.tsx';
import { useEffect } from 'react';
import TableBusinessInfo from '@/delivery/ui/admin/data_corporate/business_verify/detail/table_business_info.tsx';
import TableDocument from '@/delivery/ui/admin/data_corporate/business_verify/detail/table_document.tsx';
import TableBusinessAddress from '@/delivery/ui/admin/data_corporate/business_verify/detail/table_business_address.tsx';
import TableMailAddress from '@/delivery/ui/admin/data_corporate/business_verify/detail/table_mail_address.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

import { FiCheckCircle } from 'react-icons/fi';
import DialogApproveBusinessVerify from '@/delivery/ui/admin/data_corporate/business_verify/detail/dialog/dialog_approve_business_verify.tsx';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import TableBankAccount from '@/delivery/ui/admin/data_corporate/business_verify/detail/table_bank_account.tsx';

const DetailBusinessVerify = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const {
    handleShowBusinessVerify,
    businessVerify,
    handleApproveBusinessVerifyModal,
    handleApproveBusinessVerifyShowModal
  } = useBusinessVerifyContext();

  useEffect(() => {
    if (uid) {
      handleShowBusinessVerify({ uid: uid });
    }
  }, [uid]);

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.DATA_CORPORATE.BUSINESS_VERIFY)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to member business request
        </Typography>
      </div>

      <div className={'flex justify-between items-center'}>
        <Typography size={24} fontWeight={600}>
          Member Business Request Verify Detail
        </Typography>
        {businessVerify?.status !== STATUS_CORPORATE.VERIFIED && (
          <Button
            label={'Aproval Akun Bisnis'}
            prefixIcon={<FiCheckCircle />}
            onClick={() => handleApproveBusinessVerifyShowModal({ uid: uid })}
          />
        )}
      </div>
      <Line />
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Bisnis
            </Typography>
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              <Th>Penangung Jawab</Th>
              <Th>Bank Account</Th>
              {/*<Th>Balance (gr)</Th>*/}
              <Th>Request Date</Th>
              <Th>Status</Th>
              {/*<Th>Updated By </Th>*/}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Typography fontWeight={600} size={12} type="primary-invert">
                  {businessVerify?.name}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {businessVerify?.emailCorporate}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {businessVerify?.phoneCorporate}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={600} size={12} type="primary-invert">
                  {businessVerify?.picName}
                </Typography>
                {/*<Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*  Direktur*/}
                {/*</Typography>*/}
                {/*<Typography fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*  {item.ph}*/}
                {/*</Typography>*/}
              </Td>
              <Td>
                <div className=" text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {businessVerify?.masterBank?.name}
                  </Typography>
                  <p>
                    Acc Name:{' '}
                    <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                      {businessVerify?.accountHolderName}
                    </Typography>
                  </p>
                  <p>
                    Acc No:{' '}
                    <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                      {businessVerify?.accountNumber}
                    </Typography>
                  </p>
                </div>
              </Td>
              {/*<Td>*/}
              {/*  <div className="text-right text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">*/}
              {/*    <Typography fontWeight={600} size={12} type="primary-invert">*/}
              {/*      24,00*/}
              {/*    </Typography>*/}
              {/*    <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
              {/*      24.1755555*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*</Td>*/}
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {businessVerify?.getCreatedAt}
                </Typography>
              </Td>
              <Td>
                <div className={'whitespace-nowrap'}>
                  <Chips type={businessVerify?.getStatusColor} label={businessVerify?.status} className="leading-4" />
                  <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert"></Typography>
                </div>
              </Td>
              {/*<Td>*/}
              {/*  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
              {/*    System*/}
              {/*  </Typography>*/}
              {/*</Td>*/}
            </Tr>
          </Tbody>
        </Table>
      </div>

      <div className={'mt-[20px]'}>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Bisnis Detail
            </Typography>
          </div>
        </Theader>
        <TBox>
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <div className={'flex flex-col gap-[20px]'}>
              <TableBusinessInfo />
              <TableBankAccount />
              <TableDocument />
            </div>
            <div className={'flex flex-col gap-[20px]'}>
              <TableBusinessAddress />
              <TableMailAddress />
            </div>
          </div>
        </TBox>
      </div>

      {handleApproveBusinessVerifyModal.state && (
        <DialogApproveBusinessVerify modal={handleApproveBusinessVerifyModal} />
      )}
    </div>
  );
};

export default DetailBusinessVerify;
