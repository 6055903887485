import { IoDocumentTextOutline } from 'react-icons/io5';
import { TBox, Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';

import ChartGoldPrice from './chart_gold_price';
import CardRate from '@/delivery/components/atoms/card_rate/card_rate';
import Button from '@/delivery/components/atoms/button/button';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import { useGoldPriceContext } from '@/delivery/ui/admin/gold_price/context/gold_price_context.tsx';
import { useEffect, useRef } from 'react';
import { GoldRateModel } from '@/domain/model/gold_rate_model.ts';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { usePDF } from 'react-to-pdf';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import { formatDateParam, formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { countRateChange, setUpDown } from '@/infrastructure/helper/count_rate_change.ts';

const GoldPrice = () => {
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: `grafik-harga-emas-${formatDateParam(new Date())}.pdf` });

  const {
    handleFetchGoldRateCharts,
    loadingRateGold,
    goldRateLatest,
    handleGetLatestGoldRate,
    handleFetchGoldRate,
    goldRates,
    goldRatePagination,
    setFormGoldRate,
    formGoldRate,
    formGoldRateTable,
    setFormGoldRateTable,
    goldRatesExportData,
    handleFetchDataExportTable
  } = useGoldPriceContext();

  useEffect(() => {
    handleGetLatestGoldRate();
    handleFetchGoldRate({});
  }, []);

  //EXPORT EXCEL
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `gold-rates-${formatDateTimeParam(new Date())}`,
    sheet: 'gold rate'
  });

  const handleExport = () => {
    handleFetchDataExportTable();
  };

  useEffect(() => {
    if (goldRatesExportData.length > 0) {
      onDownload();
    }
  }, [goldRatesExportData]);

  return (
    <div>
      <div className="flex flex-col sm:flex-row gap-[24px]">
        <CardRate
          loading={loadingRateGold}
          title="Buy Rate"
          ratePrice={goldRateLatest?.currentRate.getBuy}
          ratePercentage={countRateChange(goldRateLatest?.currentRate.buy, goldRateLatest?.previousRate.buy)}
          arrow={setUpDown(goldRateLatest?.currentRate.buy, goldRateLatest?.previousRate.buy)}
        />
        <CardRate
          loading={loadingRateGold}
          title="Sell Rate"
          ratePrice={goldRateLatest?.currentRate.getSell}
          ratePercentage={countRateChange(goldRateLatest?.currentRate.sell, goldRateLatest?.previousRate.sell)}
          arrow={setUpDown(goldRateLatest?.currentRate.sell, goldRateLatest?.previousRate.sell)}
        />
      </div>
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">Grafik Harga Emas</span>
          <div className={'flex gap-[10px]'}>
            <ButtonFilter
              onClickApply={() => handleFetchGoldRateCharts()}
              size={'small'}
              titleFilter={'Filter Grafik Harga'}
              label={'More Filter'}
            >
              <div className={'flex gap-[20px]'}>
                <div className={'w-full'}>
                  <Datepicker
                    value={formGoldRate.start_date}
                    label={'Start Date'}
                    onChange={e => setFormGoldRate({ ...formGoldRate, start_date: e })}
                  />
                </div>
                <div className={'w-full'}>
                  <Datepicker
                    value={formGoldRate.end_date}
                    label={'End Date'}
                    onChange={e => setFormGoldRate({ ...formGoldRate, end_date: e })}
                  />
                </div>
              </div>
            </ButtonFilter>
            <Button
              label="Export PDF"
              type="secondary"
              prefixIcon={<IoDocumentTextOutline />}
              onClick={() => toPDF()}
            />
          </div>
        </Theader>
        <TBox>
          <div ref={targetRef}>
            <ChartGoldPrice />
          </div>
        </TBox>
      </div>

      {/*TABLE GOLD RATE*/}
      <div className="mt-[32px]">
        <Theader>
          <span className="text-[18px] font-[600]">Daftar Harga Emas</span>
          <div className={'flex gap-[10px]'}>
            <ButtonFilter
              onClickApply={() => handleFetchGoldRate({})}
              size={'small'}
              titleFilter={'Filter Harga Emas'}
              label={'More Filter'}
            >
              <div className={'flex gap-[20px]'}>
                <div className={'w-full'}>
                  <Datepicker
                    value={formGoldRateTable.start_date}
                    label={'Start Date'}
                    onChange={e => setFormGoldRateTable({ ...formGoldRateTable, start_date: e })}
                  />
                </div>
                <div className={'w-full'}>
                  <Datepicker
                    value={formGoldRateTable.end_date}
                    label={'End Date'}
                    onChange={e => setFormGoldRateTable({ ...formGoldRateTable, end_date: e })}
                  />
                </div>
              </div>
            </ButtonFilter>
            <Button
              label="Export CSV"
              type="secondary"
              prefixIcon={<IoDocumentTextOutline />}
              onClick={() => handleExport()}
            />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th noBorder>Tanggal</Th>
              <Th noBorder>Buy Rate</Th>
              <Th noBorder>Sell Rate</Th>
              <Th noBorder>Update by</Th>
            </Tr>
          </Thead>
          <Tbody>
            {goldRates.map((item: GoldRateModel, index) => (
              <Tr key={index}>
                <Td noBorder>
                  <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td noBorder>
                  <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
                    {item.getBuy}
                  </Typography>
                </Td>
                <Td noBorder>
                  <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
                    {item.getSell}
                  </Typography>
                </Td>
                <Td noBorder>
                  <Typography size={14} fontWeight={400} type={'tertiary-invert'}>
                    {item.updatedByUser?.getFullName}
                  </Typography>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={goldRatePagination?.page || 1}
            totalPages={goldRatePagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchGoldRate({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>

      {/*DATA EXPORT*/}
      <div className={'hidden'}>
        <table ref={tableRef} className={'border border-solid'}>
          <tbody>
            <tr>
              <th className={'border border-solid'}>Tanggal</th>
              <th className={'border border-solid'}>Buy Rate</th>
              <th className={'border border-solid'}>Sale Rate</th>
              <th className={'border border-solid'}>Updated By</th>
            </tr>

            {goldRatesExportData.map((item, index) => (
              <tr>
                <td className={'border border-solid'}>{formatDateTimeParam(item.createdAt)}</td>
                <td className={'border border-solid'}>{item.buy}</td>
                <td className={'border border-solid'}>{item.sell}</td>
                <td className={'border border-solid'}>{item.updatedByUser?.getFullName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GoldPrice;
