import {
  Table,
  Tbody,
  TBox,
  Td,
  Tfooter,
  Th,
  Thead,
  Theader,
  Tr
} from '@/delivery/components/molecules/table/table.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogAddPaymentInstruction from '@/delivery/ui/admin/settings/open_channel/detail/payment_instruction/dialog_add_payment_instruction.tsx';
import DialogUpdatePaymentInstruction from '@/delivery/ui/admin/settings/open_channel/detail/payment_instruction/dialog_update_payment_instruction.tsx';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PaymentInstruction = () => {
  const { uid } = useParams();

  const {
    handleFetchChannelInstruction,
    handleShowChannelInstruction,
    paymentChannelInstructionPagination,
    paymentChannelInstruction,
    handleAddPaymentInstructionModal,
    handleUpdatePaymentInstructionModal
  } = useOpenChannelContext();

  useEffect(() => {
    handleFetchChannelInstruction({ payment_channel_uid: uid });
  }, []);

  return (
    <TBox>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Payment Channel Instruction
        </Typography>
        <div>
          <Button
            prefixIcon={Icon.plus}
            label={'Add Instruction'}
            onClick={() => handleAddPaymentInstructionModal.setState(true)}
          />
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Language</Th>
            <Th>Title</Th>
            <Th>Instruction</Th>
            <Th>Display Order</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paymentChannelInstruction?.map((item, index: number) => (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.lang}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.title}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.instruction}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={14} type="tertiary-invert">
                  {item.displayOrder}
                </Typography>
              </Td>
              <Td>
                <div className="flex gap-[4px]">
                  <Button
                    iconButton
                    iconType={'edit'}
                    onClick={() => handleShowChannelInstruction({ channel_instruction_uid: item.UID })}
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          page={paymentChannelInstructionPagination?.page || 1}
          totalPages={paymentChannelInstructionPagination?.totalPages || 1}
          handlePagination={page => {
            handleFetchChannelInstruction({
              page: page
            });
          }}
        />
      </Tfooter>

      {handleAddPaymentInstructionModal.state && (
        <DialogAddPaymentInstruction modal={handleAddPaymentInstructionModal} />
      )}

      {handleUpdatePaymentInstructionModal && (
        <DialogUpdatePaymentInstruction modal={handleUpdatePaymentInstructionModal} />
      )}
    </TBox>
  );
};

export default PaymentInstruction;
