import { useEffect } from 'react';
import CorporateProfilePage from '@/delivery/ui/corporate/profile';
import { CorporateProfileProvider } from '@/delivery/ui/corporate/profile/context/corporate_profile_context.tsx';

const ViewCorporateProfile = () => {
  useEffect(() => {
    document.title = 'Corporate Profile';
  }, []);

  return (
    <div>
      <CorporateProfileProvider>
        <CorporateProfilePage />
      </CorporateProfileProvider>
    </div>
  );
};

export default ViewCorporateProfile;
