import CorporatePage from '@/delivery/ui/admin/corporate';
import { useEffect } from 'react';
import { CorporateProvider } from '@/delivery/ui/admin/corporate/context/corporate_context.tsx';

const ViewCorporate = () => {
  useEffect(() => {
    document.title = 'Corporate';
  }, []);

  return (
    <div>
      <CorporateProvider>
        <CorporatePage />
      </CorporateProvider>
    </div>
  );
};

export default ViewCorporate;
