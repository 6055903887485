export const Routing = {
  PAGE_NOT_FOUND: '*',
  PAGE_NO_ACCESS: '/403',

  LOGIN: '/login',
  REGISTRATION_CORPORATE_USER: '/corporate/registration_user',

  DASHBOARD: {
    OVERVIEW: '/dashboard/overview',
    RANKING: '/dashboard/ranking',
    MEMBER: '/dashboard/member',
    TRANSACTION: '/dashboard/transaction',
    ACCESS_STATISTIC: '/dashboard/access_statistic'
  },

  GOLD_PRICE: '/gold_price',

  DATA_MEMBER: {
    MEMBER_LIST: '/data_member/member_list',
    MEMBER_LIST_DETAIL: '/data_member/member_list/detail/',
    MEMBER_VERIFY: '/data_member/member_verify',
    MEMBER_VERIFY_DETAIL: '/data_member/member_verify/detail/',
    VERIFY_E_MONEY: '/data_member/verify_e_money',
    BUSINESS_VERIFY_DETAIL: '/data_member/business_verify/detail/'
  },

  DATA_CORPORATE: {
    CORPORATE_LIST: '/data_corporate/corporate_list',
    CORPORATE_LIST_DETAIL: '/data_corporate/corporate_list/detail/',
    REQUEST_VERIFY: '/data_corporate/request_verify',
    DATA_TRANSACTION: '/data_corporate/data_transaction',
    REFERAL_LIST: '/data_corporate/referal_list',
    REFERAL_TRANSACTION: '/data_corporate/referal_transaction',
    BUSINESS_VERIFY: '/data_corporate/business_verify'
  },

  TRANSACTION: {
    TRANSACTION_LIST: '/transaction/transaction_list',
    MANUAL_TRANSACTION: '/transaction/manual_transaction',
    SETTLEMENT: '/transaction/settlement',
    AMBIL_EMAS: {
      HISTORY: '/transaction/ambil_emas/history',
      HISTORY_DETAIL: '/transaction/ambil_emas/history/detail/',
      STOCK_GOLD: '/transaction/ambil_emas/stock_gold',
      COUNTER_OFFLINE: '/transaction/ambil_emas/counter_offline',
      VENDING_MACHINE: '/transaction/ambil_emas/vending_machine',
      VENDING_MACHINE_DETAIL: '/transaction/ambil_emas/vending_machine/detail/'
    },
    ATM_EMAS: {
      STOCK_ATM_GOLD: '/transaction/atm_gold/stock_atm_gold',
      ATM_LOCATION: '/transaction/atm_gold/atm_location'
    },
    SETOR_EMAS: '/transaction/setor_emas',
    EXPORT: '/transaction/export'
  },

  DATA_DCA: {
    DCA_ACTIVATION: '/data_dca/dca_activation',
    ACCOUNT_LIST: '/data_dca/account_list',
    ACCOUNT_REQUEST: '/data_dca/account_request',
    ACCOUNT_TRANSACTIONS: '/data_dca/account_transaction'
  },

  DEPOSIT_GOLD: {
    DEPOSIT_BY_ADMIN: '/deposit_gold/deposit_by_admin',
    MICROGOLD_BY_ATM: '/deposit_gold/microgold_by_atm'
  },

  RESELLER_AFFILIATE: {
    AFFILIATE_LIST: '/reseller_affiliate/account_list',
    AFFILIATE_LIST_DETAIL: '/reseller_affiliate/account_list/detail/',
    AFFILIATE_TRANSACTION: '/reseller_affiliate/account_request',
    EVENT_REFERRAL: '/reseller_affiliate/event_referral'
  },

  MASTER: {
    MEMBER_LOYALTY: '/master/member_loyalty',
    MEMBER_AFFILIATE: '/master/member_affiliate',
    GOLD_PIECE: '/master/gold_piece',
    GOLD_PIECE_DETAIL: '/master/gold_piece/detail/',
    RICE_RATE: '/master/rice_rate'
  },

  WEB_ADMIN: {
    MEDIA: '/web_admin/media',
    NOTIFY_APP: '/web_admin/notify_app',
    WEB_POP_UP: '/web_admin/web_pop_up',
    WEB_PAGES: '/web_admin/web_pages',
    WEB_PAGES_ADD: '/web_admin/web_pages/add',
    WEB_PAGES_UPDATE: '/web_admin/web_pages/detail/'
  },

  SETTINGS: {
    FORMAT_EMAIL: '/settings/format_email',
    ADMIN_ACCESS: '/settings/admin_access',
    ADMIN_ACCESS_DETAIL: '/settings/admin_access/detail/',
    TRANSACTION_LOG: '/settings/transaction_log',
    CHECK_DATA: '/settings/check_data',
    ACTIVITY_LOG: '/settings/activity_log',
    CUSTOM_VARIABLE: '/settings/custom_variable',
    OPEN_CHANNEL: '/settings/open_channel',
    OPEN_CHANNEL_DETAIL: '/settings/open_channel/detail/',
    COMPANY_SETTING: '/settings/company',
    DINARAN_SETTING: '/settings/dinaran_setting',
    WITHDRAWAL: '/settings/withdrawal',
    WITHDRAWAL_DETAIL: '/settings/withdrawal/detail/',
    PPOB: '/settings/ppob',
    PPOB_DETAIL: '/settings/ppob/margin/'
  },

  // CORPORATE PAGE BELOW
  CORPORATE: '/corporate',
  CORPORATE_DASHBOARD: '/corporate/dashboard',
  CORPORATE_PROFILE: '/corporate/profile',

  CORPORATE_TRANSACTION: '/corporate/transaction',
  CORPORATE_TRANSACTION_BERI_EMAS_MULTIPLE: '/corporate/transaction/add/beri_emas/multiple',
  CORPORATE_GOLD_CONVERSION: '/corporate/transaction/conversion',

  CORPORATE_REFERRAL_DATA: '/corporate/referral/referral_data',
  CORPORATE_REFERRAL_TRANSACTION: '/corporate/referral/referral_transaction'
};
