import { Table, Tbody, Td, Tfooter, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
// import FilterMyTransactionAffiliate
//     from "@/delivery/ui/admin/corporate/tab/my_transaction_affiliate/filter_my_transaction_affiliate.tsx";
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useReferralTransactionContext } from '@/delivery/ui/corporate/referral/referral_transaction/context/corporate_referral_transaction_context.tsx';
import { useEffect } from 'react';
import FilterMyTransactionAffiliate from '@/delivery/ui/corporate/referral/referral_transaction/filter_my_transaction_affiliate.tsx';

const ReferralTransaction = () => {
  const { myTransactionAffiliate, myTransactionAffiliatePagination, handleFetchMyTransactionAffiliate } =
    useReferralTransactionContext();

  useEffect(() => {
    handleFetchMyTransactionAffiliate({});
  }, []);

  return (
    <div>
      <div className="mt-[32px]">
        <Theader>
          <div>
            <Typography size={18} fontWeight={600}>
              Daftar Transaksi Member Referral
            </Typography>
          </div>
          <FilterMyTransactionAffiliate />
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Member</Th>
              <Th>Type</Th>
              <Th>Reversal</Th>
              <Th>Status</Th>
              <Th>ICDX Send</Th>
              <Th>
                <span className={'whitespace-nowrap'}>Trx Date</span>
              </Th>
              <Th>
                <span className={'whitespace-nowrap'}>Paid Date</span>
              </Th>
              <Th>Paid Amount</Th>
              <Th>Fee</Th>
              {/*<Th>Vendor</Th>*/}
              {/*<Th>Vendor Ref ID</Th>*/}
              <Th>Gramasi</Th>
              <Th>Gold Rate</Th>
              <Th>Update By</Th>
              {/*<Th>Action</Th>*/}
            </Tr>
          </Thead>
          <Tbody>
            {myTransactionAffiliate?.map((item, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.code}
                  </Typography>
                </Td>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.user?.fullName}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.user?.email}
                  </Typography>
                </Td>
                <Td>
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.type} removeDot />
                  </span>
                </Td>
                <Td>
                  {item.flags?.includes(TYPE_TRANSACTION.REVERSAL) ? (
                    <span className={'whitespace-nowrap'}>
                      <Chips type={'danger-light'} label={item.flags?.join(TYPE_TRANSACTION.REVERSAL)} removeDot />
                    </span>
                  ) : (
                    '-'
                  )}
                </Td>

                <Td>
                  {item.flags && item.type === TYPE_TRANSACTION.REVERSAL ? (
                    <Chips label={`${item.status} ${item.type}`} type={item.getStatusColor} />
                  ) : (
                    <Chips label={item.status} type={item.getStatusColor} />
                  )}
                </Td>
                <Td>
                  <div className={'whitespace-nowrap'}>
                    <Chips
                      removeDot
                      label={item.flags?.includes('ICDX_SYNCED') ? 'SENT' : 'NOT SENT'}
                      type={item.flags?.includes('ICDX_SYNCED') ? 'primary' : 'danger'}
                    />
                  </div>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getTransactionAtSingle}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getSucceedAtSingle}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getAmountSingle}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getFeeRupiahSingle}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    {item.payment?.vendor}*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                {/*<Td>*/}
                {/*  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
                {/*    {item.payment?.vendorRefID}*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getGram}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    {item.rate?.getBuy}
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate?.getSell}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.updater?.firstName}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <div className="flex gap-[4px]">*/}
                {/*    <Button iconButton iconType={'view'} />*/}
                {/*  </div>*/}
                {/*</Td>*/}
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={myTransactionAffiliatePagination?.page || 1}
            totalPages={myTransactionAffiliatePagination?.totalPages || 1}
            handlePagination={page => {
              handleFetchMyTransactionAffiliate({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default ReferralTransaction;
