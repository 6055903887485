import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Textarea from '@/delivery/components/atoms/textarea/textarea.tsx';
import { LanguageList } from '@/domain/constant/language.ts';
import { useOpenChannelContext } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import { useParams } from 'react-router-dom';

const DialogUpdatePaymentInstruction = (props: ModalDialogProps) => {
  const { uid } = useParams();

  const {
    handleUpdateChannelInstruction,
    handleUpdatePaymentInstructionModal,
    formPaymentInstruction,
    setFormPaymentInstruction
  } = useOpenChannelContext();

  return (
    <ModalDialog size="medium" title="Update Payment Instruction" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div className={'flex gap-[20px]'}>
          <div className={'w-full'}>
            <Select2
              value={formPaymentInstruction.lang}
              label={'Language'}
              options={LanguageList.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              onChange={e => setFormPaymentInstruction({ ...formPaymentInstruction, lang: e.value })}
            />
          </div>

          <TextField
            value={formPaymentInstruction.display_order}
            block
            label={'Display Order'}
            type={'number'}
            onChange={e =>
              setFormPaymentInstruction({ ...formPaymentInstruction, display_order: parseInt(e.target.value) })
            }
          />
        </div>

        <TextField
          value={formPaymentInstruction.title}
          block
          label={'Title'}
          onChange={e => setFormPaymentInstruction({ ...formPaymentInstruction, title: e.target.value })}
        />
        <Textarea
          placeholder={'jika ingin dalam bentuk list silahkan tekan Enter'}
          height={200}
          block
          value={formPaymentInstruction.instruction}
          label={'Instruction'}
          onChange={e => setFormPaymentInstruction({ ...formPaymentInstruction, instruction: e.target.value })}
        />
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button
          block
          type="secondary"
          label="Cancel"
          onClick={() => handleUpdatePaymentInstructionModal.setState(false)}
        />
        <Button block label="Save" onClick={() => handleUpdateChannelInstruction({ payment_channel_uid: uid })} />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogUpdatePaymentInstruction;
