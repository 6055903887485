import { useCorporateContext } from '@/delivery/ui/admin/corporate/context/corporate_context.tsx';
import { Table, TBox, Td, Th, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';

const DetailCorporate = () => {
  const { corporate } = useCorporateContext();

  return (
    <div>
      <TBox>
        <div className={'grid grid-cols-2 gap-[20px]'}>
          {/*DETAIL CORPORATE*/}
          <Table className="rounded-b-[8px]">
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Corporate Name
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.name}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Email
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.emailCorporate}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Phone
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.phoneCorporate}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Kelurahan
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.village.name}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Kecamatan
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.district.name}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Kabupaten
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.city.name}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Provinsi
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.province.name}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Status
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  <Chips label={corporate?.status} removeDot type={corporate?.getStatusColor} />
                </Typography>
              </Td>
            </Tr>
          </Table>

          {/*BALANCE BELOW*/}
          <Table className="rounded-b-[8px]">
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Balance (gr)
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.user.balance?.amount}
                </Typography>
              </Td>
            </Tr>
            <Tr>
              <Th>
                <div className={'text-nowrap'}>
                  <Typography fontWeight={500} size={12} type="tertiary-invert">
                    Real Balance (gr)
                  </Typography>
                </div>
              </Th>
              <Td className={'w-full'}>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {corporate?.user.balanceTotal?.amount}
                </Typography>
              </Td>
            </Tr>
          </Table>
        </div>
      </TBox>
    </div>
  );
};

export default DetailCorporate;
