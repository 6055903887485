import { useEffect } from 'react';
import { LuBriefcase } from 'react-icons/lu';
import { BsPersonCheck } from 'react-icons/bs';
import { MdMailOutline } from 'react-icons/md';

import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import Chips from '@/delivery/components/atoms/chips/chips';
import Typography from '@/delivery/components/atoms/typography/typography';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import { useMemberListContext } from './context/member_list_context';
import MemberListCount from './member_list_count';
import DialogDetailMemberList from './dialog/dialog_member_list/dialog_detail_member_list';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import DialogLocMemberBalance from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_lock_member_balance/dialog_lock_member_balance.tsx';
import DialogConfirmApproveBusiness from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_confirm_approve_business/dialog_confirm_approve_business.tsx';
import DialogCreateBusinessAccount from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_create_business_account/dialog_create_business_account.tsx';
import FilterMemberList from '@/delivery/ui/admin/data_member/member_list/filter_member_list.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/data_member/member_list/context/corporate_context.tsx';
import { UserModelQuery } from '@/domain/model/user_model_query.ts';
import { MEMBER_CORPORATE_TYPE } from '@/domain/constant/member/member_corporate_type.ts';
import Icon from '@/domain/constant/icon.tsx';
import DialogConfirmSuspendUser from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_confirm_suspend/dialog_confirm_suspend.tsx';
import { STATUS_CONST } from '@/domain/constant/status.ts';
import { MEMBER_STATUS } from '@/domain/constant/member/member_status.ts';
import DialogConfirmActiveUser from '@/delivery/ui/admin/data_member/member_list/dialog/dialog_confirm_suspend/dialog_confirm_active.tsx';

const MemberList = () => {
  const navigate = useNavigate();
  const {
    handleFetchUser,
    users,
    handleDetailMemberListModal,
    usersPagination,
    handleDialogLockMemberBalance,
    handleShowLockBalance,
    handleShowSuspendMemberModal,
    handleShowActiveMemberModal,
    handleDialogConfirmSuspendModal,
    handleDialogConfirmActiveModal
  } = useMemberListContext();

  const { handleOpenConfirmCreate, handleDialogConfirmBusinessModal, handleDialogCreateBusinessAccountModal } =
    useCorporateContext();

  useEffect(() => {
    // handleFetchUser({ page: 1, limit: 10 });
  }, []);

  return (
    <div>
      <MemberListCount />
      <div className="mt-[32px]">
        <div className={'flex justify-end mb-[12px]'}>
          <FilterMemberList />
        </div>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              {/*<Th>Transaksi</Th>*/}
              <Th>Balance (gr)</Th>
              <Th>Lock Ball (gr)</Th>
              <Th>Reg Date</Th>
              <Th>Verification</Th>
              <Th>Status</Th>
              <Th>Updated By</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((item: UserModelQuery, index) => (
              <Tr key={index}>
                <Td>
                  <Typography fontWeight={400} size={12} type="primary-invert">
                    <span className={'text-text-primary'}>{item?.serialID}</span>
                  </Typography>
                  <Typography fontWeight={500} size={12} type="primary-invert">
                    {item?.firstName} {item?.lastName}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item?.email}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item?.phone}
                  </Typography>
                  <div className={'flex gap-[5px] mt-[10px] items-center'}>
                    <Chips label={item?.corporate} removeDot type={'info-light'} />

                    {item.tier && (
                      <div>
                        <Chips label={item.tier} removeDot type={item.getTierColor} />
                      </div>
                    )}
                  </div>
                </Td>
                {/*<Td>*/}
                {/*  <Typography fontWeight={400} size={10} type="tertiary-invert">*/}
                {/*    192*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                <Td>
                  <div className="text-right">
                    <Typography fontWeight={500} size={12} type="secondary-invert">
                      {item.getTruncateAmountDecimal}
                    </Typography>

                    <Typography fontWeight={400} size={12} type="tertiary-invert">
                      {item.balance}
                    </Typography>
                  </div>
                </Td>

                <Td>
                  <div className="flex text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">
                    <div className={'w-full'}>
                      {/*<p>Emas Impian:</p>*/}
                      <p>Dinaran:</p>
                      <p>User:</p>
                      {/*<p>Saldo DCA:</p>*/}
                      {/*<p>Total DCA:</p>*/}
                    </div>
                    <div className={'text-right w-full text-text-secondary-invert font-[600]'}>
                      {/*<p>0</p>*/}
                      <p>{item.getTruncateSystemAmountDecimal}</p>
                      <p>{item.getTruncateMemberAmountDecimal}</p>
                    </div>
                  </div>
                </Td>
                <Td>
                  <Typography size={12} fontWeight={400} type="tertiary-invert">
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                <Td>
                  <div className={'flex gap-[24px]'}>
                    <div
                      className={`text-[20px] ${item?.emailVerified === true ? 'text-text-primary-green' : 'text-bg-danger'}`}
                    >
                      <MdMailOutline />
                    </div>

                    <div
                      className={`text-[20px] ${item?.verificationStatus === STATUS_CORPORATE.VERIFIED ? 'text-text-primary-green' : 'text-bg-danger'}`}
                    >
                      <BsPersonCheck />
                    </div>
                  </div>
                </Td>
                <Td>
                  <Chips
                    removeDot
                    label={item.status.toUpperCase()}
                    type={STATUS_CONST.ACTIVE === item.status.toUpperCase() ? 'primary-light' : 'danger-light'}
                  />
                </Td>
                <Td>
                  <Typography size={10} fontWeight={400} type="tertiary-invert">
                    {item.updatedBy}
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'lock'} onClick={() => handleShowLockBalance({ uuid: item.UUID })} />
                    <Button
                      iconButton
                      iconType={'view'}
                      onClick={() => navigate(Routing.DATA_MEMBER.MEMBER_LIST_DETAIL + item.UUID)}
                    />
                    <Button
                      disabled={item.corporate === MEMBER_CORPORATE_TYPE.CORPORATE ? true : false}
                      iconButton
                      icon={<LuBriefcase />}
                      onClick={() => handleOpenConfirmCreate({ uuid: item.UUID })}
                    />
                    {item.status.toUpperCase() === MEMBER_STATUS.ACTIVE.toUpperCase() ? (
                      <Button
                        iconButton
                        icon={Icon.banned}
                        onClick={() => handleShowSuspendMemberModal({ uuid: item.UUID })}
                      />
                    ) : (
                      <Button
                        iconButton
                        icon={Icon.check}
                        onClick={() => handleShowActiveMemberModal({ uuid: item.UUID })}
                      />
                    )}
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchUser({
                page: page
              });
            }}
            page={usersPagination?.page || 1}
            totalPages={usersPagination?.totalPages || 1}
          />
        </Tfooter>
      </div>

      {handleDialogConfirmSuspendModal.state && <DialogConfirmSuspendUser modal={handleDialogConfirmSuspendModal} />}

      {handleDetailMemberListModal.state && <DialogDetailMemberList modal={handleDetailMemberListModal} />}

      {handleDialogLockMemberBalance.state && <DialogLocMemberBalance modal={handleDialogLockMemberBalance} />}

      {handleDialogConfirmBusinessModal.state && (
        <DialogConfirmApproveBusiness modal={handleDialogConfirmBusinessModal} />
      )}

      {handleDialogCreateBusinessAccountModal.state && (
        <DialogCreateBusinessAccount modal={handleDialogCreateBusinessAccountModal} />
      )}

      {handleDialogConfirmActiveModal.state && <DialogConfirmActiveUser modal={handleDialogConfirmActiveModal} />}
    </div>
  );
};

export default MemberList;
