import { BaseModel } from '@/domain/model/base_model.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { UserTokenAffiliateModel } from '@/domain/model/user_token_affiliate_model.ts';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';

export class AffiliateUserModel extends BaseModel {
  userTokenAffiliateUID: string;
  parentUID: string;
  childUID: string;
  affiliateUID: string;
  expiredAt: string;
  affiliate: AffiliateModel;
  parent: ProfileModel;
  child: ProfileModel;
  userTokenAffiliate: UserTokenAffiliateModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userTokenAffiliateUID: string,
    parentUID: string,
    childUID: string,
    affiliateUID: string,
    expiredAt: string,
    affiliate: AffiliateModel,
    parent: ProfileModel,
    child: ProfileModel,
    userTokenAffiliate: UserTokenAffiliateModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.userTokenAffiliateUID = userTokenAffiliateUID;
    this.parentUID = parentUID;
    this.childUID = childUID;
    this.affiliateUID = affiliateUID;
    this.expiredAt = expiredAt;
    this.affiliate = affiliate;
    this.parent = parent;
    this.child = child;
    this.userTokenAffiliate = userTokenAffiliate;
  }

  get getExpired(): string {
    if (this.expiredAt) {
      return formatDate(this.expiredAt);
    }

    return '-';
  }

  public static fromJson(data: any): any | AffiliateUserModel {
    try {
      return new AffiliateUserModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_token_affiliate_uid,
        data.parent_uid,
        data.child_uid,
        data.affiliate_uid,
        data.expired_at,
        data.affiliate ? AffiliateModel.fromJson(data.affiliate) : null,
        data.parent ? ProfileModel.fromJson(data.parent) : null,
        data.child ? ProfileModel.fromJson(data.child) : null,
        data.user_token_affiliate ? UserTokenAffiliateModel.fromJson(data.user_token_affiliate) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): AffiliateUserModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(AffiliateUserModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
