import { Dispatch, ReactNode, RefObject, SetStateAction } from 'react';
import styles from './ModalDialog.module.css';
import { GrFormClose } from 'react-icons/gr';

interface ModalObject {
  ref: RefObject<HTMLDivElement>;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
}

export interface ModalDialogProps {
  modal?: ModalObject;
  children?: ReactNode;
  title?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  className?: string;
  noHeader?: boolean;
  overflow?: boolean;
  z_index?: string;
}

const ModalDialog = (props: ModalDialogProps) => {
  const { z_index = 'z-40', overflow = true, children, title, size = 'medium', modal, noHeader = false } = props;
  return (
    <>
      {modal?.state ? (
        <div className={`fixed inset-0 flex items-center justify-center ${z_index}`}>
          <div className="absolute inset-0 bg-bg-primary opacity-20"></div>
          <div
            className={`relative bg-text-white rounded-[12px] shadow-lg z-10 ${styles[size]} max-h-[90vh] overflow-y-auto`}
          >
            {/* header */}
            {!noHeader ? (
              <div className="py-4 px-[24px] flex justify-between items-center border-b-[1px] border-[200] border-border-secondary">
                <div className="text-text-primary-invert font-[600]">{title}</div>
                <div
                  className="text-text-tertiary-invert text-[20px] font-[300] cursor-pointer"
                  onClick={() => modal.setState(false)}
                >
                  <GrFormClose />
                </div>
              </div>
            ) : (
              ''
            )}

            {/* max-h-[800px] overflow-y-auto */}
            <div className={`p-[24px] z-10 max-h-[800px] ${overflow ? 'overflow-y-auto' : 'overflow-y-hidden'}`}>
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

interface ModalDialogContentProps {
  children?: ReactNode;
  className?: string;
}

const ModalDialogContent = (props: ModalDialogContentProps) => {
  const { children } = props;
  return <div className="py-[24px] mx-[24px] border-b-[1px] border-[200] border-border-secondary">{children}</div>;
};

const ModalDialogFooter = (props: ModalDialogContentProps) => {
  const { children, className } = props;

  return (
    <div className={` ${className} pt-[24px] mt-[24px] border-t-[1px] border-[200] border-border-secondary`}>
      {children}
    </div>
  );
};

export { ModalDialog, ModalDialogFooter, ModalDialogContent };
