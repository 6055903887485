import ReferralData from '@/delivery/ui/corporate/referral/referral_data/referral_data.tsx';
import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';

const CorporateReferralDataPage = () => {
  return (
    <div>
      <Navbar label={'Referral Data'}></Navbar>
      <ReferralData />
    </div>
  );
};

export default CorporateReferralDataPage;
