import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { PPOBList } from '@/domain/constant/ppob/ppob_type.ts';
import { usePPOBContext } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';
import { STATUS_LIST } from '@/domain/constant/status.ts';
import { useEffect, useState } from 'react';

const FilterPPOB = () => {
  const { formPPOB, setFormPPOB, handleFetchPPOBProduct } = usePPOBContext();

  const [disableButton, setDisableButton] = useState<boolean>(true);

  useEffect(() => {
    if (formPPOB.operator === '' || formPPOB.status === '' || formPPOB.type === '') {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [formPPOB.operator, formPPOB.status, formPPOB.type]);

  return (
    <div className="flex gap-[12px]">
      <ButtonFilter
        disablesApply={disableButton}
        onClickApply={() => handleFetchPPOBProduct({})}
        size={'small'}
        titleFilter={'Filter PPOB'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formPPOB.operator}
            search
            label={'Search Operator'}
            placeholder={'Search operator'}
            block
            onChange={e => setFormPPOB({ ...formPPOB, operator: e.target.value })}
          />
          <div className={'flex gap-[20px]'}>
            <div className={'w-full'}>
              <Select2
                label={'Type'}
                value={formPPOB.type}
                placeholder={'Type'}
                isSearchable={false}
                options={PPOBList.map(item => {
                  return {
                    value: item.UUID,
                    label: item.name
                  };
                })}
                onChange={e => {
                  {
                    setFormPPOB({
                      ...formPPOB,
                      type: e.value
                    });
                  }
                }}
              />
            </div>
            <div className={'w-full'}>
              <Select2
                label={'Status'}
                value={formPPOB.status}
                placeholder={'Status'}
                isSearchable={false}
                options={STATUS_LIST.map(item => {
                  return {
                    value: item.UUID,
                    label: item.name
                  };
                })}
                onChange={e => {
                  {
                    setFormPPOB({
                      ...formPPOB,
                      status: e.value
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterPPOB;
