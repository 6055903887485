import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { AffiliateUserModel } from '@/domain/model/affiliate_user_model.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateValueParams {
  uid?: string;
  page?: number;
  limit?: number;
}

interface FormCorporateInterface {
  uid?: string;
  sell?: number;
  status?: string;
}

export interface FormFilterTransaction {
  search?: string;
  type?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
}

interface CorporateValue {
  formFilterMyTransaction: FormFilterTransaction;
  setFormFilterMyTransaction: Dispatch<SetStateAction<FormFilterTransaction>>;
  formFilterMyTransactionAffiliate: FormFilterTransaction;
  setFormFilterMyTransactionAffiliate: Dispatch<SetStateAction<FormFilterTransaction>>;
  myAffiliate: AffiliateUserModel[];
  myAffiliatePagination?: PaginationModel;
  myTransaction: TransactionModel[];
  myTransactionPagination?: PaginationModel;
  myTransactionAffiliate?: TransactionModel[];
  myTransactionAffiliatePagination?: PaginationModel;
  corporate?: CorporateModel;
  formCorporate: FormCorporateInterface;
  setFormCorporate: Dispatch<SetStateAction<FormCorporateInterface>>;
  corporatePagination?: PaginationModel;
  handleAddCorporateModal: ModalObject;
  handleUpdateCorporateModal: ModalObject;
  handleShowCorporate: (_data: CorporateValueParams) => void;
  handleFetchMyTransaction: (_data: CorporateValueParams) => void;
  handleFetchMyAffiliate: (_data: CorporateValueParams) => void;
  handleFetchMyTransactionAffiliate: (_data: CorporateValueParams) => void;
}

const CorporateContext = createContext<CorporateValue | null>(null);

const corporateRep = new CorporateRepository();

const CorporateProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const handleAddCorporateModal = useVisibleComponent(false);
  const handleUpdateCorporateModal = useVisibleComponent(false);

  // const [corporate, setCorporate] = useState<CorporateModel>();
  const [corporate, setCorporate] = useState<CorporateModel>();

  const [myTransaction, setMyTransaction] = useState<TransactionModel[]>([]);
  const [myTransactionPagination, setMyTransactionPagination] = useState<PaginationModel>();

  const [myTransactionAffiliate, setMyTransactionAffiliate] = useState<TransactionModel[]>([]);
  const [myTransactionAffiliatePagination, setMyTransactionAffiliatePagination] = useState<PaginationModel>();

  const [myAffiliate, setMyAffiliate] = useState<AffiliateUserModel[]>([]);
  const [myAffiliatePagination, setMyAffiliatePagination] = useState<PaginationModel>();

  const [formCorporate, setFormCorporate] = useState<FormCorporateInterface>({});

  const [formFilterMyTransaction, setFormFilterMyTransaction] = useState<FormFilterTransaction>({});
  const [formFilterMyTransactionAffiliate, setFormFilterMyTransactionAffiliate] = useState<FormFilterTransaction>({});

  //SHOW CORPORATE
  const handleShowCorporate = (_data: CorporateValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      user_uid: _data.uid
    };

    corporateRep
      .showCorporate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setCorporate(result);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW MY TRANSACTION
  const handleFetchMyTransaction = (_data: CorporateValueParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      sort: 'created_at desc',
      page: _data.page,
      limit: 10,
      search: formFilterMyTransaction.search ?? null,
      type: formFilterMyTransaction.type ?? null,
      status: formFilterMyTransaction.status ?? null,
      transaction_at: formFilterMyTransaction.transaction_at_start
        ? [
            `${formatDateParam(formFilterMyTransaction.transaction_at_start)}`,
            `${formatDateParam(formFilterMyTransaction.transaction_at_end)}`
          ]
        : null
    };

    corporateRep
      .fetchCorporateMyTransactions(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyTransaction(result.data);
        setMyTransactionPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW MY TRANSACTION AFFILIATE
  const handleFetchMyTransactionAffiliate = (_data: CorporateValueParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      sort: 'created_at desc',
      page: _data.page,
      limit: 10,
      search: formFilterMyTransactionAffiliate.search ?? null,
      type: formFilterMyTransactionAffiliate.type ?? null,
      status: formFilterMyTransactionAffiliate.status ?? null,
      transaction_at: formFilterMyTransactionAffiliate.transaction_at_start
        ? [
            `${formatDateParam(formFilterMyTransactionAffiliate.transaction_at_start)}`,
            `${formatDateParam(formFilterMyTransactionAffiliate.transaction_at_end)}`
          ]
        : null
    };

    corporateRep
      .fetchCorporateAffiliateTransaction(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyTransactionAffiliate(result.data);
        setMyTransactionAffiliatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW MY AFFILIATE
  const handleFetchMyAffiliate = (_data: CorporateValueParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      page: _data.page,
      limit: 10
    };

    corporateRep
      .fetchCorporateMyAffiliates(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyAffiliate(result.data);
        setMyAffiliatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateValue = {
    formFilterMyTransaction,
    setFormFilterMyTransaction,
    formFilterMyTransactionAffiliate,
    setFormFilterMyTransactionAffiliate,
    myAffiliate,
    myAffiliatePagination,
    myTransaction,
    myTransactionPagination,
    myTransactionAffiliate,
    myTransactionAffiliatePagination,
    corporate,
    formCorporate,
    setFormCorporate,
    handleShowCorporate,
    handleAddCorporateModal,
    handleUpdateCorporateModal,
    handleFetchMyTransaction,
    handleFetchMyAffiliate,
    handleFetchMyTransactionAffiliate
  };

  return <CorporateContext.Provider value={contextValue}>{children}</CorporateContext.Provider>;
};

const useCorporateContext = (): CorporateValue => {
  const context = useContext(CorporateContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a CorporateProvider');
  }
  return context;
};

export { CorporateProvider, useCorporateContext };
