import { useEffect } from 'react';
import PPOBPage from '@/delivery/ui/admin/settings/ppob';
import { PPOBProvider } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';

const ViewPPOB = () => {
  useEffect(() => {
    document.title = 'PPOB';
  }, []);

  return (
    <div>
      <PPOBProvider>
        <PPOBPage />
      </PPOBProvider>
    </div>
  );
};

export default ViewPPOB;
