import DetailPPOB from '@/delivery/ui/admin/settings/ppob/detail/detail_ppob.tsx';
import { PPOBProvider } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';

const DetailPPOBPage = () => {
  return (
    <div>
      <PPOBProvider>
        <DetailPPOB />
      </PPOBProvider>
    </div>
  );
};

export default DetailPPOBPage;
