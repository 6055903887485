import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { GoldRateRepository } from '@/domain/repository/gold_rate_repository.ts';
import { GoldRateLatestModel } from '@/domain/model/gold_rate_lates_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateDashboardValueParams {
  uid?: string;
  page?: number;
  limit?: number;
}

interface CorporateDashboardValue {
  goldRateLatest?: GoldRateLatestModel;
  loadingRateGold: boolean;
  corporateDashboard?: CorporateModel;
  corporateDashboardPagination?: PaginationModel;
  handleShowCorporateDashboard: (_data: CorporateDashboardValueParams) => void;
  handleGetLatestGoldRate: () => void;
}

const CorporateDashboardContext = createContext<CorporateDashboardValue | null>(null);

const corporateDashboardRep = new CorporateRepository();
const goldRateRepository = new GoldRateRepository();

const CorporateDashboardProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const [goldRateLatest, setGoldRateLatest] = useState<GoldRateLatestModel>();
  const [corporateDashboard, setCorporateDashboard] = useState<CorporateModel>();

  const [loadingRateGold, setLoadingRateGold] = useState<boolean>(false);

  //SHOW CORPORATE
  const handleShowCorporateDashboard = (_data: CorporateDashboardValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      user_uid: _data.uid
    };

    corporateDashboardRep
      .showCorporate(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setCorporateDashboard(result);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //GET LATEST GOLD RATE
  const handleGetLatestGoldRate = () => {
    setLoadingRateGold(true);

    goldRateRepository
      .latestGoldRate()
      .then((result: any) => {
        setLoadingRateGold(false);
        // handleFetchGoldRate({ page: 1 });
        setGoldRateLatest(result);
      })
      .catch(error => {
        setLoadingRateGold(false);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateDashboardValue = {
    goldRateLatest,
    loadingRateGold,
    corporateDashboard,
    handleShowCorporateDashboard,
    handleGetLatestGoldRate
  };

  return <CorporateDashboardContext.Provider value={contextValue}>{children}</CorporateDashboardContext.Provider>;
};

const useCorporateDashboardContext = (): CorporateDashboardValue => {
  const context = useContext(CorporateDashboardContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a CorporateDashboardProvider');
  }
  return context;
};

export { CorporateDashboardProvider, useCorporateDashboardContext };
