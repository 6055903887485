import { ModalDialog, ModalDialogProps } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { useReferralContext } from '@/delivery/ui/admin/data_member/member_list/context/referral_context.tsx';
import { useEffect, useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Loading from '@/delivery/components/atoms/loading/loading.tsx';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';

const DialogListUserReferral = (props: ModalDialogProps) => {
  const { user } = useMemberListContext();
  const {
    handleAddParentReferralModal,
    handleSelectChildReferral,
    handleSelectParentReferral,
    loading,
    formReferral,
    setFormReferral,
    handleFetchUser,
    users
  } = useReferralContext();

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (search.length > 3) {
      handleFetchUser({ search: search });
    }
  }, [search]);

  return (
    <ModalDialog
      overflow={false}
      z_index={'z-60'}
      size="small"
      title={`Select User ${handleAddParentReferralModal.state ? ' Parent' : 'Child'}`}
      modal={props.modal}
    >
      <div className="flex gap-[20px] items-center">
        <div className={'w-full '}>
          <Select2
            label={'Select User (type to search)'}
            onInputChange={e => {
              setSearch(e);
            }}
            placeholder={'Select User, type to search'}
            options={users.map(item => {
              return {
                label: item.email,
                value: item.email
              };
            })}
            onChange={(e: any) => {
              setFormReferral({
                ...formReferral,
                child_email: e.value
              });
            }}
          />
        </div>
        <div className={!loading ? 'hidden' : ''}>{loading ? <Loading /> : ''}</div>
      </div>
      <div className={'mt-[20px] justify-end flex'}>
        {handleAddParentReferralModal.state ? (
          <Button
            label={'Save'}
            onClick={() =>
              handleSelectParentReferral({
                parent_email: formReferral.child_email,
                child_email: user?.email,
                user_uid: user?.UUID
              })
            }
          />
        ) : (
          <Button
            label={'Save'}
            onClick={() =>
              handleSelectChildReferral({
                parent_email: user?.email,
                child_email: formReferral.child_email,
                user_uid: user?.UUID
              })
            }
          />
        )}
      </div>
    </ModalDialog>
  );
};

export default DialogListUserReferral;
