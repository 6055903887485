import CorporateTransactionList from '@/delivery/ui/corporate/transactions/transaction_list/corporate_transaction_list.tsx';
import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogAddTransaction from '@/delivery/ui/corporate/transactions/transaction_list/dialog/dialog_add_transaction.tsx';
import { useCorporateTransactionListContext } from '@/delivery/ui/corporate/transactions/transaction_list/context/corporate_transaction_list_context.tsx';
import DialogAddBeriEmas from '@/delivery/ui/corporate/transactions/transaction_list/dialog/beri_emas/dialog_add_beri_emas.tsx';

const CorporateTransactionListPage = () => {
  const { handleAddBeriEmasModal, handleAddTransactionModal } = useCorporateTransactionListContext();

  return (
    <div>
      <Navbar label={'List Transaction'}>
        <Button
          prefixIcon={Icon.plus}
          label={'Tambah Transaksi'}
          onClick={() => handleAddTransactionModal.setState(true)}
        />
      </Navbar>
      <CorporateTransactionList />

      {handleAddTransactionModal.state && <DialogAddTransaction modal={handleAddTransactionModal} />}

      {handleAddBeriEmasModal.state && <DialogAddBeriEmas modal={handleAddBeriEmasModal} />}
    </div>
  );
};

export default CorporateTransactionListPage;
