import { BaseModel } from '@/domain/model/base_model.ts';

export class PaymentChannelInstructionModel extends BaseModel {
  paymentChannelUID: string;
  title: string;
  instruction: string;
  lang: string;
  displayOrder: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    paymentChannelUID: string,
    title: string,
    instruction: string,
    lang: string,
    displayOrder: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.paymentChannelUID = paymentChannelUID;
    this.title = title;
    this.instruction = instruction;
    this.lang = lang;
    this.displayOrder = displayOrder;
  }

  public static fromJson(data: any): any | PaymentChannelInstructionModel {
    try {
      return new PaymentChannelInstructionModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.payment_channel_uid,
        data.title,
        data.instruction,
        data.lang,
        data.display_order
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): PaymentChannelInstructionModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(PaymentChannelInstructionModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
