import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { usePPOBContext } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';
import { PPOBModel } from '@/domain/model/ppob_model.ts';
import Icon from '@/domain/constant/icon.tsx';
import FilterPPOB from '@/delivery/ui/admin/settings/ppob/filter_ppob.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';

const PPOB = () => {
  const navigate = useNavigate();
  const { ppobProducts } = usePPOBContext();

  return (
    <div>
      <Theader>
        <Typography size={16} fontWeight={600} type={'secondary-invert'}>
          Table PPOB
        </Typography>
        <div>
          <FilterPPOB />
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Code</Th>
            <Th>Type</Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Base Price</Th>
            <Th>Price</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ppobProducts.length !== 0 ? (
            <>
              {ppobProducts?.map((item: PPOBModel, index: number) => (
                <Tr>
                  <Td>
                    <Typography fontWeight={400} size={14} type="tertiary-invert">
                      {item.code}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={14} type="tertiary-invert">
                      {item.type}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={14} type="tertiary-invert">
                      {item.name}
                    </Typography>
                  </Td>

                  <Td>
                    <Chips
                      type={item.status === STATUS_CONSTANT.active ? 'primary-light' : 'danger-light'}
                      label={item.status}
                    />
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={14} type="tertiary-invert">
                      {item.getBasePriceRupiah}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography fontWeight={400} size={14} type="tertiary-invert">
                      {item.getPriceRupiah}
                    </Typography>
                  </Td>

                  <Td>
                    <div className="flex gap-[4px]">
                      <Button
                        iconButton
                        icon={Icon.eye}
                        onClick={() => navigate(Routing.SETTINGS.PPOB_DETAIL + item.vendorUUID + '/' + item.code)}
                      />
                    </div>
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={7}>
                <Typography size={12} style={'italic'}>
                  Filter untuk menampilkan data
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default PPOB;
