import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { useBusinessVerifyContext } from '@/delivery/ui/admin/data_corporate/business_verify/context/business_verify_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_CORPORATE_LIST } from '@/domain/constant/status_corporate.ts';
import { useEffect, useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';

const FilterBusinessVerify = () => {
  const {
    handleFetchMasterBank,
    masterBanks,
    handleFetchBusinessVerify,
    formFilterBusinessVerify,
    setFormFilterBusinessVerify
  } = useBusinessVerifyContext();

  useEffect(() => {
    handleFetchMasterBank();
  }, []);

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterBusinessVerify({ search: '', master_bank_uuid: '', account_number: '', account_holder_name: '' });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchBusinessVerify({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterBusinessVerify, isResetting]);

  return (
    <div className="flex gap-[12px]">
      <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
      <ButtonFilter
        onClickApply={() => handleFetchBusinessVerify({})}
        size={'large'}
        titleFilter={'Filter Business Verify'}
        label={'More Filter'}
      >
        <div className={'flex flex-col gap-[20px]'}>
          <TextField
            value={formFilterBusinessVerify.search}
            search
            placeholder={'Search...'}
            block
            onChange={e =>
              setFormFilterBusinessVerify({
                ...formFilterBusinessVerify,
                search: e.target.value
              })
            }
          />
          <div className={'flex gap-[20px]'}>
            <Select2
              value={formFilterBusinessVerify.master_bank_uuid}
              block
              options={masterBanks.map(item => {
                return {
                  label: item.name,
                  value: item.UID
                };
              })}
              label={'Bank Name'}
              onChange={e => setFormFilterBusinessVerify({ ...formFilterBusinessVerify, master_bank_uuid: e.value })}
            />
            <TextField
              value={formFilterBusinessVerify.account_holder_name}
              block
              label={'Bank Acc Holder'}
              onChange={e =>
                setFormFilterBusinessVerify({
                  ...formFilterBusinessVerify,
                  account_holder_name: e.target.value
                })
              }
            />
            <TextField
              value={formFilterBusinessVerify.account_number}
              type={'number'}
              block
              label={'Bank Acc Number'}
              onChange={e =>
                setFormFilterBusinessVerify({ ...formFilterBusinessVerify, account_number: e.target.value })
              }
            />
          </div>
          <div className={'flex gap-[20px]'}>
            <TextField
              value={formFilterBusinessVerify.type}
              block
              label={'Type'}
              onChange={e =>
                setFormFilterBusinessVerify({
                  ...formFilterBusinessVerify,
                  type: e.target.value
                })
              }
            />
            <Select2
              value={formFilterBusinessVerify.status}
              options={STATUS_CORPORATE_LIST.map(item => {
                return {
                  label: item.name,
                  value: item.UUID
                };
              })}
              block
              label={'Status'}
              onChange={e => setFormFilterBusinessVerify({ ...formFilterBusinessVerify, status: e.value })}
            />
          </div>
        </div>
      </ButtonFilter>
    </div>
  );
};

export default FilterBusinessVerify;
