import { BaseModel } from '@/domain/model/base_model.ts';
import { UserModel } from '@/domain/model/user_model.ts';

export class ChildUserAffiliateModel extends BaseModel {
  parentUUID: string;
  child: UserModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    parentUUID: string,
    child: UserModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.parentUUID = parentUUID;
    this.child = child;
  }

  public static fromJson(data: any): any | ChildUserAffiliateModel {
    try {
      return new ChildUserAffiliateModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.parent_uuid,
        data.child ? UserModel.fromJson(data.child) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): ChildUserAffiliateModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(ChildUserAffiliateModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
