import { BaseModel } from '@/domain/model/base_model.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';

export class PPOBModel extends BaseModel {
  vendorUUID: string;
  code: string;
  type: string;
  name: string;
  description: string;
  status: string;
  basePrice: number;
  price: number;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    vendorUUID: string,
    code: string,
    type: string,
    name: string,
    description: string,
    status: string,
    basePrice: number,
    price: number
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.vendorUUID = vendorUUID;
    this.code = code;
    this.type = type;
    this.name = name;
    this.description = description;
    this.status = status;
    this.basePrice = basePrice;
    this.price = price;
  }

  get getPriceRupiah(): string {
    if (this.price) {
      return formatRupiah(this.price);
    }

    return formatRupiah(0);
  }

  get getBasePriceRupiah(): string {
    if (this.basePrice) {
      return formatRupiah(this.basePrice);
    }

    return formatRupiah(0);
  }

  public static fromJson(data: any): PPOBModel | any {
    try {
      return new PPOBModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.vendor_uid,
        data.code,
        data.type,
        data.name,
        data.description,
        data.status,
        data.base_price,
        data.price
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): PPOBModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(PPOBModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}

export class PPOBMarginModel extends BaseModel {
  discountFlat: number;
  discountPercentage: number;
  marginFlat: number;
  marginPercentage: number;
  status: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,

    discountFlat: number,
    discountPercentage: number,
    marginFlat: number,
    marginPercentage: number,
    status: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.discountFlat = discountFlat;
    this.discountPercentage = discountPercentage;
    this.marginFlat = marginFlat;
    this.marginPercentage = marginPercentage;
    this.status = status;
  }

  public static fromJson(data: any): PPOBMarginModel | any {
    try {
      return new PPOBMarginModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.discount_flat,
        data.discount_percentage,
        data.margin_flat,
        data.margin_percentage,
        data.status
      );
    } catch (e) {
      return null;
    }
  }

  public static fromJsonList(data: any): PPOBMarginModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(PPOBMarginModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
