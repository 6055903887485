import { useEffect } from 'react';
import BusinessVerifyPage from '../../admin/data_corporate/business_verify';
import { BusinessVerifyProvider } from '@/delivery/ui/admin/data_corporate/business_verify/context/business_verify_context.tsx';

const ViewBusinessVerify = () => {
  useEffect(() => {
    document.title = 'Business Verify';
  }, []);

  return (
    <div>
      <BusinessVerifyProvider>
        <BusinessVerifyPage />
      </BusinessVerifyProvider>
    </div>
  );
};

export default ViewBusinessVerify;
