import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import userSingle from '@/delivery/assets/icons/user_single.svg';
import userMultiple from '@/delivery/assets/icons/user_multiple.svg';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import ButtonCustom from '@/delivery/components/atoms/button/button_custom.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useState } from 'react';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import { useCorporateTransactionListContext } from '@/delivery/ui/corporate/transactions/transaction_list/context/corporate_transaction_list_context.tsx';

const DialogAddBeriEmas = (props: ModalDialogProps) => {
  const { handleAddBeriEmasModal, handleAddTransactionModal } = useCorporateTransactionListContext();
  const navigate = useNavigate();
  const [active1, setActive1] = useState<boolean>(false);
  const [active2, setActive2] = useState<boolean>(false);

  const routingPage = () => {
    if (active2) {
      navigate(Routing.CORPORATE_TRANSACTION_BERI_EMAS_MULTIPLE);
    }
  };

  const backButton = () => {
    handleAddBeriEmasModal.setState(false);
    handleAddTransactionModal.setState(true);
  };

  return (
    <ModalDialog overflow={false} size="small" title="Beri Emas" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <ButtonCustom
          disabled={true}
          isActive={active1}
          onClick={() => {
            setActive1(true);
            setActive2(false);
          }}
        >
          <div className={'items-center flex gap-[20px]'}>
            <Image url={userSingle} width={50} />
            <div>
              <Typography size={14} fontWeight={600}>
                Beri Emas ke Satu User
              </Typography>
              <Typography size={12} fontWeight={400} type={'tertiary-invert'}>
                Beri emas dengan mudah dan cepat kepada satu pengguna.
              </Typography>
            </div>
          </div>
        </ButtonCustom>
        <ButtonCustom
          isActive={active2}
          onClick={() => {
            setActive1(false);
            setActive2(true);
          }}
        >
          <div className={'items-center flex gap-[20px]'}>
            <Image url={userMultiple} width={60} />
            <div>
              <Typography size={14} fontWeight={600}>
                Beri Emas ke Banyak User
              </Typography>
              <Typography size={12} fontWeight={400} type={'tertiary-invert'}>
                Beri emas kepada beberapa pengguna sekaligus. Ideal untuk hadiah grup atau distribusi emas massal.
              </Typography>
            </div>
          </div>
        </ButtonCustom>
      </div>
      <ModalDialogFooter>
        <div className={'flex gap-[20px] justify-end'}>
          <Button label={'Kembali'} type={'secondary'} onClick={() => backButton()} />
          <Button disabled={active1 || active2 ? false : true} label={'Lanjutkan'} onClick={() => routingPage()} />
        </div>
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddBeriEmas;
