import { useEffect } from 'react';
import { BsFilter } from 'react-icons/bs';
import Button from '@/delivery/components/atoms/button/button';
import { Table, Tbody, Td, Th, Thead, Theader, Tr, Tfooter } from '@/delivery/components/molecules/table/table';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import Pagination from '@/delivery/components/atoms/pagination/pagination';
import Typography from '@/delivery/components/atoms/typography/typography';
import { useCorporateContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_list_context.tsx';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
// import { Routing } from '@/domain/constant/router_path.ts';
// import { useNavigate } from 'react-router-dom';

const CorporateList = () => {
  // const navigate = useNavigate();
  const { handleFetchCorporate, corporates, corporatePagination } = useCorporateContext();

  useEffect(() => {
    handleFetchCorporate({});
  }, []);

  return (
    <div>
      {/*<div className="flex gap-[24px]">*/}
      {/*  <SimpleCard title="Total User Register" bigTitle="307.365" />*/}
      {/*  <SimpleCard title="Total Corporate Verified" bigTitle="55.642" />*/}
      {/*  <SimpleCard title="Total Corporate Active" bigTitle="79.041" />*/}
      {/*</div>*/}
      <div className="mt-[32px]">
        <Theader>
          <TextField search placeholder="Search" />
          <div className="flex gap-[12px]">
            <Button label="More Filter" type="secondary" prefixIcon={<BsFilter />} />
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Corporate</Th>
              <Th>Reg Date</Th>
              <Th>Website</Th>
              <Th>Status</Th>
              {/*<Th>Action</Th>*/}
            </Tr>
          </Thead>
          <Tbody>
            {corporates.map((item: CorporateModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {item.name}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.emailCorporate}
                  </Typography>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.phoneCorporate}
                  </Typography>
                </Td>

                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getCreatedAt}
                  </Typography>
                </Td>
                {/*<Td>*/}
                {/*  <Chips type="danger-light" label="Verified" className="leading-4" />*/}
                {/*  <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert">*/}
                {/*    2023-05-20 20:02:46*/}
                {/*  </Typography>*/}
                {/*</Td>*/}
                <Td>
                  <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.website}
                  </Typography>
                </Td>
                <Td>
                  <Chips label={item.status} removeDot type={item.getStatusColor} />
                </Td>
                {/*<Td>*/}
                {/*  <div className="flex gap-[4px]">*/}
                {/*    /!*<Button iconButton iconType={'lock'} />*!/*/}
                {/*    <Button*/}
                {/*      iconButton*/}
                {/*      iconType={'view'}*/}
                {/*      onClick={() => navigate(Routing.DATA_CORPORATE.CORPORATE_LIST_DETAIL + item.UID)}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</Td>*/}
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            handlePagination={page => {
              handleFetchCorporate({
                page: page
              });
            }}
            page={corporatePagination?.page || 1}
            totalPages={corporatePagination?.totalPages || 1}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default CorporateList;
