import { useEffect } from 'react';
import ReferralTransactionPage from '@/delivery/ui/corporate/referral/referral_transaction';
import { ReferralTransactionProvider } from '@/delivery/ui/corporate/referral/referral_transaction/context/corporate_referral_transaction_context.tsx';

const ViewCorporateReferralTransaction = () => {
  useEffect(() => {
    document.title = 'Referral Transaction';
  }, []);

  return (
    <ReferralTransactionProvider>
      <ReferralTransactionPage />
    </ReferralTransactionProvider>
  );
};

export default ViewCorporateReferralTransaction;
