const Access = {
  WITHDRAWAL_FEE: {
    SHOW: 'withdrawal_fee:show',
    FETCH: 'withdrawal_fee:fetch',
    STORE: 'withdrawal_fee:store',
    STORE_BULK: 'withdrawal_fee:store-bulk'
  },

  WITHDRAWAL_METHOD: {
    SHOW: 'withdrawal_method:show',
    FETCH: 'withdrawal_method:fetch',
    STORE: 'withdrawal_method:store',
    STORE_BULK: 'withdrawal_method:store-bulk',
    UPDATE: 'withdrawal_method:update',
    DELETE: 'withdrawal_method:delete'
  },

  USER_VERIFICATION: {
    SHOW: 'user_verification:show',
    FETCH: 'user_verification:fetch',
    STORE: 'user_verification:store',
    STORE_BULK: 'user_verification:store-bulk',
    UPDATE: 'user_verification:update',
    DELETE: 'user_verification:delete'
  },

  USER_WITHDRAWAL_ACCOUNT: {
    SHOW: 'user_withdrawal_account:show',
    FETCH: 'user_withdrawal_account:fetch',
    STORE: 'user_withdrawal_account:store',
    STORE_BULK: 'user_withdrawal_account:store-bulk',
    UPDATE: 'user_withdrawal_account:update',
    DELETE: 'user_withdrawal_account:delete'
  },

  VENDOR: {
    SHOW: 'vendor:show',
    FETCH: 'vendor:fetch',
    STORE: 'vendor:store',
    STORE_BULK: 'vendor:store-bulk',
    UPDATE: 'vendor:update',
    DELETE: 'vendor:delete'
  },

  WEB_PAGE: {
    SHOW: 'web_page:show',
    FETCH: 'web_page:fetch',
    STORE: 'web_page:store',
    STORE_BULK: 'web_page:store-bulk',
    UPDATE: 'web_page:update',
    DELETE: 'web_page:delete'
  },

  USER_ADDRESS: {
    SHOW: 'user_address:show',
    FETCH: 'user_address:fetch',
    STORE: 'user_address:store',
    UPDATE: 'user_address:update',
    DELETE: 'user_address:delete'
  },

  USER_AFFILIATE: {
    SHOW: 'user_affiliate:show',
    FETCH: 'user_affiliate:fetch',
    STORE: 'user_affiliate:store',
    STORE_BULK: 'user_affiliate:store-bulk',
    UPDATE: 'user_affiliate:update',
    DELETE: 'user_affiliate:delete'
  },

  USER_COMPANY: {
    SHOW: 'user_company:show',
    FETCH: 'user_company:fetch',
    STORE: 'user_company:store',
    STORE_BULK: 'user_company:store-bulk',
    UPDATE: 'user_company:update',
    DELETE: 'user_company:delete'
  },

  USER_DASHBOARD: {
    PROFILE: 'user_dashboard:profile',
    BALANCE: 'user_dashboard:balance'
  },

  USER_HEIR: {
    SHOW: 'user_heir:show',
    FETCH: 'user_heir:fetch',
    STORE: 'user_heir:store',
    STORE_BULK: 'user_heir:store-bulk',
    UPDATE: 'user_heir:update',
    DELETE: 'user_heir:delete'
  },

  USER: {
    SHOW: 'user:show',
    COUNT: 'user:count',
    DELETE: 'user:delete',
    FETCH: 'user:fetch',
    UPDATE: 'user:update',
    AFFILIATE_COUNTER: 'user:affiliate-count'
  },

  TRANSACTION_CALLBACK: {
    IPAYMU: 'transaction_callback:ipaymu'
  },

  TRANSACTION: {
    SHOW: 'transaction:show',
    FETCH: 'transaction:fetch',
    UPDATE: 'transaction:update',
    DELETE: 'transaction:delete',
    FETCH_AFFILIATE: 'transaction:fetch-affiliate',
    MANUAL: 'transaction:manual',
    CONFIRMATION_PRINT: 'transaction:confirmation-print',
    SETTLEMENT: 'transaction:settlement'
  },

  TRANSACTION_MEMBER: {
    SHOW: 'transaction-member:show',
    FETCH: 'transaction-member:fetch',
    BUY: 'transaction-member:buy',
    TRANSFER: 'transaction-member:transfer',
    WITHDRAWAL: 'transaction-member:withdraw',
    PRINT_COUNTER: 'transaction-member:print-counter',
    PRINT_SHIPPING: 'transaction-member:print-shipping',
    PRINT_SHIPPING_RATES: 'transaction-member:print-shipping-rates',
    PRINT_VENDING: 'transaction-member:print-vending',
    LOCK: 'transaction-member:lock',
    DCA: 'transaction-member:dca',
    DREAM_GOLD_STORE: 'transaction-member:dream-gold-store',
    DREAM_GOLD_WITHDRAWAL: 'transaction-member:dream-gold-withdraw',
    PPOB: 'transaction-member:pppob'
  },

  SHIPPING_METHOD: {
    SHOW: 'shipping_method:show',
    FETCH: 'shipping_method:fetch',
    STORE: 'shipping_method:store',
    STORE_BULK: 'shipping_method:store-bulk',
    UPDATE: 'shipping_method:update',
    DELETE: 'shipping_method:delete'
  },

  TIER: {
    SHOW: 'tier:show',
    FETCH: 'tier:fetch',
    STORE: 'tier:store',
    STORE_BULK: 'tier:store-bulk',
    UPDATE: 'tier:update',
    DELETE: 'tier:delete'
  },

  PROFILE: {
    GET: 'profile:get',
    FETCH: 'profile:fetch',
    UPDATE: 'profile:update'
  },

  REGION: {
    NATIONALITY_FETCH: 'region:nationality-fetch',
    PROVINCE_FETCH: 'region:province-fetch',
    CITIES_FETCH: 'region:cities-fetch',
    DISTRICT_FETCH: 'region:district-fetch',
    VILLAGE_FETCH: 'region:village-fetch'
  },

  ROLE: {
    SHOW: 'role:show',
    FETCH: 'role:fetch',
    STORE: 'role:store',
    STORE_BULK: 'role:store-bulk',
    UPDATE: 'role:update',
    DELETE: 'role:delete'
  },

  ACCESS_CONTROLLER: {
    FETCH_MODULE: 'access-controller:fetch-access-module',
    FETCH: 'access-controller:fetch-access'
  },

  ROLE_ACCESS: {
    SHOW: 'role_access:show',
    FETCH: 'role_access:fetch',
    STORE: 'role_access:store',
    STORE_BULK: 'role_access:store-bulk',
    UPDATE: 'role_access:update',
    DELETE: 'role_access:delete'
  },

  ROLE_USER_COMPANY: {
    SHOW: 'role_user_company:show',
    FETCH: 'role_user_company:fetch',
    STORE: 'role_user_company:store',
    STORE_BULK: 'role_user_company:store-bulk',
    UPDATE: 'role_user_company:update',
    DELETE: 'role_user_company:delete'
  },

  PAYMENT_CHANNEL: {
    SHOW: 'payment_channel:show',
    FETCH: 'payment_channel:fetch',
    STORE: 'payment_channel:store',
    STORE_BULK: 'payment_channel:store-bulk',
    UPDATE: 'payment_channel:update',
    DELETE: 'payment_channel:delete'
  },

  PAYMENT_CHANNEL_FEE: {
    SHOW: 'payment_channel_fee:show',
    FETCH: 'payment_channel_fee:fetch',
    STORE: 'payment_channel_fee:store',
    STORE_BULK: 'payment_channel_fee:store-bulk'
  },

  PAYMENT_METHOD: {
    SHOW: 'payment_method:show',
    FETCH: 'payment_method:fetch',
    STORE: 'payment_method:store',
    STORE_BULK: 'payment_method:store-bulk',
    UPDATE: 'payment_method:update',
    DELETE: 'payment_method:delete'
  },

  OFFLINE_COUNTER_UNAVAILABILITY: {
    SHOW: 'offline_counter_unavailability:show',
    FETCH: 'offline_counter_unavailability:fetch',
    STORE: 'offline_counter_unavailability:store',
    STORE_BULK: 'offline_counter_unavailability:store-bulk',
    UPDATE: 'offline_counter_unavailability:update',
    DELETE: 'offline_counter_unavailability:delete'
  },

  OFFLINE_COUNTER: {
    SHOW: 'offline_counter:show',
    FETCH: 'offline_counter:fetch',
    STORE: 'offline_counter:store',
    STORE_BULK: 'offline_counter:store-bulk',
    UPDATE: 'offline_counter:update',
    DELETE: 'offline_counter:delete'
  },

  COMPANY: {
    SHOW: 'company:show',
    FETCH: 'company:fetch',
    STORE: 'company:store',
    STORE_BULK: 'company:store-bulk',
    UPDATE: 'company:update',
    DELETE: 'company:delete'
  },
  MASTER_BANK: {
    SHOW: 'master_bank:show',
    FETCH: 'master_bank:fetch',
    STORE: 'master_bank:store',
    STORE_BULK: 'master_bank:store-bulk',
    UPDATE: 'master_bank:update',
    DELETE: 'master_bank:delete'
  },

  MEDIA: {
    SHOW: 'media:show',
    FETCH: 'media:fetch',
    STORE: 'media:store',
    MULTIPLE: 'media:multiple',
    UPDATE: 'media:update',
    DELETE: 'media:delete',
    GET_FILE: 'get-file'
  },

  MEDIA_ASSIGNMENT: {
    FETCH: 'media_assignment:fetch',
    STORE: 'media_assignment:store',
    UPDATE: 'media_assignment:update',
    DELETE: 'media_assignment:delete'
  },

  NOTIFICATION: {
    SHOW: 'notification:show',
    FETCH: 'notification:fetch',
    STORE: 'notification:store',
    STORE_BULK: 'notification:store-bulk',
    UPDATE: 'notification:update',
    DELETE: 'notification:delete'
  },

  GOLD_PIECE_FEE: {
    SHOW: 'gold_piece:show',
    FETCH: 'gold_piece:fetch',
    STORE: 'gold_piece:store',
    STORE_BULK: 'gold_piece:store-bulk',
    UPDATE: 'gold_piece:update',
    DELETE: 'gold_piece:delete'
  },

  GOLD_PIECE: {
    SHOW: 'gold_piece_fee:show',
    FETCH: 'gold_piece_fee:fetch',
    STORE: 'gold_piece_fee:store',
    STORE_BULK: 'gold_piece_fee:store-bulk',
    UPDATE: 'gold_piece_fee:update',
    DELETE: 'gold_piece_fee:delete'
  },

  GOLD_STOCK: {
    SHOW: 'gold_stock:show',
    FETCH: 'gold_stock:fetch',
    STORE: 'gold_stock:store',
    STORE_BULK: 'gold_stock:store-bulk',
    UPDATE: 'gold_stock:update',
    DELETE: 'gold_stock:delete'
  },

  GOLD_RATE: {
    SHOW: 'gold_rate:show',
    FETCH: 'gold_rate:fetch',
    STORE: 'gold_rate:store',
    STORE_BULK: 'gold_rate:store-bulk',
    UPDATE: 'gold_rate:update',
    DELETE: 'gold_rate:delete'
  },

  CUSTOM_ACCESS: {
    SHOW: 'custom_access:show',
    FETCH: 'custom_access:fetch',
    STORE: 'custom_access:store',
    STORE_BULK: 'custom_access:store-bulk',
    UPDATE: 'custom_access:update',
    DELETE: 'custom_access:delete'
  },

  DREAM_GOLD: {
    SHOW: 'dream_gold:show',
    FETCH: 'dream_gold:fetch',
    STORE: 'dream_gold:store',
    STORE_BULK: 'dream_gold:store-bulk',
    UPDATE: 'dream_gold:update',
    DELETE: 'dream_gold:delete'
  },

  EVENT_AFFILIATE: {
    SHOW: 'event_affiliate:show',
    FETCH: 'event_affiliate:fetch',
    STORE: 'event_affiliate:store',
    STORE_BULK: 'event_affiliate:store-bulk',
    UPDATE: 'event_affiliate:update',
    DELETE: 'event_affiliate:delete'
  },

  CORPORATE_VERIFICATION: {
    SHOW: 'corporate_verification:show',
    FETCH: 'corporate_verification:fetch',
    STORE: 'corporate_verification:store',
    STORE_BULK: 'corporate_verification:store-bulk',
    UPDATE: 'corporate_verification:update',
    DELETE: 'corporate_verification:delete'
  },

  COMPANY_SETTING: {
    SHOW: 'company_setting:show',
    FETCH: 'company_setting:fetch',
    STORE: 'company_setting:store',
    UPDATE: 'company_setting:update',
    DELETE: 'company_setting:delete'
  },

  ADDRESS: {
    SHOW: 'address:show',
    FETCH: 'address:fetch',
    STORE: 'address:store',
    STORE_BULK: 'address:store-bulk',
    UPDATE: 'address:update',
    DELETE: 'address:delete'
  },

  AFFILIATE: {
    SHOW: 'affiliate:show',
    FETCH: 'affiliate:fetch',
    STORE: 'affiliate:store',
    STORE_BULK: 'affiliate:store-bulk',
    UPDATE: 'affiliate:update',
    DELETE: 'affiliate:delete'
  },

  RICE_RATE: {
    SHOW: 'rice_rate:show',
    FETCH: 'rice_rate:fetch',
    STORE: 'rice_rate:store',
    STORE_BULK: 'rice_rate:store-bulk',
    UPDATE: 'rice_rate:update',
    DELETE: 'rice_rate:delete'
  },

  BALANCE: {
    SHOW: 'balance_lock_history:show',
    FETCH: 'balance_lock_history:fetch',
    STORE: 'balance_lock_history:store',
    STORE_BULK: 'balance_lock_history:store-bulk',
    UPDATE: 'balance_lock_history:update',
    DELETE: 'balance_lock_history:delete'
  },

  DCA: {
    SHOW: 'dca:show'
  },

  DINARAN_SETTING: {
    SHOW: 'dinaran_setting:show',
    FETCH: 'dinaran_setting:fetch',
    STORE: 'dinaran_setting:store',
    STORE_BULK: 'dinaran_setting:store-bulk',
    UPDATE: 'dinaran_setting:update',
    DELETE: 'dinaran_setting:delete'
  },

  DCA_REQUEST: {
    SHOW: 'dca_request:show',
    FETCH: 'dca_request:fetch',
    STORE: 'dca_request:store',
    STORE_BULK: 'dca_request:store-bulk',
    UPDATE: 'dca_request:update',
    DELETE: 'dca_request:delete',
    GRAM_CLOSE: 'dca_request:gram-close',
    TRANSFER_CLOSE: 'dca_request:transfer-close'
  },

  CORPORATE: {
    FETCH: 'corporate:fetch',
    STORE: 'corporate:store',
    STORE_BULK: 'corporate:store-bulk',
    UPDATE: 'corporate:update',
    DELETE: 'corporate:delete',
    MY_AFFILIATE: 'corporate:my-affiliates',
    MY_TRX: 'corporate:my-trx',
    FETCH_TRX: 'corporate:fetch-trx'
  },

  DASHBOARD: {
    TRANSACTION_SUMMARY: 'dashboard:transaction-summary',
    BALANCE_RANK: 'dashboard:balance-rank',
    TRANSACTION_DAILY: 'dashboard:transaction-daily'
  },

  PPOB_MARGIN: {
    SHOW: 'ppob_margin:show',
    FETCH: 'ppob_margin:fetch',
    STORE: 'ppob_margin:store',
    STORE_BULK: 'ppob_margin:store-bulk',
    UPDATE: 'ppob_margin:update',
    DELETE: 'ppob_margin:delete'
  },

  PPOOB_PRODUCT: {
    SHOW: 'ppob_product:show',
    FETCH: 'ppob_product:fetch',
    STORE: 'ppob_product:store',
    STORE_BULK: 'ppob_product:store-bulk',
    UPDATE: 'ppob_product:update',
    DELETE: 'ppob_product:delete'
  }
} as const;

export default Access;
