const PPOBList = [
  { UUID: 'PPOB', name: 'PPOB' },
  { UUID: 'PULSA', name: 'PULSA' },
  { UUID: 'GAME', name: 'GAME' }
];

const PPOBTypeConst = {
  PPOB: 'PPOB',
  PULSA: 'PULSA',
  GAME: 'GAME'
};

const PPOBMarginTypeList = [
  { UUID: 'MARGIN', name: 'MARGIN' },
  { UUID: 'DISCOUNT', name: 'DISCOUNT' }
];

const PPOBMarginTypeConst = {
  MARGIN: 'MARGIN',
  DISCOUNT: 'DISCOUNT'
};

export { PPOBList, PPOBTypeConst, PPOBMarginTypeList, PPOBMarginTypeConst };
