import React, { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import 'react-quill/dist/quill.snow.css';

interface IATextEditorProps {
  label?: string;
  value?: string;
  onChange?: (data: string) => void;
}

const TextEditor2: React.FC<IATextEditorProps> = ({ label, value = '', onChange }) => {
  const [editorValue, setEditorValue] = useState<string>(value || '');

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const handleChange = (content: string) => {
    setEditorValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <div className="flex flex-col mb-10">
      <div className="text-text-secondary-invert text-[14px] text-[500] mb-[6px]">{label}</div>
      <SunEditor
        setOptions={{
          height: '200',
          buttonList: [
            ['undo', 'redo'],
            ['bold', 'underline', 'italic', 'strike'],
            ['list', 'link', 'image', 'video'],
            ['codeView']
          ]
        }}
        setContents={editorValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextEditor2;
