import useAlertToast from '@/delivery/hooks/useAlertComponent';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { UserDashboardRepository } from '@/domain/repository/user_dashboard.ts';
import { UserDashboardBalanceModel } from '@/domain/model/user_dashboard_balance.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { DreamGoldRepository } from '@/domain/repository/dream_gold_repository.ts';
import { DreamGoldModel } from '@/domain/model/dream_gold_model.ts';
import { BalanceModel } from '@/domain/model/balance_model.ts';
import { BalanceLockRepository } from '@/domain/repository/balance_lock_repository.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { PaginationModel } from '@/domain/model/pagination_model.ts';

interface BalanceContextProps {
  children: ReactNode;
}

interface BalanceParams {
  user_uid?: string;
  uid?: string;
  uuid?: string;
  page?: number;
  limit?: number;
}

interface FormDreamGold {
  uid?: string;
  status?: string;
  user_uid?: string;
}

interface FormBalance {
  uid?: string;
  user_uid?: string;
  type?: string;
  amount?: number;
  new_amount?: number;
}

interface BalanceContextValue {
  balanceLocks: BalanceModel[];
  balanceLockPagination?: PaginationModel;
  formBalance: FormBalance;
  setFormBalance: Dispatch<SetStateAction<FormBalance>>;
  formDreamGold: FormDreamGold;
  setFormDreamGold: Dispatch<SetStateAction<FormDreamGold>>;
  balance?: UserDashboardBalanceModel;
  handleShowBalance: (_data: BalanceParams) => void;
  handleUpdateDreamGoldModal: ModalObject;
  handleLockGoldModal: ModalObject;
  handleShowDreamGold: (_data: DreamGoldModel) => void;
  handleUpdateDreamGold: () => void;
  handleShowBalanceLock: (_data: BalanceModel) => void;
  handleReleaseGold: () => void;
  handleFetchBalanceLock: (_data: BalanceParams) => void;
}

const BalanceContext = createContext<BalanceContextValue | null>(null);

const balanceRep = new UserDashboardRepository();
const dreamGoldRep = new DreamGoldRepository();
const balanceLockRep = new BalanceLockRepository();

const BalanceProvider: React.FC<BalanceContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const [formDreamGold, setFormDreamGold] = useState<FormDreamGold>({});
  const [formBalance, setFormBalance] = useState<FormBalance>({});

  const [balance, setBalance] = useState<UserDashboardBalanceModel>();

  const [balanceLocks, setBalanceLocks] = useState<BalanceModel[]>([]);
  const [balanceLockPagination, setBalanceLockPagination] = useState<PaginationModel>();

  const handleUpdateDreamGoldModal = useVisibleComponent(false);
  const handleLockGoldModal = useVisibleComponent(false);

  //HANDLE SHOW BALANCE
  const handleShowBalance = (_data: BalanceParams) => {
    const x = alertToast.loadingAlert('');
    const param = {
      user_uid: _data.user_uid
    };
    balanceRep
      .showUserDashboardBalance(param)
      .then((result: any) => {
        setBalance(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
        alertToast.updateLoading(x);
      });
  };

  //SHOW DREAM GOLD
  const handleShowDreamGold = (_data: DreamGoldModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    dreamGoldRep
      .showDreamGold(params)
      .then((result: any) => {
        const res: DreamGoldModel = result;

        setFormDreamGold({ uid: res.UID, status: res.status, user_uid: res.userUID });
        handleUpdateDreamGoldModal.setState(true);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPDATE DREAM GOLD
  const handleUpdateDreamGold = () => {
    const params = {
      uid: formDreamGold.uid,
      status: formDreamGold.status
    };

    dreamGoldRep
      .updateDreamGold(params)
      .then((result: any) => {
        handleShowBalance({ user_uid: formDreamGold.user_uid });
        alertToast.successAlert();
        handleUpdateDreamGoldModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW
  const handleShowBalanceLock = (_data: BalanceModel) => {
    setFormBalance({
      uid: _data.UID,
      user_uid: _data.userUID,
      amount: _data.amount,
      type: _data.type
    });

    handleLockGoldModal.setState(true);
  };

  //HANDLE RELEASE GOLD
  const handleReleaseGold = () => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          user_uid: formBalance.user_uid,
          type: formBalance.type,
          amount: formBalance.new_amount
        };

        balanceLockRep
          .releaseBalanceLock(params)
          .then(() => {
            handleShowBalance({ user_uid: formBalance.user_uid });
            alertToast.successAlert();
            handleLockGoldModal.setState(false);
            alertToast.updateLoading(x);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE FETCH BALANCE LOCK USER
  const handleFetchBalanceLock = (_data: BalanceParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      user_uid: _data.user_uid,
      sort: 'created_at desc'
    };

    balanceLockRep
      .fetchBalanceLock(params)
      .then((result: any) => {
        setBalanceLocks(result.data);
        setBalanceLockPagination(result.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: BalanceContextValue = {
    balanceLockPagination,
    balanceLocks,
    formBalance,
    setFormBalance,
    formDreamGold,
    setFormDreamGold,
    balance,
    handleShowBalance,
    handleUpdateDreamGoldModal,
    handleLockGoldModal,
    handleShowDreamGold,
    handleUpdateDreamGold,
    handleShowBalanceLock,
    handleReleaseGold,
    handleFetchBalanceLock
  };

  return <BalanceContext.Provider value={contextValue}>{children}</BalanceContext.Provider>;
};

const useBalanceContext = (): BalanceContextValue => {
  const context = useContext(BalanceContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a BalanceProvider');
  }
  return context;
};

export { BalanceProvider, useBalanceContext };
