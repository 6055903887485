import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import ReferralTransaction from '@/delivery/ui/corporate/referral/referral_transaction/referral_transaction.tsx';

const ReferralTransactionPage = () => {
  return (
    <div>
      <Navbar label={'Referral Transaction'}></Navbar>
      <ReferralTransaction />
    </div>
  );
};

export default ReferralTransactionPage;
