import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import dinaranLogo from '@/delivery/assets/dinaran-logo.png';
import { Image } from '@/delivery/components/atoms/image/image';

import { useState } from 'react';
import DialogForgotPassword from './forgot_password/forgot_password_dialog';
import { useAuthContext } from '@/delivery/ui/login/context/auth_context.tsx';
import { getSendEmailOTP, removeSendEmailOTP } from '@/infrastructure/helper/auth.ts';
import { COMPANY_UUID } from '@/domain/constant/company/company_uuid.ts';
import Icon from '@/domain/constant/icon.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';

const LoginOTP = () => {
  const navigate = useNavigate();
  const { excLoginOTP, loginOTP, loading, handleForgotPasswordModal } = useAuthContext();

  const [email, setEmail] = useState<string>('');
  const [otp, setOTP] = useState<string>('');

  const handleKeyDownLoginOTP = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      loginOTP({ email: email });
    }
  };

  const handleKeyDownExcLoginOTP = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      excLoginOTP({ otp: otp, username: email, company_uuid: COMPANY_UUID });
    }
  };

  const handleBackToEmail = () => {
    removeSendEmailOTP();
    location.reload();
  };

  return (
    <div className="flex flex-col justify-center items-center h-[100vh] bg-content-bg-secondary">
      <div className="flex items-center flex-col gap-[20px] lg:w-[420px] md:w-[420px] sm:lg:w-full bg-content-bg-white shadow-lg p-[40px] rounded-[20px]">
        <div className="mb-[40px]">
          <Image width={150} url={dinaranLogo} />
        </div>
        {getSendEmailOTP() === null ? (
          <div className={'w-full'}>
            <TextField
              value={email}
              block
              label="Email"
              onChange={e => setEmail(e.target.value)}
              onKeyDown={handleKeyDownLoginOTP}
            />

            <div className="mt-[30px] w-full">
              <Button
                loading={loading}
                block
                label="Login"
                onKeyUp={() =>
                  loginOTP({
                    email: email
                  })
                }
                onClick={() => {
                  loginOTP({
                    email: email
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className={'w-full'}>
            <TextField
              value={otp}
              block
              label="OTP"
              onKeyDown={handleKeyDownExcLoginOTP}
              onChange={e => setOTP(e.target.value)}
            />

            <div className="mt-[30px] w-full flex gap-1">
              <Button prefixIcon={Icon.arrowLeft} onClick={() => handleBackToEmail()} />
              <Button
                loading={loading}
                block
                label="Send OTP"
                onKeyUp={() =>
                  excLoginOTP({
                    otp: otp,
                    username: email,
                    company_uuid: COMPANY_UUID
                  })
                }
                onClick={() =>
                  excLoginOTP({
                    otp: otp,
                    username: email,
                    company_uuid: COMPANY_UUID
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className={'mt-[20px]'}>
        <Typography tag={'p'} size={14} fontWeight={500} type={'tertiary-invert'}>
          Belum punya akun bisnis ?{' '}
          <span
            className={'text-bg-primary cursor-pointer'}
            onClick={() => navigate(Routing.REGISTRATION_CORPORATE_USER)}
          >
            daftar sekarang
          </span>
        </Typography>
      </div>

      {handleForgotPasswordModal.state && <DialogForgotPassword modal={handleForgotPasswordModal} />}
    </div>
  );
};

export default LoginOTP;
