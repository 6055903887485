import { Table, Tbody, Td, Tfooter, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/corporate/context/corporate_context.tsx';
import { STATUS_CONST } from '@/domain/constant/status.ts';
import { AffiliateUserModel } from '@/domain/model/affiliate_user_model.ts';

const MyAffiliate = () => {
  const { myAffiliate, myAffiliatePagination, handleFetchMyAffiliate } = useCorporateContext();

  return (
    <div className="mt-[32px]">
      {/*<Theader>*/}
      {/*  <div></div>*/}
      {/*</Theader>*/}
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Member</Th>
            <Th>Balance (gr)</Th>
            <Th>Lock Ball (gr)</Th>
            <Th>Reg Date</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {myAffiliate.map((item: AffiliateUserModel, index) => (
            <Tr key={index}>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  <span className={'text-text-primary'}>{item.child?.serialID}</span>
                </Typography>
                <Typography fontWeight={500} size={12} type="primary-invert">
                  {item.child?.firstName} {item.child?.lastName}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.child?.email}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {item.child?.phone}
                </Typography>
              </Td>
              <Td>
                <div className="text-right">
                  <Typography fontWeight={500} size={12} type="secondary-invert">
                    {item.child?.balance.getTruncateAmountDecimal}
                  </Typography>

                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.child?.balance?.gram}
                  </Typography>
                </div>
              </Td>

              <Td>
                <div className="flex text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">
                  <div className={'w-full'}>
                    {/*<p>Emas Impian:</p>*/}
                    <p>Dinaran:</p>
                    <p>User:</p>
                    {/*<p>Saldo DCA:</p>*/}
                    {/*<p>Total DCA:</p>*/}
                  </div>
                  <div className={'text-right w-full text-text-secondary-invert font-[600]'}>
                    {/*<p>0</p>*/}
                    <p>{item.child.getTruncateSystemAmountDecimal}</p>
                    <p>{item.child.getTruncateMemberAmountDecimal}</p>
                  </div>
                </div>
              </Td>
              <Td>
                <Typography size={12} fontWeight={400} type="tertiary-invert">
                  {item.child.getCreatedAt}
                </Typography>
              </Td>

              <Td>
                <Chips
                  removeDot
                  label={item.child.status.toUpperCase()}
                  type={STATUS_CONST.ACTIVE === item.child.status.toUpperCase() ? 'primary-light' : 'danger-light'}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Tfooter>
        <Pagination
          handlePagination={page => {
            handleFetchMyAffiliate({
              page: page
            });
          }}
          page={myAffiliatePagination?.page || 1}
          totalPages={myAffiliatePagination?.totalPages || 1}
        />
      </Tfooter>
    </div>
  );
};

export default MyAffiliate;
