import { truncateDecimal } from '@/infrastructure/helper/truncateDecimalNumber.ts';
import GOLD_RATE_ARROW from '@/domain/constant/gold_rate/gold_rate_arrow.ts';

const countRateChange = (currentNumber?: number, previousNumber?: number) => {
  if (currentNumber && previousNumber) {
    const numberChange: number = currentNumber - previousNumber;
    const resultPercentage = (numberChange / previousNumber) * 100;

    return truncateDecimal(resultPercentage);
  }
  return 0;
};

const setUpDown = (currentNumber?: number, previousNumber?: number) => {
  if (currentNumber && previousNumber) {
    const res = currentNumber - previousNumber;
    if (res > 0) {
      return GOLD_RATE_ARROW.UP;
    }

    if (res < 0) {
      return GOLD_RATE_ARROW.DOWN;
    }

    if (res === 0) {
      return GOLD_RATE_ARROW.FLAT;
    }
  }
};

export { countRateChange, setUpDown };
