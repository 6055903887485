import { useEffect } from 'react';

const ViewCorporateGoldConversion = () => {
  useEffect(() => {
    document.title = 'Konversi Emas';
  }, []);

  return <div>this is view gold conversion</div>;
};

export default ViewCorporateGoldConversion;
