import PaymentChannelInterface, {
  PaymentChannelValue
} from '@/domain/repository/interface/payment_channel_interface.ts';
import { ApiClient } from '@/main/apiClient';
import { PaginationModel } from '@/domain/model/pagination_model';
import { PaymentChannelModel } from '@/domain/model/payment/payment_channel_model.ts';
import { PaymentChannel, PaymentChannelInstruction } from '@/domain/constant/api_list';
import { PaymentChannelInstructionModel } from '@/domain/model/payment/payment_channel_instruction_model.ts';

export class PaymentChannelRepository implements PaymentChannelInterface {
  api = new ApiClient();

  async fetchPaymentChannel(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentChannel.FETCH, {
            params: data
          })
          .then(result => {
            const data = PaymentChannelModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showPaymentChannel(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentChannel.SHOW, {
            params: data
          })
          .then(result => {
            const data = PaymentChannelModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storePaymentChannel(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PaymentChannel.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storeBulkPaymentChannel(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PaymentChannel.STORE_BULK, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updatePaymentChannel(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(PaymentChannel.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deletePaymentChannel(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(PaymentChannel.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storePaymentInstruction(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PaymentChannelInstruction.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async showPaymentInstruction(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentChannelInstruction.SHOW, {
            params: data
          })
          .then(result => {
            const data = PaymentChannelInstructionModel.fromJson(result.data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updatePaymentInstruction(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(PaymentChannelInstruction.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchPaymentInstruction(data: PaymentChannelValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PaymentChannelInstruction.FETCH, {
            params: data
          })
          .then(result => {
            const data = PaymentChannelInstructionModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
