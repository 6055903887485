import { useEffect } from 'react';
import CorporateTransactionListPage from '@/delivery/ui/corporate/transactions/transaction_list';
import { CorporateTransactionListProvider } from '@/delivery/ui/corporate/transactions/transaction_list/context/corporate_transaction_list_context.tsx';

const ViewCorporateTransactionList = () => {
  useEffect(() => {
    document.title = 'Transaction';
  }, []);

  return (
    <CorporateTransactionListProvider>
      <CorporateTransactionListPage />
    </CorporateTransactionListProvider>
  );
};

export default ViewCorporateTransactionList;
