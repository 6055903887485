import {
  Table,
  Tbody,
  TBox,
  Td,
  Tfooter,
  Th,
  Thead,
  Theader,
  Tr
} from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';
import { useCorporateTransactionListContext } from '@/delivery/ui/corporate/transactions/transaction_list/context/corporate_transaction_list_context.tsx';
import { useEffect } from 'react';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';

import Pagination from '@/delivery/components/atoms/pagination/pagination.tsx';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import formatNumberWithDot from '@/infrastructure/helper/formatNumberWithDot.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';

const CorporateTransactionList = () => {
  const alertToast = useAlertToast();
  const prof = getProfile();
  const {
    profile,
    handleShowProfile,
    corporateTransactionList,
    corporateTransactionListPagination,
    handleFetchCorporateTransactionList
  } = useCorporateTransactionListContext();

  const handleFetchAll = async () => {
    try {
      const x = alertToast.loadingAlert('');
      await handleFetchCorporateTransactionList({});
      await handleShowProfile({ user_uuid: prof?.user_uuid });
      alertToast.updateLoading(x);
    } catch (e) {
      alertToast.errorAlert('');
    }
  };

  useEffect(() => {
    handleFetchAll();
  }, []);

  return (
    <div>
      <div>
        <Theader>
          <div className={'flex items-center gap-[20px]'}>
            <Typography size={14} fontWeight={600}>
              Saldo Terakhir
            </Typography>
            <Chips label={formatDateTime(new Date())} removeDot />
          </div>
        </Theader>
        <TBox>
          <div className={'flex lg:flex-row flex-col gap-[20px]'}>
            <SimpleCard title="Gramasi Emas" bigTitle={profile?.balance?.amount.toString() ?? ''} unit={'gr'} />
            <SimpleCard title="Amount" bigTitle={formatNumberWithDot(profile?.balance.fiat ?? 0) ?? ''} unit={'rp'} />
            <SimpleCard title="Koin" bigTitle={profile?.balanceCoin.amount.toString() ?? ''} />
            <SimpleCard
              title="Lock User"
              bigTitle={formatNumberWithDot(profile?.balanceMemberLock.amount ?? 0) ?? ''}
              unit={'gr'}
            />
            <SimpleCard
              title="Lock System"
              bigTitle={formatNumberWithDot(profile?.balanceSystemLock.amount ?? 0) ?? ''}
              unit={'gr'}
            />
          </div>
        </TBox>
      </div>
      <div className={'mt-[20px]'}>
        <Theader>
          <div className={'flex items-center gap-[20px]'}>
            <Typography size={14} fontWeight={600}>
              Data Transaksi
            </Typography>
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>TrxID</Th>
              <Th>Type</Th>
              <Th>Status</Th>
              <Th>
                <span className={'whitespace-nowrap'}>Trx Date</span>
              </Th>
              <Th>
                <span className={'whitespace-nowrap'}>Paid Date</span>
              </Th>
              <Th>Paid Amount</Th>
              <Th>Fee</Th>
              <Th>Vendor</Th>
              <Th>Vendor Ref ID</Th>
              <Th>Gramasi</Th>
              <Th>Gold Rate</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {corporateTransactionList?.map((item: TransactionModel, index) => (
              <Tr>
                <Td>
                  <Typography fontWeight={400} size={12} type="tertiary-invert">
                    {item.code}
                  </Typography>
                </Td>
                <Td>
                  <span className={'whitespace-nowrap'}>
                    <Chips label={item.type} removeDot />
                  </span>
                </Td>
                <Td>
                  {item.flags && item.type === TYPE_TRANSACTION.REVERSAL ? (
                    <Chips label={`${item.status} ${item.type}`} type={item.getStatusColor} />
                  ) : (
                    <Chips label={item.status} type={item.getStatusColor} />
                  )}
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getTransactionAtSingle}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    <span className={'whitespace-nowrap'}>{item.getSucceedAtSingle}</span>
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getAmountSingle}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.getFeeRupiahSingle}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.payment?.vendor}
                  </Typography>
                </Td>
                <Td>
                  <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                    {item.payment?.vendorRefID}
                  </Typography>
                </Td>
                <Td>
                  <div className={'whitespace-nowrap'}>
                    <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                      {item.getGram}
                    </Typography>
                  </div>
                </Td>
                <Td>
                  <Typography tag="p" fontWeight={600} size={12} type="tertiary-invert">
                    {item.rate.getBuy}
                  </Typography>
                  <Typography tag="p" fontWeight={400} size={12} type="tertiary-invert">
                    {item.rate.getSell}
                  </Typography>
                </Td>
                <Td>
                  <div className="flex gap-[4px]">
                    <Button iconButton iconType={'view'} />
                    <Button iconButton iconType={'banned'} />
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Tfooter>
          <Pagination
            page={corporateTransactionListPagination?.page || 0}
            totalPages={corporateTransactionListPagination?.totalPages || 0}
            handlePagination={page => {
              handleFetchCorporateTransactionList({
                page: page
              });
            }}
          />
        </Tfooter>
      </div>
    </div>
  );
};

export default CorporateTransactionList;
