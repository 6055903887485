import styles from './Image.module.css';
import clsx from 'clsx';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';

interface MsImageProps {
  url?: string;
  width?: number;
  height?: number;
  className?: string;
  circle?: boolean;
  zoomClick?: boolean;
}

export function Image(props: MsImageProps) {
  const { zoomClick = true, url, width, height, className, circle } = props;

  const classProps = clsx(className, `${circle ? styles['rounded'] : ''}`);

  return (
    <>
      {zoomClick ? (
        <PhotoProvider
          toolbarRender={({ rotate, onRotate, onScale, scale }) => {
            return (
              // <svg className="PhotoView-Slider__toolbarIcon bg-border-tertiary" onClick={() => onRotate(rotate + 90)} />
              <>
                <Button iconButton icon={Icon.plusCircle} onClick={() => onScale(scale + 1)} />
                <Button iconButton icon={Icon.minusCircle} onClick={() => onScale(scale - 1)} />
                <Button iconButton icon={Icon.rightRotate} onClick={() => onRotate(rotate + 90)} />
              </>
            );
          }}
        >
          <PhotoView src={url}>
            {url !== '#' ? (
              <img
                style={{ width: width, height: height }}
                height={height}
                width={width}
                className={classProps}
                src={url}
              />
            ) : (
              <p className={'italic text-[12px]'}>(no image)</p>
            )}
          </PhotoView>
        </PhotoProvider>
      ) : (
        <>
          {url !== '#' ? (
            <img
              style={{ width: width, height: height }}
              height={height}
              width={width}
              className={classProps}
              src={url}
            />
          ) : (
            <p className={'italic text-[12px]'}>(no image)</p>
          )}
        </>
      )}
    </>
  );
}
