import { BaseModel } from '@/domain/model/base_model';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { COMMISSION_TYPE } from '@/domain/constant/affiliate/type_affiliate.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';

export class AffiliateModel extends BaseModel {
  type: string;
  commission: number;
  commission_type: string;
  username: string;
  lastName: string;
  firstName: string;
  serialID: string;
  birthDate: string;
  emailVerified: string;
  verifiedAt: string;
  phone: string;
  status: string;
  profileImgUUID: string;
  userCompany: string;
  defaultCompanyUUID: string;
  gender: string;
  religion: string;
  birthPlace: string;
  identityNumber: string;
  identityMediaUID: string;
  selfieMediaUID: string;
  zipCode: string;
  fullAddress: string;
  dcaStatus: string;
  affiliates: string;
  token: string;
  commissionType: string;
  earning: number;
  earningGram: number;
  email: string;
  totalTrx: number;
  userUID: string;
  subject: string;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    type: string,
    commission: number,
    commission_type: string,
    username: string,
    lastName: string,
    firstName: string,
    serialID: string,
    birthDate: string,
    emailVerified: string,
    verifiedAt: string,
    phone: string,
    status: string,
    profileImgUUID: string,
    userCompany: string,
    defaultCompanyUUID: string,
    gender: string,
    religion: string,
    birthPlace: string,
    identityNumber: string,
    identityMediaUID: string,
    selfieMediaUID: string,
    zipCode: string,
    fullAddress: string,
    dcaStatus: string,
    affiliates: string,
    token: string,
    commissionType: string,
    earning: number,
    earningGram: number,
    email: string,
    totalTrx: number,
    userUID: string,
    subject: string
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy);
    this.type = type;
    this.commission = commission;
    this.commission_type = commission_type;
    this.username = username;
    this.lastName = lastName;
    this.firstName = firstName;
    this.serialID = serialID;
    this.birthDate = birthDate;
    this.emailVerified = emailVerified;
    this.verifiedAt = verifiedAt;
    this.phone = phone;
    this.status = status;
    this.profileImgUUID = profileImgUUID;
    this.userCompany = userCompany;
    this.defaultCompanyUUID = defaultCompanyUUID;
    this.gender = gender;
    this.religion = religion;
    this.birthPlace = birthPlace;
    this.identityNumber = identityNumber;
    this.identityMediaUID = identityMediaUID;
    this.selfieMediaUID = selfieMediaUID;
    this.zipCode = zipCode;
    this.fullAddress = fullAddress;
    this.dcaStatus = dcaStatus;
    this.affiliates = affiliates;
    this.token = token;
    this.commission = commission;
    this.commissionType = commissionType;
    this.type = type;
    this.earning = earning;
    this.earningGram = earningGram;
    this.email = email;
    this.totalTrx = totalTrx;
    this.userUID = userUID;
    this.subject = subject;
  }

  get getFullName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }
    return '-';
  }

  get getEarningRupiah(): string {
    if (this.earning) {
      return formatRupiah(this.earning);
    }
    return '-';
  }

  get getCommission(): string {
    if (this.commission_type === COMMISSION_TYPE.FLAT) {
      return formatRupiah(this.commission);
    }

    if (this.commission_type === COMMISSION_TYPE.PERCENT) {
      return `${this.commission}%`;
    }
    return '-';
  }

  get getStatusColor(): ChipType {
    if (this.status === STATUS_CORPORATE.VERIFIED) {
      return 'primary-light';
    }

    if (this.status === STATUS_TRANSACTION.REQUEST) {
      return 'warning-light';
    }

    if (this.status === STATUS_TRANSACTION.REJECT) {
      return 'danger-light';
    }
  }

  public static fromJson(data: any): any | AffiliateModel {
    try {
      return new AffiliateModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.affiliate ? data.affiliate.type : data.type,
        data.affiliate ? data.affiliate.commission : data.commission,
        data.affiliate ? data.affiliate.commission_type : data.commission_type,
        data.username,
        data.last_name,
        data.first_name,
        data.serial_id,
        data.birth_date,
        data.email_verified,
        data.verified_at,
        data.phone,
        data.status,
        data.profile_img_uuid,
        data.user_company,
        data.default_company_uuid,
        data.gender,
        data.religion,
        data.birth_place,
        data.identity_number,
        data.identity_media_uid,
        data.selfie_media_uid,
        data.zip_code,
        data.full_address,
        data.dca_status,
        data.affiliates,
        data.token,
        data.commission_type,
        data.total,
        data.total_gram,
        data.email,
        data.total_trx,
        data.user_uid,
        data.subject
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): AffiliateModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(AffiliateModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
