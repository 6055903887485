import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import PPOB from '@/delivery/ui/admin/settings/ppob/ppob.tsx';

const PPOBPage = () => {
  return (
    <div>
      <Navbar label={'Payment Point Online Bank'}></Navbar>
      <PPOB />
    </div>
  );
};

export default PPOBPage;
