import AResponse from '@/domain/constant/response';
import { jwtDecode } from 'jwt-decode';
import CompanyModel from '@/domain/model/company_model';

export function setToken(value: string) {
  localStorage.setItem(AResponse.SESSION_COOKIE, value);
}

export function getToken(): string | null {
  const xtoken = localStorage.getItem(AResponse.SESSION_COOKIE);
  if (xtoken != null) {
    return String(xtoken);
  }
  return null;
}

export function setAccess(value: string) {
  localStorage.setItem(AResponse.ACCESS_COOKIE, value);
}

export function getAccess(): any {
  const access = localStorage.getItem(AResponse.ACCESS_COOKIE);
  const arrayAccess = access?.split(',').map(item => item.trim());
  return arrayAccess;
}

export function removeToken() {
  localStorage.removeItem(AResponse.SESSION_COOKIE);
  localStorage.removeItem(AResponse.ACCESS_COOKIE);
}

export function getProfile() {
  const token: string | null = getToken();

  if (token != null) {
    const profile: any = jwtDecode(token);

    return {
      user_uuid: profile?.uuid,
      username: profile?.username,
      type: profile?.type,
      company_parent_uuid: profile?.login_company?.parent_uuid,
      company_uuid: profile?.login_company?.uuid,
      company_name: profile?.login_company?.name,
      email: profile?.email,
      first_name: profile?.first_name,
      name: profile?.first_name + ' ' + profile?.last_name || '',
      phone: profile?.phone,
      status: profile?.status,
      company: CompanyModel.fromJson(profile?.login_company),
      default_company_uuid: profile?.default_company_uuid,
      parent_company: CompanyModel.fromJson(profile?.super_company)
    };
  }
}

export function setSendEmailOTP(value: string) {
  localStorage.setItem('emailOTP', value);
}

export function getSendEmailOTP() {
  return localStorage.getItem('emailOTP');
}

export function removeSendEmailOTP() {
  localStorage.removeItem('emailOTP');
}
