import { FiGlobe } from 'react-icons/fi';
import { BiBarChart } from 'react-icons/bi';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { MdOutlinePersonOutline } from 'react-icons/md';
import { FiShoppingCart } from 'react-icons/fi';
import { AiOutlineGold } from 'react-icons/ai';
import { FiRefreshCw } from 'react-icons/fi';
import { LuBriefcase, LuNetwork } from 'react-icons/lu';
import { RiHandCoinLine } from 'react-icons/ri';
import { FiServer } from 'react-icons/fi';
import { GoPerson } from 'react-icons/go';

import { Routing } from './router_path';
import Access from '@/domain/constant/role/access.ts';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';

interface SidebarInterface {
  label?: string;
  prefixIcon?: any;
  href?: string;
  access: string[];
  submenu?: SidebarInterface[];
}

const profile = getProfile();

/* -------------------------------- MAIN MENU ------------------------------- */
let mainMenu: SidebarInterface[] = [];
if (profile?.type === MEMBER_TYPE.CORPORATE) {
  mainMenu = [
    {
      label: 'Dashboard',
      prefixIcon: <BiBarChart />,
      href: Routing.CORPORATE_DASHBOARD,
      access: [Access.CORPORATE.FETCH, Access.CORPORATE.MY_AFFILIATE, Access.CORPORATE.MY_TRX]
    },
    {
      label: 'Profile',
      prefixIcon: <GoPerson />,
      href: Routing.CORPORATE_PROFILE,
      access: [Access.CORPORATE.FETCH, Access.CORPORATE.MY_AFFILIATE, Access.CORPORATE.MY_TRX]
    },
    {
      label: 'Transaction',
      href: '/transaction',
      prefixIcon: <FiShoppingCart />,
      access: [Access.CORPORATE.FETCH, Access.CORPORATE.MY_AFFILIATE, Access.CORPORATE.MY_TRX],
      submenu: [
        {
          label: 'List Transaction',
          href: Routing.CORPORATE_TRANSACTION,
          access: [Access.USER.FETCH, Access.PROFILE.FETCH]
        }
        // {
        //   label: 'Konversi Emas',
        //   href: Routing.CORPORATE_GOLD_CONVERSION,
        //   access: [Access.USER.FETCH, Access.PROFILE.FETCH]
        // }
      ]
    },
    {
      label: 'Referral',
      prefixIcon: <LuNetwork />,
      href: '/referral',
      access: [Access.CORPORATE.FETCH, Access.CORPORATE.MY_AFFILIATE, Access.CORPORATE.MY_TRX],
      submenu: [
        {
          label: 'Data Referral',
          href: Routing.CORPORATE_REFERRAL_DATA,
          access: [Access.USER.FETCH, Access.PROFILE.FETCH]
        },
        {
          label: 'Referral Transaction',
          href: Routing.CORPORATE_REFERRAL_TRANSACTION,
          access: [Access.USER.FETCH, Access.PROFILE.FETCH]
        }
      ]
    }

    // {
    //   label: 'Corporate',
    //   prefixIcon: <HiOutlineBuildingOffice2 />,
    //   href: Routing.CORPORATE,
    //   access: [Access.CORPORATE.FETCH, Access.CORPORATE.MY_AFFILIATE, Access.CORPORATE.MY_TRX]
    // }
  ];
} else {
  mainMenu = [
    {
      label: 'Dashboard',
      prefixIcon: <BiBarChart />,
      href: '/dashboard',
      access: [
        Access.DASHBOARD.BALANCE_RANK,
        Access.DASHBOARD.TRANSACTION_SUMMARY,
        Access.DASHBOARD.TRANSACTION_DAILY,
        Access.USER.FETCH,
        Access.PROFILE.FETCH,
        Access.TRANSACTION.FETCH
      ],
      submenu: [
        {
          label: 'Overview',
          href: Routing.DASHBOARD.OVERVIEW,
          access: [Access.USER.FETCH, Access.PROFILE.FETCH]
        },
        {
          label: 'Ranking',
          href: Routing.DASHBOARD.RANKING,
          access: [Access.DASHBOARD.BALANCE_RANK]
        },
        {
          label: 'Member',
          href: Routing.DASHBOARD.MEMBER,
          access: [Access.USER.FETCH, Access.PROFILE.FETCH]
        },
        {
          label: 'Transaction',
          href: Routing.DASHBOARD.TRANSACTION,
          access: [Access.TRANSACTION.FETCH, Access.DASHBOARD.TRANSACTION_SUMMARY, Access.DASHBOARD.TRANSACTION_DAILY]
        },
        {
          label: 'Profit Loss',
          href: '#',
          access: []
        },
        {
          label: 'Statistik Akses',
          href: Routing.DASHBOARD.ACCESS_STATISTIC,
          access: []
        }
      ]
    },
    {
      label: 'Harga Emas',
      prefixIcon: <FaArrowTrendUp />,
      href: Routing.GOLD_PRICE,
      access: [Access.GOLD_RATE.FETCH, Access.GOLD_RATE.STORE, Access.GOLD_RATE.SHOW]
    },
    {
      label: 'Data Member',
      prefixIcon: <MdOutlinePersonOutline />,
      href: '/data_member',
      access: [
        Access.PROFILE.FETCH,
        Access.PROFILE.GET,
        Access.BALANCE.SHOW,
        Access.USER_HEIR.SHOW,
        Access.USER_HEIR.UPDATE,
        Access.USER_HEIR.STORE,
        Access.USER_ADDRESS.SHOW,
        Access.USER_VERIFICATION.FETCH,
        Access.USER_VERIFICATION.SHOW,
        Access.USER_VERIFICATION.UPDATE,
        Access.USER_WITHDRAWAL_ACCOUNT.FETCH,
        Access.USER_WITHDRAWAL_ACCOUNT.SHOW,
        Access.USER_WITHDRAWAL_ACCOUNT.STORE,
        Access.USER_WITHDRAWAL_ACCOUNT.UPDATE,
        Access.CORPORATE_VERIFICATION.FETCH,
        Access.CORPORATE_VERIFICATION.SHOW,
        Access.CORPORATE_VERIFICATION.UPDATE
      ],
      submenu: [
        {
          label: 'Member List',
          href: Routing.DATA_MEMBER.MEMBER_LIST,
          access: [
            Access.PROFILE.FETCH,
            Access.PROFILE.GET,
            Access.BALANCE.SHOW,
            Access.USER_HEIR.SHOW,
            Access.USER_HEIR.UPDATE,
            Access.USER_HEIR.STORE,
            Access.USER_ADDRESS.SHOW
          ]
        },
        {
          label: 'Member Verify',
          href: Routing.DATA_MEMBER.MEMBER_VERIFY,
          access: [Access.USER_VERIFICATION.FETCH, Access.USER_VERIFICATION.SHOW, Access.USER_VERIFICATION.UPDATE]
        },
        {
          label: 'Verify E-Money',
          href: Routing.DATA_MEMBER.VERIFY_E_MONEY,
          access: [
            Access.USER_WITHDRAWAL_ACCOUNT.FETCH,
            Access.USER_WITHDRAWAL_ACCOUNT.SHOW,
            Access.USER_WITHDRAWAL_ACCOUNT.STORE,
            Access.USER_WITHDRAWAL_ACCOUNT.UPDATE
          ]
        }

        // {
        //   label: 'Member Third Party',
        //   href: '#',
        //   access: []
        // }
      ]
    },
    {
      label: 'Data Corporate',
      prefixIcon: <LuBriefcase />,
      href: '/data_corporate',
      access: [
        Access.CORPORATE.FETCH,
        Access.CORPORATE_VERIFICATION.FETCH,
        Access.CORPORATE_VERIFICATION.SHOW,
        Access.CORPORATE_VERIFICATION.UPDATE
      ],
      submenu: [
        {
          label: 'Corporate List',
          href: Routing.DATA_CORPORATE.CORPORATE_LIST,
          access: [Access.CORPORATE.FETCH]
        },
        {
          label: 'Corporate Verify',
          href: Routing.DATA_CORPORATE.BUSINESS_VERIFY,
          access: [
            Access.CORPORATE_VERIFICATION.FETCH,
            Access.CORPORATE_VERIFICATION.SHOW,
            Access.CORPORATE_VERIFICATION.UPDATE
          ]
        },
        {
          label: 'Request Verify',
          href: Routing.DATA_CORPORATE.REQUEST_VERIFY,
          access: []
        },
        {
          label: 'Data Transaksi',
          href: Routing.DATA_CORPORATE.DATA_TRANSACTION,
          access: []
        },
        {
          label: 'Referal List',
          href: Routing.DATA_CORPORATE.REFERAL_LIST,
          access: []
        },
        {
          label: 'Referal Transaksi',
          href: Routing.DATA_CORPORATE.REFERAL_TRANSACTION,
          access: []
        }
      ]
    },
    {
      label: 'Transaction',
      prefixIcon: <FiShoppingCart />,
      href: '/transaction',
      access: [
        Access.GOLD_STOCK.FETCH,
        Access.GOLD_STOCK.STORE,
        Access.GOLD_STOCK.SHOW,
        Access.GOLD_STOCK.UPDATE,
        Access.TRANSACTION.FETCH,
        Access.TRANSACTION.SHOW,
        Access.TRANSACTION.UPDATE,
        Access.TRANSACTION.MANUAL,
        Access.TRANSACTION.FETCH,
        Access.TRANSACTION.SHOW,
        Access.TRANSACTION.UPDATE,
        Access.GOLD_STOCK.FETCH,
        Access.GOLD_STOCK.STORE,
        Access.GOLD_STOCK.SHOW,
        Access.GOLD_STOCK.UPDATE,
        Access.OFFLINE_COUNTER.FETCH,
        Access.OFFLINE_COUNTER.SHOW,
        Access.OFFLINE_COUNTER.STORE,
        Access.OFFLINE_COUNTER.UPDATE
      ],
      submenu: [
        {
          label: 'Transaction List',
          href: Routing.TRANSACTION.TRANSACTION_LIST,
          access: [Access.TRANSACTION.FETCH, Access.TRANSACTION.SHOW, Access.TRANSACTION.UPDATE]
        },
        {
          label: 'Manual Transaction',
          href: Routing.TRANSACTION.MANUAL_TRANSACTION,
          access: [Access.TRANSACTION.MANUAL]
        },
        {
          label: 'Settlement',
          href: Routing.TRANSACTION.SETTLEMENT,
          access: [Access.TRANSACTION.SETTLEMENT]
        },
        {
          label: 'Ambil Emas',
          prefixIcon: <AiOutlineGold />,
          href: '/transaction/ambil_emas',
          access: [
            Access.TRANSACTION.FETCH,
            Access.TRANSACTION.SHOW,
            Access.TRANSACTION.UPDATE,
            Access.GOLD_STOCK.FETCH,
            Access.GOLD_STOCK.STORE,
            Access.GOLD_STOCK.SHOW,
            Access.GOLD_STOCK.UPDATE,
            Access.OFFLINE_COUNTER.FETCH,
            Access.OFFLINE_COUNTER.SHOW,
            Access.OFFLINE_COUNTER.STORE,
            Access.OFFLINE_COUNTER.UPDATE
          ],
          submenu: [
            {
              label: 'History',
              href: Routing.TRANSACTION.AMBIL_EMAS.HISTORY,
              access: [Access.TRANSACTION.FETCH, Access.TRANSACTION.SHOW, Access.TRANSACTION.UPDATE]
            },
            {
              label: 'Stock Emas',
              href: Routing.TRANSACTION.AMBIL_EMAS.STOCK_GOLD,
              access: [
                Access.GOLD_STOCK.FETCH,
                Access.GOLD_STOCK.STORE,
                Access.GOLD_STOCK.SHOW,
                Access.GOLD_STOCK.UPDATE
              ]
            },
            {
              label: 'Counter Offline',
              href: Routing.TRANSACTION.AMBIL_EMAS.COUNTER_OFFLINE,
              access: [
                Access.OFFLINE_COUNTER.FETCH,
                Access.OFFLINE_COUNTER.SHOW,
                Access.OFFLINE_COUNTER.STORE,
                Access.OFFLINE_COUNTER.UPDATE
              ]
            },
            {
              label: 'Vending Machine',
              href: Routing.TRANSACTION.AMBIL_EMAS.VENDING_MACHINE,
              access: []
            }
          ]
        }
        // {
        //   label: 'ATM Emas',
        //   prefixIcon: <MdOutlineAtm />,
        //   href: '/transaction/atm_gold',
        //   access: [Access.GOLD_STOCK.FETCH, Access.GOLD_STOCK.STORE, Access.GOLD_STOCK.SHOW, Access.GOLD_STOCK.UPDATE],
        //   submenu: [
        //     {
        //       label: 'Stock ATM Emas',
        //       href: Routing.TRANSACTION.ATM_EMAS.STOCK_ATM_GOLD,
        //       access: [Access.GOLD_STOCK.FETCH, Access.GOLD_STOCK.STORE, Access.GOLD_STOCK.SHOW, Access.GOLD_STOCK.UPDATE]
        //     },
        //     {
        //       label: 'Lokasi ATM',
        //       href: Routing.TRANSACTION.ATM_EMAS.ATM_LOCATION,
        //       access: []
        //     }
        //   ]
        // }
      ]
    },
    {
      label: 'Data DCA',
      prefixIcon: <FiRefreshCw />,
      href: '/data_dca',
      access: [
        Access.PROFILE.FETCH,
        Access.PROFILE.UPDATE,
        Access.DCA_REQUEST.FETCH,
        Access.DCA_REQUEST.SHOW,
        Access.DCA_REQUEST.STORE,
        Access.TRANSACTION.FETCH,
        Access.TRANSACTION.SHOW,
        Access.TRANSACTION.UPDATE,
        Access.DCA.SHOW
      ],
      submenu: [
        {
          label: 'DCA Activation',
          href: Routing.DATA_DCA.DCA_ACTIVATION,
          access: [Access.PROFILE.FETCH, Access.PROFILE.UPDATE]
        },
        {
          label: 'Account List',
          href: Routing.DATA_DCA.ACCOUNT_LIST,
          access: [Access.DCA.SHOW]
        },
        {
          label: 'Account Request',
          href: Routing.DATA_DCA.ACCOUNT_REQUEST,
          access: [Access.DCA_REQUEST.FETCH, Access.DCA_REQUEST.SHOW, Access.DCA_REQUEST.STORE]
        },
        {
          label: 'Account Transaction',
          href: Routing.DATA_DCA.ACCOUNT_TRANSACTIONS,
          access: [Access.TRANSACTION.FETCH, Access.TRANSACTION.SHOW, Access.TRANSACTION.UPDATE]
        }
      ]
    },
    {
      label: 'Setor Emas ',
      prefixIcon: <RiHandCoinLine />,
      href: '/deposit_gold',
      access: [Access.TRANSACTION.FETCH, Access.TRANSACTION.UPDATE],
      submenu: [
        {
          label: 'By Admin',
          href: Routing.DEPOSIT_GOLD.DEPOSIT_BY_ADMIN,
          access: [Access.TRANSACTION.FETCH, Access.TRANSACTION.SHOW, Access.TRANSACTION.UPDATE]
        },
        {
          label: 'Microgold by ATM',
          href: Routing.DEPOSIT_GOLD.MICROGOLD_BY_ATM,
          access: []
        }
      ]
    },
    {
      label: 'Reseller / Affiliate ',
      prefixIcon: <LuNetwork />,
      href: '/reseller_affiliate',
      access: [
        Access.EVENT_AFFILIATE.FETCH,
        Access.EVENT_AFFILIATE.STORE,
        Access.EVENT_AFFILIATE.UPDATE,
        Access.EVENT_AFFILIATE.DELETE,
        Access.TRANSACTION.FETCH,
        Access.TRANSACTION.UPDATE,
        Access.AFFILIATE.FETCH,
        Access.AFFILIATE.STORE,
        Access.AFFILIATE.SHOW,
        Access.AFFILIATE.UPDATE
      ],
      submenu: [
        {
          label: 'Affiliate List',
          href: Routing.RESELLER_AFFILIATE.AFFILIATE_LIST,
          access: [Access.AFFILIATE.FETCH, Access.AFFILIATE.STORE, Access.AFFILIATE.SHOW, Access.AFFILIATE.UPDATE]
        },
        {
          label: 'Affiliate Transaction',
          href: Routing.RESELLER_AFFILIATE.AFFILIATE_TRANSACTION,
          access: [Access.TRANSACTION.FETCH, Access.TRANSACTION.SHOW, Access.TRANSACTION.UPDATE]
        },
        {
          label: 'Event Referral',
          href: Routing.RESELLER_AFFILIATE.EVENT_REFERRAL,
          access: [
            Access.EVENT_AFFILIATE.FETCH,
            Access.EVENT_AFFILIATE.STORE,
            Access.EVENT_AFFILIATE.UPDATE,
            Access.EVENT_AFFILIATE.DELETE
          ]
        }
      ]
    }
  ];
}

/* ------------------------------- BOTTOM MENU ------------------------------ */

let bottomMenu: SidebarInterface[] = [];

if (profile?.type !== MEMBER_TYPE.CORPORATE) {
  bottomMenu = [
    {
      label: 'Master',
      prefixIcon: <FiServer />,
      href: '/master',
      access: [
        Access.TIER.FETCH,
        Access.TIER.STORE,
        Access.TIER.UPDATE,
        Access.TIER.DELETE,
        Access.AFFILIATE.FETCH,
        Access.AFFILIATE.STORE,
        Access.AFFILIATE.UPDATE,
        Access.AFFILIATE.DELETE,
        Access.GOLD_PIECE.FETCH,
        Access.GOLD_PIECE.STORE,
        Access.GOLD_PIECE.UPDATE,
        Access.GOLD_PIECE.DELETE,
        Access.GOLD_PIECE_FEE.FETCH,
        Access.GOLD_PIECE_FEE.STORE,
        Access.GOLD_PIECE_FEE.UPDATE,
        Access.GOLD_PIECE_FEE.DELETE,
        Access.RICE_RATE.FETCH,
        Access.RICE_RATE.STORE,
        Access.RICE_RATE.UPDATE,
        Access.RICE_RATE.DELETE
      ],
      submenu: [
        {
          label: 'Gold Piece',
          href: Routing.MASTER.GOLD_PIECE,
          access: [
            Access.GOLD_PIECE.FETCH,
            Access.GOLD_PIECE.STORE,
            Access.GOLD_PIECE.UPDATE,
            Access.GOLD_PIECE.DELETE,
            Access.GOLD_PIECE_FEE.FETCH,
            Access.GOLD_PIECE_FEE.STORE,
            Access.GOLD_PIECE_FEE.UPDATE,
            Access.GOLD_PIECE_FEE.DELETE
          ]
        },
        {
          label: 'Member Loyalty',
          href: Routing.MASTER.MEMBER_LOYALTY,
          access: [Access.TIER.FETCH, Access.TIER.STORE, Access.TIER.UPDATE, Access.TIER.DELETE]
        },
        {
          label: 'Member Affiliate',
          href: Routing.MASTER.MEMBER_AFFILIATE,
          access: [Access.AFFILIATE.FETCH, Access.AFFILIATE.STORE, Access.AFFILIATE.UPDATE, Access.AFFILIATE.DELETE]
        },
        {
          label: 'Rice Rate',
          href: Routing.MASTER.RICE_RATE,
          access: [Access.RICE_RATE.FETCH, Access.RICE_RATE.STORE, Access.RICE_RATE.UPDATE, Access.RICE_RATE.DELETE]
        }
      ]
    },
    {
      label: 'Web Admin',
      prefixIcon: <FiGlobe />,
      href: '/web_admin',
      access: [
        Access.NOTIFICATION.FETCH,
        Access.NOTIFICATION.UPDATE,
        Access.NOTIFICATION.STORE,
        Access.NOTIFICATION.DELETE,
        Access.WEB_PAGE.FETCH,
        Access.WEB_PAGE.UPDATE,
        Access.WEB_PAGE.STORE,
        Access.WEB_PAGE.DELETE
      ],
      submenu: [
        // {
        //   label: 'Media',
        //   href: Routing.WEB_ADMIN.MEDIA
        // },
        {
          label: 'Notify App',
          href: Routing.WEB_ADMIN.NOTIFY_APP,
          access: [
            Access.NOTIFICATION.FETCH,
            Access.NOTIFICATION.UPDATE,
            Access.NOTIFICATION.STORE,
            Access.NOTIFICATION.DELETE
          ]
        },
        // {
        //   label: "Web PopUp",
        //   href: Routing.WEB_ADMIN.WEB_POP_UP,
        // },
        {
          label: 'Web Pages',
          href: Routing.WEB_ADMIN.WEB_PAGES,
          access: [Access.WEB_PAGE.FETCH, Access.WEB_PAGE.UPDATE, Access.WEB_PAGE.STORE, Access.WEB_PAGE.DELETE]
        }
      ]
    },
    {
      label: 'Setting',
      prefixIcon: <AiOutlineSetting />,
      href: '/settings',
      access: [
        Access.ROLE.FETCH,
        Access.ROLE.UPDATE,
        Access.ROLE.SHOW,
        Access.ROLE.STORE,
        Access.ROLE.DELETE,
        Access.ROLE.STORE_BULK,
        Access.ROLE_ACCESS.FETCH,
        Access.ROLE_ACCESS.UPDATE,
        Access.ROLE_ACCESS.DELETE,
        Access.ROLE_ACCESS.STORE,
        Access.USER.FETCH,
        Access.PAYMENT_CHANNEL.FETCH,
        Access.PAYMENT_CHANNEL.SHOW,
        Access.PAYMENT_CHANNEL.UPDATE,
        Access.PAYMENT_CHANNEL_FEE.FETCH,
        Access.COMPANY_SETTING.FETCH,
        Access.COMPANY_SETTING.SHOW,
        Access.COMPANY_SETTING.UPDATE,
        Access.DINARAN_SETTING.SHOW,
        Access.DINARAN_SETTING.UPDATE,
        Access.DINARAN_SETTING.SHOW,
        Access.WITHDRAWAL_METHOD.FETCH,
        Access.WITHDRAWAL_METHOD.SHOW,
        Access.WITHDRAWAL_METHOD.UPDATE,
        Access.WITHDRAWAL_FEE.FETCH,
        Access.WITHDRAWAL_FEE.STORE,
        Access.PPOOB_PRODUCT.SHOW,
        Access.PPOOB_PRODUCT.STORE,
        Access.PPOOB_PRODUCT.UPDATE,
        Access.PPOOB_PRODUCT.DELETE,
        Access.PPOOB_PRODUCT.STORE_BULK,
        Access.PPOOB_PRODUCT.FETCH
      ],
      submenu: [
        // {
        //   label: "Format Email",
        //   href: Routing.SETTINGS.FORMAT_EMAIL,
        // },

        {
          label: 'Admin Access',
          href: Routing.SETTINGS.ADMIN_ACCESS,
          access: [
            Access.ROLE_ACCESS.FETCH,
            Access.ROLE_ACCESS.UPDATE,
            Access.ROLE_ACCESS.DELETE,
            Access.ROLE_ACCESS.STORE,
            Access.USER.FETCH,
            Access.ROLE.FETCH,
            Access.ROLE.UPDATE,
            Access.ROLE.SHOW,
            Access.ROLE.STORE,
            Access.ROLE.DELETE,
            Access.ROLE.STORE_BULK
          ]
        },
        // {
        //   label: "Transaction Log",
        //   href: Routing.SETTINGS.TRANSACTION_LOG,
        // },
        // {
        //   label: "Check Data",
        //   href: Routing.SETTINGS.CHECK_DATA,
        // },
        // {
        //   label: "Activity Log",
        //   href: Routing.SETTINGS.ACTIVITY_LOG,
        // },
        // {
        //   label: "Custom Variable",
        //   href: Routing.SETTINGS.CUSTOM_VARIABLE,
        // },
        {
          label: 'Open Channel',
          href: Routing.SETTINGS.OPEN_CHANNEL,
          access: [
            Access.PAYMENT_CHANNEL.FETCH,
            Access.PAYMENT_CHANNEL.SHOW,
            Access.PAYMENT_CHANNEL.UPDATE,
            Access.PAYMENT_CHANNEL_FEE.FETCH
          ]
        },
        {
          label: 'PPOB',
          href: Routing.SETTINGS.PPOB,
          access: [
            Access.PPOOB_PRODUCT.SHOW,
            Access.PPOOB_PRODUCT.STORE,
            Access.PPOOB_PRODUCT.UPDATE,
            Access.PPOOB_PRODUCT.DELETE,
            Access.PPOOB_PRODUCT.STORE_BULK,
            Access.PPOOB_PRODUCT.FETCH
          ]
        },
        {
          label: 'Company',
          href: Routing.SETTINGS.COMPANY_SETTING,
          access: [Access.COMPANY_SETTING.FETCH, Access.COMPANY_SETTING.SHOW, Access.COMPANY_SETTING.UPDATE]
        },
        {
          label: 'Dinaran Setting',
          href: Routing.SETTINGS.DINARAN_SETTING,
          access: [Access.DINARAN_SETTING.SHOW, Access.DINARAN_SETTING.UPDATE, Access.DINARAN_SETTING.SHOW]
        },
        {
          label: 'Withdrawal',
          href: Routing.SETTINGS.WITHDRAWAL,
          access: [
            Access.WITHDRAWAL_METHOD.FETCH,
            Access.WITHDRAWAL_METHOD.SHOW,
            Access.WITHDRAWAL_METHOD.UPDATE,
            Access.WITHDRAWAL_FEE.FETCH,
            Access.WITHDRAWAL_FEE.STORE
          ]
        }
      ]
    }
  ];
} else {
  bottomMenu = [];
}

export { mainMenu, bottomMenu };
