import { ModalDialog, ModalDialogProps } from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import ButtonCustom from '@/delivery/components/atoms/button/button_custom.tsx';

import beliEmas from '@/delivery/assets/icons/beli_emas_icon.svg';
import belanjakanEmas from '@/delivery/assets/icons/belanjakan_emas_icon.svg';
import beriEmas from '@/delivery/assets/icons/beri_emas_icon.svg';
import ambilEmas from '@/delivery/assets/icons/ambil_emas_icon.svg';
import { useCorporateTransactionListContext } from '@/delivery/ui/corporate/transactions/transaction_list/context/corporate_transaction_list_context.tsx';

const DialogAddTransaction = (props: ModalDialogProps) => {
  const { handleAddBeriEmasModal, handleAddTransactionModal } = useCorporateTransactionListContext();

  const handleSetBeriEmasModal = () => {
    handleAddBeriEmasModal.setState(true);
    handleAddTransactionModal.setState(false);
  };

  return (
    <ModalDialog overflow={false} size="medium" title="Tambah Transaksi" modal={props.modal}>
      <div className="grid grid-cols-2 gap-[20px]">
        <ButtonCustom disabled>
          <div className={'items-center flex flex-col gap-[20px]'}>
            <Image url={beliEmas} width={70} />
            <Typography size={14} fontWeight={600}>
              Beli Emas
            </Typography>
          </div>
        </ButtonCustom>
        <ButtonCustom disabled>
          <div className={'items-center flex flex-col gap-[20px]'}>
            <Image url={belanjakanEmas} width={70} />
            <Typography size={14} fontWeight={600}>
              Belanjakan Emas
            </Typography>
          </div>
        </ButtonCustom>
        <ButtonCustom onClick={() => handleSetBeriEmasModal()}>
          <div className={'items-center flex flex-col gap-[20px]'}>
            <Image url={beriEmas} width={70} />
            <Typography size={14} fontWeight={600}>
              Beri Emas
            </Typography>
          </div>
        </ButtonCustom>
        <ButtonCustom disabled>
          <div className={'items-center flex flex-col gap-[20px]'}>
            <Image url={ambilEmas} width={70} />
            <Typography size={14} fontWeight={600}>
              Ambil Emas
            </Typography>
          </div>
        </ButtonCustom>
      </div>
    </ModalDialog>
  );
};

export default DialogAddTransaction;
