import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useReferralContext } from '@/delivery/ui/admin/data_member/member_list/context/referral_context.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogListUserReferral from '@/delivery/ui/admin/data_member/member_list/detail/tabs/referral/dialog_user.tsx';

const TableParentReferral = () => {
  const { handleAddParentReferralModal, userDashboard } = useReferralContext();
  return (
    <div>
      <Theader>
        <div>
          <Typography size={14} fontWeight={600}>
            Parent Referral
          </Typography>
        </div>
        <div>
          <Button
            prefixIcon={Icon.plus}
            label={'Add Parent Referral'}
            onClick={() => handleAddParentReferralModal.setState(true)}
          />
        </div>
      </Theader>
      <Table noBorder className="rounded-b-[8px]">
        <Thead>
          <Tr>
            <Th>Serial ID</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userDashboard?.parentAffiliate !== null ? (
            <Tr>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {userDashboard?.parentAffiliate?.serialID}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {userDashboard?.parentAffiliate?.firstName}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {userDashboard?.parentAffiliate?.email}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={400} size={12} type="primary-invert">
                  {userDashboard?.parentAffiliate?.phone}
                </Typography>
              </Td>
            </Tr>
          ) : (
            <Tr>
              <Td colSpan={4}>
                <Typography size={12} style={'italic'}>
                  belum memiliki parent referral
                </Typography>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {handleAddParentReferralModal.state && <DialogListUserReferral modal={handleAddParentReferralModal} />}
    </div>
  );
};

export default TableParentReferral;
