import Dexie, { Table } from 'dexie';
import { DATABASE_NAME } from '@/domain/constant/indexed_db/indexed_db.ts';

export interface TransactionExportInt {
  id?: number;
  uid?: string;
  trx_id?: string;
  name?: string;
  email?: string;
  type?: string;
  reversal?: string;
  sender?: string | null;
  sender_email?: string | null;
  receiver?: string | null;
  receiver_email?: string | null;
  status?: string;
  trx_date?: string;
  paid_date?: string;
  paid_amount?: number | undefined;
  fee?: number | null;
  coin?: number | null;
  vendor?: string;
  vendor_ref_id?: string;
  amount_gram?: number;
  rate_buy?: number;
  rate_sell?: number;
}

export class DBTransaction extends Dexie {
  transactionExport!: Table<TransactionExportInt>;

  constructor() {
    super(DATABASE_NAME);
    this.version(1).stores({
      transactionExport:
        'uid, trx_id, name, email, type, reversal, sender, sender_email, receiver, receiver_email, status, trx_date, paid_date, paid_amount, fee, coin, vendor, vendor_ref_id, amount_gram, rate_buy, rate_sell'
    });
  }
}
