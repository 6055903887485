import clsx from 'clsx';
// import styles from '@/delivery/components/atoms/tab_pane/tab_pane.module.css';
import { ReactNode } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface StepperListValue {
  label?: string;
  index?: number;
  handleClick?: any;
  tab?: number;
  prefixIcon?: any;
  complete?: boolean;
}

interface StepperPaneProps {
  children?: ReactNode;
}

const Stepper = (props: StepperPaneProps) => {
  const { children } = props;
  return (
    <div className="relative overflow-x-auto w-full flex justify-center bg-content-bg-white rounded-full">
      <ul className="relative flex items-center list-none">{children}</ul>
      <StepperContent />
    </div>
  );
};

const StepperList = (props: StepperListValue) => {
  const { complete, handleClick, index = 0, label, prefixIcon } = props;
  return (
    <div className={'p-[20px] flex items-center list-none'}>
      <div>
        {complete ? (
          <div className={'text-bg-primary'}>
            <FaCheckCircle />
          </div>
        ) : (
          <div className={'text-[14px] text-text-tertiary-invert'}>{index + 1}</div>
        )}
      </div>
      <li>
        <a
          onClick={handleClick}
          tabIndex={index}
          className={clsx(
            // styles['tab-pane-2'],
            `cursor-pointer ${
              complete ? 'text-text-primary font-[500] text-[14px]' : 'text-text-tertiary-invert font-[500] text-[14px]'
            }`
          )}
        >
          <span className="text-[20px] mr-[8px]">
            {prefixIcon}
            {/* <AiOutlineEye /> */}
          </span>
          <span className="text-[14px]">{label}</span>
        </a>
      </li>
    </div>
  );
};

interface StepperContentProps {
  children?: ReactNode;
  index?: number;
  tab?: number;
}

const StepperContent = (props: StepperContentProps) => {
  const { children, index, tab } = props;
  return (
    <div tabIndex={index} className={`${tab === index ? `block` : `hidden`}`}>
      {children}
    </div>
  );
};

export { StepperContent, StepperList, Stepper };
