import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import { ICDXRepository } from '@/domain/repository/icdx_repository.ts';
import { DBTransaction, TransactionExportInt } from '@/infrastructure/db/transaction_db.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface TransactionListParamsInterface {
  page?: number;
  limit?: number;
}

export interface FormFilterTransaction {
  search?: string;
  type?: string | null;
  status?: string | null;
  start_date?: string;
  end_date?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
}

interface FormTransactionListInterface {
  userUID?: string;
  rateUID?: string;
  referenceID?: string;
  type?: string;
  status?: string;
  amount?: number;
  gram?: string;
  notes?: string;
  flow?: string;
  relatedUID?: string;
  transactionAt?: string;
  settledAt?: string;
  succeedAt?: string;
  reconAt?: string;
}

interface TransactionListValue {
  formFilterTransaction: FormFilterTransaction;
  setFormFilterTransaction: Dispatch<SetStateAction<FormFilterTransaction>>;
  formTransactionList: FormTransactionListInterface;
  setFormTransactionList: Dispatch<SetStateAction<FormTransactionListInterface>>;
  transactionList: TransactionModel | undefined;
  transactionLists: TransactionModel[];
  transactionListExport: TransactionModel[];
  transactionListPagination?: PaginationModel;
  handleDetailTransactionListModal: ModalObject;
  handleExportTransactionModal: ModalObject;
  handleFetchTransactionList: (_data: TransactionListParamsInterface) => void;
  handleShowTransaction: (_data: TransactionModel) => void;
  handleApprovePayment: (_data: TransactionModel | undefined) => void;
  handleCancelTransaction: (_data: TransactionModel | undefined) => void;
  handleSendICDXTransaction: (_data: TransactionModel) => void;
  handleExportTransactionList: (_data: TransactionListParamsInterface) => Promise<any>;
}

const TransactionListContext = createContext<TransactionListValue | null>(null);

const transactionListRepository = new TransactionRepository();
const icdxRepository = new ICDXRepository();

const TransactionListProvider: React.FC<DialogContextProps> = ({ children }) => {
  const db = new DBTransaction();
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  const handleDetailTransactionListModal = useVisibleComponent(false);
  const handleExportTransactionModal = useVisibleComponent(false);

  const [transactionListExport, setTransactionListExport] = useState<TransactionModel[]>([]);
  const [transactionList, setTransactionList] = useState<TransactionModel>();
  const [transactionLists, setTransactionLists] = useState<TransactionModel[]>([]);
  const [transactionListPagination, setTransactionListPagination] = useState<PaginationModel>();

  const [formFilterTransaction, setFormFilterTransaction] = useState<FormFilterTransaction>({});

  const [formTransactionList, setFormTransactionList] = useState<FormTransactionListInterface>({});

  //FETCH TRANSACTION
  const handleFetchTransactionList = (_data: TransactionListParamsInterface) => {
    const x = alertToast.loadingAlert('Fetching transaction');
    const params = {
      sort: 'created_at desc',
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterTransaction.search ?? null,
      type: formFilterTransaction.type ?? null,
      status: formFilterTransaction.status ?? null,
      // transaction_at: formFilterTransaction.transaction_at_start
      //   ? [
      //       `${formatDateParam(formFilterTransaction.transaction_at_start)}`,
      //       `${formatDateParam(formFilterTransaction.transaction_at_end)}`
      //     ]
      //   : null,
      transaction_date_start: formatDateParam(formFilterTransaction.transaction_at_start),
      transaction_date_end: formatDateParam(formFilterTransaction.transaction_at_end)
    };

    transactionListRepository
      .fetchTransactionDashboard(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setTransactionLists(result.data);
        setTransactionListPagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const handleExportTransactionList = async (_data: TransactionListParamsInterface) => {
    const params = {
      sort: 'created_at desc',
      page: _data.page ?? 1,
      limit: _data.limit ?? 10,
      search: formFilterTransaction.search ?? null,
      type: formFilterTransaction.type ?? null,
      status: formFilterTransaction.status ?? null,
      // transaction_at: formFilterTransaction.transaction_at_start
      //   ? [
      //       `${formatDateParam(formFilterTransaction.transaction_at_start)}`,
      //       `${formatDateParam(formFilterTransaction.transaction_at_end)}`
      //     ]
      //   : null,
      transaction_date_start: formatDateParam(formFilterTransaction.transaction_at_start),
      transaction_date_end: formatDateParam(formFilterTransaction.transaction_at_end)
    };

    await transactionListRepository
      .fetchTransactionDashboard(params)
      .then((result: any) => {
        const res: TransactionModel[] = result.data;

        //store data to variable
        if (res.length > 0) {
          const dataPerPage: TransactionExportInt[] = [];
          for (let x = 0; x < res.length; x++) {
            dataPerPage.push({
              id: x + 1,
              uid: res[x].UID,
              trx_id: res[x].code,
              name: res[x].userEmail?.name,
              email: res[x].userEmail?.email,
              type: res[x].type,
              reversal: res[x]?.flags?.join(''),
              sender: res[x]?.senderName,
              sender_email: res[x]?.senderEmail,
              receiver: res[x].receiverName,
              receiver_email: res[x].receiverEmail,
              status: res[x].status,
              trx_date: res[x].transactionDate?.transactionAt,
              paid_date: res[x].transactionDate?.succeedAt,
              paid_amount: res[x].amount?.total,
              fee: res[x].amount?.fee,
              coin: res[x].amount?.coin,
              vendor: res[x].payment?.vendor,
              vendor_ref_id: res[x].payment?.vendorRefID,
              amount_gram: res[x].amount?.gram,
              rate_buy: res[x].rate.buy,
              rate_sell: res[x].rate.sell
            });
          }

          db.transactionExport.bulkAdd(dataPerPage);
        }

        setTransactionListExport(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const handleShowTransaction = (_data: TransactionModel) => {
    const x = alertToast.loadingAlert('');
    const params = {
      uid: _data.UID
    };

    transactionListRepository
      .showTransaction(params)
      .then((result: any) => {
        setTransactionList(result);
        alertToast.updateLoading(x);
        handleDetailTransactionListModal.setState(true);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const handleApprovePayment = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID,
          status: STATUS_TRANSACTION.SUCCESS
        };

        transactionListRepository
          .updateTransaction(params)
          .then((result: any) => {
            setTransactionList(result);
            alertToast.updateLoading(x);
            handleDetailTransactionListModal.setState(false);
            handleFetchTransactionList({});
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE CANCEL TRANSACTION
  const handleCancelTransaction = (_data: TransactionModel | undefined) => {
    alertSweet.questionAlert('Apakah anda yakin cancel transaksi?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          uid: _data?.UID
        };

        transactionListRepository
          .cancelTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            handleDetailTransactionListModal.setState(false);
            handleFetchTransactionList({ page: 1 });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  //HANDLE SEND ICDX TRANSACTION
  const handleSendICDXTransaction = (_data: TransactionModel) => {
    alertSweet.questionAlert('Apakah anda Send ICDX?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          transaction_uid: _data?.UID
        };
        icdxRepository
          .sendICDXTransaction(params)
          .then((result: any) => {
            alertToast.updateLoading(x);
            handleFetchTransactionList({ page: 1 });
            alertToast.successAlert();
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: TransactionListValue = {
    formFilterTransaction,
    setFormFilterTransaction,
    handleCancelTransaction,
    transactionList,
    handleApprovePayment,
    handleShowTransaction,
    formTransactionList,
    transactionListExport,
    setFormTransactionList,
    transactionLists,
    transactionListPagination,
    handleFetchTransactionList,
    handleDetailTransactionListModal,
    handleSendICDXTransaction,
    handleExportTransactionModal,
    handleExportTransactionList
  };

  return <TransactionListContext.Provider value={contextValue}>{children}</TransactionListContext.Provider>;
};

const useTransactionListContext = (): TransactionListValue => {
  const context = useContext(TransactionListContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a TransactionListProvider');
  }
  return context;
};

export { TransactionListProvider, useTransactionListContext };
