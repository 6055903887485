import { useEffect } from 'react';
import CorporateDashboardPage from '@/delivery/ui/corporate/dashboard';
import { CorporateDashboardProvider } from '@/delivery/ui/corporate/dashboard/context/corporate_dashboard_context.tsx';

const ViewCorporateDashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  return (
    <div>
      <CorporateDashboardProvider>
        <CorporateDashboardPage />
      </CorporateDashboardProvider>
    </div>
  );
};

export default ViewCorporateDashboard;
