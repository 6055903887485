import React, { createContext, useContext, ReactNode, useState } from 'react';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { AffiliateUserModel } from '@/domain/model/affiliate_user_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateReferralDataValueParams {
  page?: number;
  limit?: number;
  email?: string;
}

interface CorporateReferralDataValue {
  //INTERFACE
  profileAffiliate?: AffiliateModel;
  myAffiliate: AffiliateUserModel[];
  myAffiliatePagination?: PaginationModel;
  handleFetchMyAffiliate: (_data: CorporateReferralDataValueParams) => void;
  handleShowProfileAffiliate: (_data: CorporateReferralDataValueParams) => void;
}

const CorporateReferralDataContext = createContext<CorporateReferralDataValue | null>(null);

//REPOSITORY HERE
const corporateRep = new CorporateRepository();
const affiliateProfileRep = new AffiliateRepository();

const CorporateReferralDataProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  //const alertSweet = useAlertSweetComponent();

  //const handleModal = useVisibleComponent(false);

  const [profileAffiliate, setProfileAffiliate] = useState<AffiliateModel>();

  const [myAffiliate, setMyAffiliate] = useState<AffiliateUserModel[]>([]);
  const [myAffiliatePagination, setMyAffiliatePagination] = useState<PaginationModel>();

  //FUNCTION HERE BELOW
  const handleFetchMyAffiliate = (_data: CorporateReferralDataValueParams) => {
    const x = alertToast.loadingAlert('Fetching Data Affiliate');

    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10
    };

    corporateRep
      .fetchCorporateMyAffiliates(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyAffiliate(result.data);
        setMyAffiliatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW PROFILE AFFILIATE
  const handleShowProfileAffiliate = (_data: CorporateReferralDataValueParams) => {
    const x = alertToast.loadingAlert('Showing Token');

    const params = {
      email: _data.email
    };

    affiliateProfileRep
      .showProfileAffiliate(params)
      .then((result: any) => {
        setProfileAffiliate(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateReferralDataValue = {
    //INTERFACE
    profileAffiliate,
    myAffiliate,
    myAffiliatePagination,
    handleFetchMyAffiliate,
    handleShowProfileAffiliate
  };

  return <CorporateReferralDataContext.Provider value={contextValue}>{children}</CorporateReferralDataContext.Provider>;
};

const useCorporateReferralDataContext = (): CorporateReferralDataValue => {
  const context = useContext(CorporateReferralDataContext);
  if (!context) {
    throw new Error('useCorporateReferralDataContext must be used within a CorporateReferralDataProvider');
  }
  return context;
};

export { CorporateReferralDataProvider, useCorporateReferralDataContext };
