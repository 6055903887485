import { ReactNode } from 'react';

interface ButtonCustomProps {
  isActive?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  block?: boolean;
  children?: ReactNode;
}

const ButtonCustom = (props: ButtonCustomProps) => {
  const { isActive = false, onClick, disabled = false, children } = props;

  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={`${isActive ? `border-bg-primary ` : ' border-border-secondary'} p-[30px] border rounded-[8px] ${
        disabled ? 'bg-gray-300 cursor-not-allowed' : 'hover:border-bg-primary hover:cursor-pointer'
      }`}
      style={{
        opacity: disabled ? 0.5 : 1
      }}
    >
      {children}
    </div>
  );
};

export default ButtonCustom;
