import CorporateProfile from '@/delivery/ui/corporate/profile/corporate_profile.tsx';
import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';

const CorporateProfilePage = () => {
  return (
    <div>
      <Navbar label={'Profile'}></Navbar>
      <CorporateProfile />
    </div>
  );
};

export default CorporateProfilePage;
