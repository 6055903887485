import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useReferralContext } from '@/delivery/ui/admin/data_member/member_list/context/referral_context.tsx';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { COMMISSION_TYPE } from '@/domain/constant/affiliate/type_affiliate.ts';
import { REFERRAL_LINK } from '@/domain/constant/referral/referral_link.ts';
import TableParentReferral from '@/delivery/ui/admin/data_member/member_list/detail/tabs/referral/table_parent_referral.tsx';
import TableChildReferral from '@/delivery/ui/admin/data_member/member_list/detail/tabs/referral/table_child_refferal.tsx';

const Referral = () => {
  const { profile } = useReferralContext();

  return (
    <div className={'flex flex-col gap-[20px]'}>
      <TableParentReferral />
      <TableChildReferral />
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Referral
            </Typography>
          </div>

          {/*<div>*/}
          {/*  <span className={'text-text-primary font-[600] text-[14px] cursor-pointer'}>Edit</span>*/}
          {/*</div>*/}
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Token Referral</Th>
              <Th>Link Referral</Th>
              <Th>Type</Th>
              <Th>Komisi (%)</Th>
              <Th>Komisi (Rp)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {profile?.userTokenAffiliate?.length !== 0 ? (
              <>
                {profile?.userTokenAffiliate?.map((item: AffiliateModel) => (
                  <Tr>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {item.token}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {`${REFERRAL_LINK}/${item.token}`}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {item.type}
                      </Typography>
                    </Td>
                    <Td>
                      {item.commission_type === COMMISSION_TYPE.PERCENT ? (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          {item.commission}
                        </Typography>
                      ) : (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          -
                        </Typography>
                      )}
                    </Td>
                    <Td>
                      {item.commission_type === COMMISSION_TYPE.FLAT ? (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          {item.commission}
                        </Typography>
                      ) : (
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          -
                        </Typography>
                      )}
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Typography size={12} fontWeight={500} style={'italic'}>
                    belum memiliki referral
                  </Typography>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Referral;
