import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBusinessVerifyContext } from '@/delivery/ui/admin/data_corporate/business_verify/context/business_verify_context.tsx';

const TableBusinessInfo = () => {
  const { businessVerify } = useBusinessVerifyContext();
  return (
    <Table noBorder className="rounded-b-[8px]">
      <Thead>
        <Tr noBorder>
          <Th noBorder>
            <Typography fontWeight={600} size={14} type="primary-invert">
              Informasi Bisnis
            </Typography>
          </Th>
          <Th noBorder></Th>
        </Tr>
      </Thead>
      <Tbody>
        {/*nama usaha*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Nama Usaha
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.name}
            </Typography>
          </Td>
        </Tr>

        {/*email*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Email
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.emailCorporate}
            </Typography>
          </Td>
        </Tr>

        {/*website*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Website
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.website}
            </Typography>
          </Td>
        </Tr>

        {/*phone*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Phone
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.phoneCorporate}
            </Typography>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default TableBusinessInfo;
