import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';

import { useDownloadExcel } from 'react-export-table-to-excel';
import { useRef } from 'react';
import { useAffiliateListContext } from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/context/affiliate_list_context.tsx';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';

const DialogExportAffiliate = (props: ModalDialogProps) => {
  const tableRef = useRef(null);
  const { handleExportDataAffiliateListModal, affiliateUsersExport } = useAffiliateListContext();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `affiliate-list-${formatDateTimeParam(new Date())}`,
    sheet: 'affiliate'
  });

  return (
    <ModalDialog size="xlarge" title="Preview Data Affiliate" modal={props.modal}>
      <div>
        <table ref={tableRef} className={'border border-solid'}>
          <tbody>
            <tr>
              <th className={'border border-solid'}>Affiliate ID</th>
              <th className={'border border-solid'}>Member Affiliate</th>
              <th className={'border border-solid'}>Type Affiliate</th>
              <th className={'border border-solid'}>Token</th>
              <th className={'border border-solid'}>Komisi %</th>
              <th className={'border border-solid'}>Total Affiliate</th>
              <th className={'border border-solid'}>Earnings (Rp)</th>
            </tr>

            {affiliateUsersExport.map((item: AffiliateModel, index) => (
              <tr>
                <td className={'border border-solid'}>{item?.serialID}</td>
                <td className={'border border-solid'}>
                  <span>{item.getFullName}</span>
                  <br />
                  <span>{item.email}</span>
                  <br />
                  <span>{item.phone}</span>
                  <br />
                </td>
                <td className={'border border-solid'}>{item.type}</td>
                <td className={'border border-solid'}>{item.token}</td>
                <td className={'border border-solid'}>{item.commission}</td>
                <td className={'border border-solid'}>{item.affiliates}</td>
                <td className={'border border-solid'}>{item.getEarningRupiah}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/*<Table noBorder className="rounded-b-[8px]" ref={tableRef}>*/}
        {/*  <Thead>*/}
        {/*    <Tr>*/}
        {/*      <Th>Affiliate ID</Th>*/}
        {/*      <Th>Member Affiliate</Th>*/}
        {/*      <Th>Type Affiliate</Th>*/}
        {/*      <Th>Token</Th>*/}
        {/*      <Th>Komisi %</Th>*/}
        {/*      <Th>Total Affiliate</Th>*/}
        {/*      <Th>Earnings (Rp)</Th>*/}
        {/*    </Tr>*/}
        {/*  </Thead>*/}
        {/*  <Tbody>*/}
        {/*    {affiliateUsersExport?.map((item: AffiliateModel, index) => (*/}
        {/*      <Tr>*/}
        {/*        <Td>*/}
        {/*          <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.serialID}*/}
        {/*          </Typography>*/}
        {/*        </Td>*/}
        {/*        <Td>*/}
        {/*          <Typography fontWeight={600} size={12} type="primary-invert">*/}
        {/*            {item.getFullName}*/}
        {/*          </Typography>*/}
        {/*          <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.email}*/}
        {/*          </Typography>*/}
        {/*          <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.phone}*/}
        {/*          </Typography>*/}
        {/*        </Td>*/}
        {/*        <Td>*/}
        {/*          <Chips label={item.type} type="primary" removeDot />*/}
        {/*        </Td>*/}
        {/*        <Td>*/}
        {/*          <Typography fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.token}*/}
        {/*          </Typography>*/}
        {/*        </Td>*/}
        {/*        <Td>*/}
        {/*          <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.commission}*/}
        {/*          </Typography>*/}
        {/*        </Td>*/}
        {/*        <Td>*/}
        {/*          <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.affiliates}*/}
        {/*          </Typography>*/}
        {/*        </Td>*/}
        {/*        <Td>*/}
        {/*          <Typography className="leading-4" tag="span" fontWeight={400} size={12} type="tertiary-invert">*/}
        {/*            {item.getEarningRupiah}*/}
        {/*          </Typography>*/}
        {/*        </Td>*/}
        {/*      </Tr>*/}
        {/*    ))}*/}
        {/*  </Tbody>*/}
        {/*</Table>*/}
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button type="secondary" label="Cancel" onClick={() => handleExportDataAffiliateListModal.setState(false)} />
        {/*<DownloadTableExcel filename="affiliate-list" sheet="affiliate" currentTableRef={tableRef.current}>*/}
        {/*  <button>export</button>*/}
        <Button onClick={onDownload} label="Export" />
        {/*</DownloadTableExcel>*/}
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogExportAffiliate;
