import Corporate from '@/delivery/ui/admin/corporate/corporate.tsx';
import Navbar from '@/delivery/components/organisms/navbar/navbar.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/corporate/context/corporate_context.tsx';

const CorporatePage = () => {
  const { corporate } = useCorporateContext();
  return (
    <div>
      <Navbar label={corporate?.name ?? 'Corporate'}></Navbar>
      <Corporate />
    </div>
  );
};

export default CorporatePage;
