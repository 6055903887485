import { useEffect } from 'react';
import { RegistrationCorporateUserProvider } from '@/delivery/ui/registration_corporate_user/context/registration_corporate_user_context.tsx';
import RegistrationCorporateUser from '@/delivery/ui/registration_corporate_user/registration_corporate_user.tsx';

const RegistrationAccountCorporateUserPage = () => {
  useEffect(() => {
    document.title = 'Registration - Dinaran';
  }, []);

  return (
    <RegistrationCorporateUserProvider>
      <RegistrationCorporateUser />
    </RegistrationCorporateUserProvider>
  );
};

export default RegistrationAccountCorporateUserPage;
