import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { Stepper, StepperContent, StepperList } from '@/delivery/components/atoms/stepper/stepper.tsx';
import { useState } from 'react';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import {
  CorporateTransactionListProvider,
  useCorporateTransactionListContext
} from '@/delivery/ui/corporate/transactions/transaction_list/context/corporate_transaction_list_context.tsx';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';

interface DataInt {
  label: string;
  index: number;
  complete: boolean;
  onClick?: any;
}

const BeriEmasMultipleUsers = () => {
  const navigation = useNavigate();
  const profile = getProfile();
  const { handleStoreTransactionPayrollMultipleConfirm, handleStoreTransactionPayrollMultiple, transactionUpload } =
    useCorporateTransactionListContext();

  const [fileBeriEmas, setFileBeriEmas] = useState<any>();

  const [tabPage, setTabPage] = useState(0);

  //HANDLE STEP 1
  const handleStep1 = () => {
    const index = 0;
    setData(prevData => prevData.map(item => ([0, 1, 2].includes(item.index) ? { ...item, complete: false } : item)));
    setTabPage(index);
  };

  //HANDLE STEP 2
  const handleStep2 = () => {
    const index = 1;
    setData(prevData => prevData.map(item => (item.index === 0 ? { ...item, complete: true } : item)));
    setData(prevData => prevData.map(item => (item.index === 1 ? { ...item, complete: false } : item)));
    setTabPage(index);
  };

  //HANDLE STEP 2
  const handleStep3 = () => {
    const index = 2;
    setData(prevData => prevData.map(item => (item.index === 1 ? { ...item, complete: true } : item)));
    setTabPage(index);
  };

  const [data, setData] = useState<DataInt[]>([
    {
      label: 'Upload File',
      index: 0,
      complete: false,
      onClick: handleStep1
    },
    {
      label: 'Validasi Data',
      index: 1,
      complete: false,
      onClick: handleStep2
    },
    {
      label: 'Konfirmasi dan Beli Emas',
      index: 2,
      complete: false,
      onClick: handleStep3
    }
  ]);

  return (
    <div className={'flex flex-col items-center justify-center'}>
      <div className={'w-3/4 p-[35px] border-b-[1px] border-[200] border-border-secondary'}>
        <div className={'flex justify-between items-start'}>
          <div>
            <Typography fontWeight={600} size={24}>
              Beri Emas Ke Banyak User
            </Typography>
            <Typography fontWeight={400} size={14} type={'tertiary-invert'}>
              Kirim emas kepada beberapa pengguna sekaligus dengan upload file Excel.
            </Typography>
          </div>
          <div onClick={() => navigation(Routing.CORPORATE_TRANSACTION)}>{Icon.close}</div>
        </div>
      </div>
      <div className={'w-3/4 p-[35px]'}>
        <Stepper>
          {data?.map((item, index) => (
            <StepperList
              complete={item.complete}
              label={item.label}
              index={index}
              tab={tabPage}
              // handleClick={() => item.onClick(index)}
            />
          ))}
        </Stepper>

        {/*UPLOAD FILE*/}
        <StepperContent index={0} tab={tabPage}>
          <div className={'mt-[20px]'}>
            <div className={'flex justify-between items-end'}>
              <div>
                <Typography fontWeight={600} size={20}>
                  Upload File
                </Typography>
                <Typography fontWeight={400} size={14} type={'tertiary-invert'}>
                  Pilih file Excel untuk diimpor.
                </Typography>
              </div>
              <div>
                <Typography fontWeight={400} size={14} type={'tertiary-invert'} tag={'span'}>
                  Download template{' '}
                  <a
                    download="template_beri_emas.xlsx"
                    href={'/template_beri_emas.xlsx'}
                    className={'cursor-pointer underline text-text-secondary-invert'}
                  >
                    excel
                  </a>
                </Typography>
              </div>
            </div>
            <div className={'mt-[20px]'}>
              <Dropzone fileTypes={['.xls, .xlsx']} id={'notify-app'} onChange={e => setFileBeriEmas(e)} />
            </div>
            <div className={'mt-[20px] flex justify-center'}>
              <Button
                label={'Konfirmasi File'}
                onClick={() => {
                  handleStoreTransactionPayrollMultiple({
                    user_uuid: profile?.user_uuid,
                    file_beri_emas_multiple: fileBeriEmas
                  }).then((result: any) => {
                    if (result) {
                      handleStep2();
                    }
                  });
                }}
              />
            </div>
          </div>
        </StepperContent>

        {/*VALIDASI DATA*/}
        <StepperContent index={1} tab={tabPage}>
          <div className={'mt-[20px]'}>
            <div className={'flex justify-between items-end'}>
              <div>
                <Typography fontWeight={600} size={20}>
                  Validasi Data
                </Typography>
                <Typography fontWeight={400} size={14} type={'tertiary-invert'}>
                  Pastikan data sudah benar sebelum melanjutkan
                </Typography>
              </div>
            </div>
            <div className={'mt-[20px]'}>
              <Table className="rounded-b-[8px]">
                <Thead>
                  <Tr>
                    <Th>Trx Code</Th>
                    <Th>Amount</Th>
                    <Th>Gram</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactionUpload.map((item, index) => (
                    <Tr key={index}>
                      <Td>
                        <Typography size={14} fontWeight={400} type="secondary-invert">
                          {item.code}
                        </Typography>
                      </Td>
                      <Td>
                        <Typography size={14} fontWeight={400} type="secondary-invert">
                          {item.getAmountSingle}
                        </Typography>
                      </Td>
                      <Td>
                        <Typography size={14} fontWeight={400} type="secondary-invert">
                          {item.getGram}
                        </Typography>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
            <div className={'flex justify-center gap-[20px] mt-[20px]'}>
              <Button
                prefixIcon={Icon.arrowLeft}
                label={'Upload Ulang'}
                type={'secondary'}
                onClick={() => handleStep1()}
              />
              <Button prefixIcon={Icon.check} label={'Konfirmasi Data'} onClick={() => handleStep3()} />
            </div>
          </div>
        </StepperContent>

        {/*KONFIRMASI DAN BELI EMAS*/}
        <StepperContent index={2} tab={tabPage}>
          <div className={'mt-[20px]'}>
            <div className={'flex justify-between items-end'}>
              <div>
                <Typography fontWeight={600} size={20}>
                  Konfirmasi dan Beri Emas
                </Typography>
                <Typography fontWeight={400} size={14} type={'tertiary-invert'}>
                  Periksa kembali informasi yang akan di Beri Emas. Jika sudah benar, klik 'Beri Emas'.{' '}
                </Typography>
              </div>
            </div>
            <div className={'flex justify-center mt-[20px] gap-[20px]'}>
              <Button prefixIcon={Icon.arrowLeft} label={'Kembali'} type={'secondary'} onClick={() => handleStep2()} />
              <Button
                label={'Beri Emas'}
                onClick={() =>
                  handleStoreTransactionPayrollMultipleConfirm({
                    user_uuid: profile?.user_uuid,
                    file_beri_emas_multiple: fileBeriEmas
                  })
                }
              />
            </div>
          </div>
        </StepperContent>
      </div>
    </div>
  );
};

const BeriEmasMultipleUsersPage = () => {
  return (
    <CorporateTransactionListProvider>
      <BeriEmasMultipleUsers />
    </CorporateTransactionListProvider>
  );
};

export default BeriEmasMultipleUsersPage;
