import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { formatDateParam } from '@/infrastructure/helper/formatDate.ts';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface ReferralTransactionValueParams {
  page?: number;
  limit?: number;
}

export interface FormFilterTransaction {
  search?: string;
  type?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  transaction_at_start?: string;
  transaction_at_end?: string;
}

interface ReferralTransactionValue {
  //INTERFACE
  myTransactionAffiliate?: TransactionModel[];
  myTransactionAffiliatePagination?: PaginationModel;
  formFilterMyTransactionAffiliate: FormFilterTransaction;
  setFormFilterMyTransactionAffiliate: Dispatch<SetStateAction<FormFilterTransaction>>;
  handleFetchMyTransactionAffiliate: (_data: ReferralTransactionValueParams) => void;
}

const ReferralTransactionContext = createContext<ReferralTransactionValue | null>(null);

//REPOSITORY HERE
const corporateRep = new CorporateRepository();

const ReferralTransactionProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  //const alertSweet = useAlertSweetComponent();

  //const handleModal = useVisibleComponent(false);

  const [formFilterMyTransactionAffiliate, setFormFilterMyTransactionAffiliate] = useState<FormFilterTransaction>({});

  const [myTransactionAffiliate, setMyTransactionAffiliate] = useState<TransactionModel[]>([]);
  const [myTransactionAffiliatePagination, setMyTransactionAffiliatePagination] = useState<PaginationModel>();

  //FUNCTION HERE BELOW
  //SHOW MY TRANSACTION AFFILIATE
  const handleFetchMyTransactionAffiliate = (_data: ReferralTransactionValueParams) => {
    const x = alertToast.loadingAlert('');

    const params = {
      sort: 'created_at desc',
      page: _data.page,
      limit: 10,
      search: formFilterMyTransactionAffiliate.search ?? null,
      type: formFilterMyTransactionAffiliate.type ?? null,
      status: formFilterMyTransactionAffiliate.status ?? null,
      transaction_at: formFilterMyTransactionAffiliate.transaction_at_start
        ? [
            `${formatDateParam(formFilterMyTransactionAffiliate.transaction_at_start)}`,
            `${formatDateParam(formFilterMyTransactionAffiliate.transaction_at_end)}`
          ]
        : null
    };

    corporateRep
      .fetchCorporateAffiliateTransaction(params)
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyTransactionAffiliate(result.data);
        setMyTransactionAffiliatePagination(result.pagination);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: ReferralTransactionValue = {
    //INTERFACE
    myTransactionAffiliate,
    myTransactionAffiliatePagination,
    formFilterMyTransactionAffiliate,
    setFormFilterMyTransactionAffiliate,
    handleFetchMyTransactionAffiliate
  };

  return <ReferralTransactionContext.Provider value={contextValue}>{children}</ReferralTransactionContext.Provider>;
};

const useReferralTransactionContext = (): ReferralTransactionValue => {
  const context = useContext(ReferralTransactionContext);
  if (!context) {
    throw new Error('useReferralTransactionContext must be used within a ReferralTransactionProvider');
  }
  return context;
};

export { ReferralTransactionProvider, useReferralTransactionContext };
