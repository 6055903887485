import './App.css';
import { Route, Routes } from 'react-router-dom';

import Draft from './delivery/ui/draft';

import LoginPage from '@/delivery/ui/login';
import MainApp from '@/delivery/ui/app/main/admin';
import { Routing } from '@/domain/constant/router_path';

// view dashboard
import ViewDashboardOverview from '@/delivery/ui/view/dashboard/view_dashboard_overview';
import ViewDashboardRanking from '@/delivery/ui/view/dashboard/view_dashboard_ranking';
import ViewDashboardMember from '@/delivery/ui/view/dashboard/view_dashboard_member';
import ViewDashboardTransaction from '@/delivery/ui/view/dashboard/view_dashboard_transaction';
import ViewDashboardAccessStatistic from '@/delivery/ui/view/dashboard/view_dashboard_access_statistic';

// gold price
import ViewGoldPrice from '@/delivery/ui/view/gold_price/view_gold_price';

//member list
import ViewMemberList from '@/delivery/ui/view/data_member/view_member_list';
import ViewMemberVerify from '@/delivery/ui/view/data_member/view_member_verify';
import ViewMemberVerifyEMoney from '@/delivery/ui/view/data_member/view_member_verify_e_money';

//corporate list
import ViewCorporateList from '@/delivery/ui/view/data_corporate/view_corporate_list';
import ViewRequestVerify from '@/delivery/ui/view/data_corporate/view_request_verify';
import ViewDataTransaction from '@/delivery/ui/view/data_corporate/view_data_transaction';
import ViewReferralList from '@/delivery/ui/view/data_corporate/view_referral_list';
import ViewReferralTransaction from '@/delivery/ui/view/data_corporate/view_referral_transaction';

//transaction
import ViewTransactionList from '@/delivery/ui/view/transaction/view_transaction_list';
import ViewManualTransaction from '@/delivery/ui/view/transaction/view_manual_transaction';
import ViewHistoryTakeGold from '@/delivery/ui/view/transaction/take_gold/view_history_take_gold';
import ViewStockGold from '@/delivery/ui/view/transaction/take_gold/view_stock_gold';
import ViewCounterOffline from '@/delivery/ui/view/transaction/take_gold/view_counter_offline';
import ViewStockATMGold from '@/delivery/ui/view/transaction/atm_gold/view_stock_atm_gold';
import ViewATMLocation from '@/delivery/ui/view/transaction/atm_gold/view_atm_location';

//data dca
import ViewAccountList from '@/delivery/ui/view/data_dca/view_account_list';
import ViewAccountRequest from '@/delivery/ui/view/data_dca/view_account_request';
import ViewAccountTransaction from '@/delivery/ui/view/data_dca/view_account_transaction';

//reseller affiliate
import ViewAffiliateList from '@/delivery/ui/view/reseller_affiliate/view_affiliate_list';
import ViewAffiliateTransaction from '@/delivery/ui/view/reseller_affiliate/view_affiliate_transaction';

//web admin
import ViewMedia from '@/delivery/ui/view/web_admin/view_media';
import ViewNotifyApp from '@/delivery/ui/view/web_admin/view_notify_app';
import ViewWebPopUp from '@/delivery/ui/view/web_admin/view_web_pop_up';
import ViewWebPages from '@/delivery/ui/view/web_admin/view_pages';

//settings
import ViewCheckData from '@/delivery/ui/view/settings/view_check_data';
import ViewTransactionLog from '@/delivery/ui/view/settings/view_transaction_log';
import ViewAdminAccess from '@/delivery/ui/view/settings/view_admin_access';
import ViewFormatEmail from '@/delivery/ui/view/settings/view_format_email';
import ViewActivityLog from '@/delivery/ui/view/settings/view_activity_log';
import ViewCustomVariable from '@/delivery/ui/view/settings/view_custom_variable';
import ViewCompanySetting from '@/delivery/ui/view/settings/view_company_setting';

//depost gold
import ViewDepositByAdmin from '@/delivery/ui/view/deposit_gold/view_depost_by_admin';
import ViewMicrogoldByATM from '@/delivery/ui/view/deposit_gold/view_microgold_by_atm';

//master data
import ViewMasterMemberLoyalty from '@/delivery/ui/view/master/view_master_member_loyalty';
import ViewMasterMemberAffiliate from '@/delivery/ui/view/master/view_master_member_affiliate';
import ViewOpenChannel from '@/delivery/ui/view/settings/view_open_channel.tsx';

//page not found
import PageNotFoundPage from '@/delivery/ui/page_not_found/PageNotFound.tsx';

//infrastructure
import AuthMiddleware from '@/infrastructure/middleware/authentication.tsx';
import CreateWebPagePage from '@/delivery/ui/admin/web_admin/web_pages/create';
import UpdateWebPagePage from '@/delivery/ui/admin/web_admin/web_pages/update';
import DetailOpenChannelPage from '@/delivery/ui/admin/settings/open_channel/detail/detail_open_channel';
import ViewBusinessVerify from '@/delivery/ui/view/data_member/view_business_verify.tsx';
import DetailBusinessVerifyPage from '@/delivery/ui/admin/data_corporate/business_verify/detail';
import DetailMemberPage from '@/delivery/ui/admin/data_member/member_list/detail';
import DetailMemberVerifyPage from '@/delivery/ui/admin/data_member/member_verify/detail';
import ViewDinaranSetting from '@/delivery/ui/view/settings/view_dinaran_setting.tsx';
import ViewWithdrawal from '@/delivery/ui/view/settings/view_withdrawal.tsx';
import DetailWithdrawalPage from '@/delivery/ui/admin/settings/withdrawal/detail/detail_withdrawal.tsx';
import ViewEventReferral from '@/delivery/ui/view/reseller_affiliate/view_event_referral.tsx';
import DetailHistoryPage from '@/delivery/ui/admin/transaction/take_gold/history/detail';
import ViewDCAActivation from '@/delivery/ui/view/data_dca/view_dca_activation.tsx';
import DetailUserPage from '@/delivery/ui/admin/settings/admin_access/detail/user';
import ViewGoldPiece from '@/delivery/ui/view/master/view_gold_piece.tsx';
import DetailGoldPiecePage from '@/delivery/ui/admin/master/gold_piece/detail/detail_gold_piece.tsx';
import ViewRiceRate from '@/delivery/ui/view/master/view_rice_price.tsx';
import DetailAffiliatePage from '@/delivery/ui/admin/reseller_affiliate/affiliate_list/detail';
import ViewVendingMachine from '@/delivery/ui/view/transaction/take_gold/view_vending_machine.tsx';
import VendingMachineDetail from '@/delivery/ui/admin/transaction/take_gold/vending_machine/detail';
import ViewSettlement from '@/delivery/ui/view/transaction/view_settlement.tsx';
import DetailCorporatePage from '@/delivery/ui/admin/data_corporate/corporate_list/detail';
import ViewCorporate from '@/delivery/ui/view/corporate/view_corporate.tsx';
import ViewPPOB from '@/delivery/ui/view/settings/view_ppob.tsx';
import DetailPPOBPage from '@/delivery/ui/admin/settings/ppob/detail';
import ViewCorporateDashboard from '@/delivery/ui/view/corporate/view_corporate_dashboard.tsx';
import ViewCorporateTransactionList from '@/delivery/ui/view/corporate/transaction/view_corporate_transaction_list.tsx';
import ViewCorporateGoldConversion from '@/delivery/ui/view/corporate/transaction/view_corporate_gold_conversion.tsx';
import ViewCorporateReferralData from '@/delivery/ui/view/corporate/referral/view_corporate_referral_data.tsx';
import ViewCorporateReferralTransaction from '@/delivery/ui/view/corporate/referral/view_corporate_referral_transaction.tsx';
import ViewCorporateProfile from '@/delivery/ui/view/corporate/view_corporate_profile.tsx';
import BeriEmasMultipleUsersPage from '@/delivery/ui/corporate/transactions/transaction_list/dialog/beri_emas/beri_emas_multiple_user.tsx';
import RegistrationAccountCorporateUserPage from '@/delivery/ui/registration_corporate_user';

function App() {
  return (
    <>
      <Routes>
        <Route path={Routing.LOGIN} element={<LoginPage />} />
        <Route path={Routing.REGISTRATION_CORPORATE_USER} element={<RegistrationAccountCorporateUserPage />} />
        <Route path={Routing.PAGE_NOT_FOUND} element={<PageNotFoundPage />} />
        <Route path={Routing.PAGE_NO_ACCESS} element={<div>page no access</div>} />
        <Route element={AuthMiddleware(MainApp)}>
          {/* DASHBOARD */}
          <Route path={Routing.DASHBOARD.OVERVIEW} element={<ViewDashboardOverview />} />
          <Route path={Routing.DASHBOARD.RANKING} element={<ViewDashboardRanking />} />
          <Route path={Routing.DASHBOARD.MEMBER} element={<ViewDashboardMember />} />
          <Route path={Routing.DASHBOARD.TRANSACTION} element={<ViewDashboardTransaction />} />
          <Route path={Routing.DASHBOARD.ACCESS_STATISTIC} element={<ViewDashboardAccessStatistic />} />

          {/* GOLD PRICE */}
          <Route path={Routing.GOLD_PRICE} element={<ViewGoldPrice />} />

          {/* DATA MEMBER */}
          <Route path={Routing.DATA_MEMBER.MEMBER_LIST} element={<ViewMemberList />} />
          <Route path={Routing.DATA_MEMBER.MEMBER_LIST_DETAIL + ':uuid'} element={<DetailMemberPage />} />
          <Route path={Routing.DATA_MEMBER.MEMBER_VERIFY} element={<ViewMemberVerify />} />
          <Route path={Routing.DATA_MEMBER.MEMBER_VERIFY_DETAIL + ':uid'} element={<DetailMemberVerifyPage />} />
          <Route path={Routing.DATA_MEMBER.VERIFY_E_MONEY} element={<ViewMemberVerifyEMoney />} />
          <Route path={Routing.DATA_MEMBER.BUSINESS_VERIFY_DETAIL + ':uid'} element={<DetailBusinessVerifyPage />} />

          {/* DATA CORPORATE */}
          <Route path={Routing.DATA_CORPORATE.CORPORATE_LIST} element={<ViewCorporateList />} />
          <Route path={Routing.DATA_CORPORATE.CORPORATE_LIST_DETAIL + ':uid'} element={<DetailCorporatePage />} />
          <Route path={Routing.DATA_CORPORATE.BUSINESS_VERIFY} element={<ViewBusinessVerify />} />
          <Route path={Routing.DATA_CORPORATE.REQUEST_VERIFY} element={<ViewRequestVerify />} />
          <Route path={Routing.DATA_CORPORATE.DATA_TRANSACTION} element={<ViewDataTransaction />} />
          <Route path={Routing.DATA_CORPORATE.REFERAL_LIST} element={<ViewReferralList />} />
          <Route path={Routing.DATA_CORPORATE.REFERAL_TRANSACTION} element={<ViewReferralTransaction />} />

          {/* TRANSACTION */}
          <Route path={Routing.TRANSACTION.TRANSACTION_LIST} element={<ViewTransactionList />} />
          <Route path={Routing.TRANSACTION.MANUAL_TRANSACTION} element={<ViewManualTransaction />} />
          <Route path={Routing.TRANSACTION.SETTLEMENT} element={<ViewSettlement />} />
          {/* TRANSACTION -> TAKE GOLD */}
          <Route path={Routing.TRANSACTION.AMBIL_EMAS.HISTORY} element={<ViewHistoryTakeGold />} />
          <Route path={Routing.TRANSACTION.AMBIL_EMAS.HISTORY_DETAIL + ':uid'} element={<DetailHistoryPage />} />
          {/* TRANSACTION -> TAKE GOLD */}
          <Route path={Routing.TRANSACTION.AMBIL_EMAS.STOCK_GOLD} element={<ViewStockGold />} />
          {/* TRANSACTION -> TAKE GOLD  */}
          <Route path={Routing.TRANSACTION.AMBIL_EMAS.COUNTER_OFFLINE} element={<ViewCounterOffline />} />
          {/* TRANSACTION -> VENDING MACHINE  */}
          <Route path={Routing.TRANSACTION.AMBIL_EMAS.VENDING_MACHINE} element={<ViewVendingMachine />} />
          {/* TRANSACTION -> DETAIL VENDING MACHINE  */}
          <Route
            path={Routing.TRANSACTION.AMBIL_EMAS.VENDING_MACHINE_DETAIL + ':uid'}
            element={<VendingMachineDetail />}
          />

          {/* TRANSACTION -> ATM GOLD  */}
          <Route path={Routing.TRANSACTION.ATM_EMAS.STOCK_ATM_GOLD} element={<ViewStockATMGold />} />
          {/* TRANSACTION -> ATM GOLD  */}
          <Route path={Routing.TRANSACTION.ATM_EMAS.ATM_LOCATION} element={<ViewATMLocation />} />

          {/* DATA DCA */}
          <Route path={Routing.DATA_DCA.DCA_ACTIVATION} element={<ViewDCAActivation />} />
          <Route path={Routing.DATA_DCA.ACCOUNT_LIST} element={<ViewAccountList />} />
          <Route path={Routing.DATA_DCA.ACCOUNT_REQUEST} element={<ViewAccountRequest />} />
          <Route path={Routing.DATA_DCA.ACCOUNT_TRANSACTIONS} element={<ViewAccountTransaction />} />

          {/* SETOR EMAS */}
          <Route path={Routing.DEPOSIT_GOLD.DEPOSIT_BY_ADMIN} element={<ViewDepositByAdmin />} />
          <Route path={Routing.DEPOSIT_GOLD.MICROGOLD_BY_ATM} element={<ViewMicrogoldByATM />} />

          {/* RESELLER / AFFILIATE */}
          <Route path={Routing.RESELLER_AFFILIATE.AFFILIATE_LIST} element={<ViewAffiliateList />} />
          <Route path={Routing.RESELLER_AFFILIATE.AFFILIATE_LIST_DETAIL + ':uuid'} element={<DetailAffiliatePage />} />
          <Route path={Routing.RESELLER_AFFILIATE.AFFILIATE_TRANSACTION} element={<ViewAffiliateTransaction />} />
          <Route path={Routing.RESELLER_AFFILIATE.EVENT_REFERRAL} element={<ViewEventReferral />} />

          {/* MASTER */}
          <Route path={Routing.MASTER.MEMBER_LOYALTY} element={<ViewMasterMemberLoyalty />} />
          <Route path={Routing.MASTER.MEMBER_AFFILIATE} element={<ViewMasterMemberAffiliate />} />
          <Route path={Routing.MASTER.GOLD_PIECE} element={<ViewGoldPiece />} />
          <Route path={Routing.MASTER.GOLD_PIECE_DETAIL + ':uid'} element={<DetailGoldPiecePage />} />
          <Route path={Routing.MASTER.RICE_RATE} element={<ViewRiceRate />} />

          {/* WEB ADMIN */}
          <Route path={Routing.WEB_ADMIN.MEDIA} element={<ViewMedia />} />
          <Route path={Routing.WEB_ADMIN.NOTIFY_APP} element={<ViewNotifyApp />} />
          <Route path={Routing.WEB_ADMIN.WEB_POP_UP} element={<ViewWebPopUp />} />
          <Route path={Routing.WEB_ADMIN.WEB_PAGES} element={<ViewWebPages />} />
          <Route path={Routing.WEB_ADMIN.WEB_PAGES_ADD} element={<CreateWebPagePage />} />
          <Route path={Routing.WEB_ADMIN.WEB_PAGES_UPDATE + ':uid'} element={<UpdateWebPagePage />} />

          {/* SETTING */}
          <Route path={Routing.SETTINGS.CHECK_DATA} element={<ViewCheckData />} />
          <Route path={Routing.SETTINGS.TRANSACTION_LOG} element={<ViewTransactionLog />} />
          <Route path={Routing.SETTINGS.ADMIN_ACCESS} element={<ViewAdminAccess />} />
          <Route path={Routing.SETTINGS.ADMIN_ACCESS_DETAIL + ':uuid'} element={<DetailUserPage />} />
          <Route path={Routing.SETTINGS.FORMAT_EMAIL} element={<ViewFormatEmail />} />
          <Route path={Routing.SETTINGS.ACTIVITY_LOG} element={<ViewActivityLog />} />
          <Route path={Routing.SETTINGS.CUSTOM_VARIABLE} element={<ViewCustomVariable />} />
          <Route path={Routing.SETTINGS.COMPANY_SETTING} element={<ViewCompanySetting />} />
          <Route path={Routing.SETTINGS.OPEN_CHANNEL} element={<ViewOpenChannel />} />
          <Route path={Routing.SETTINGS.OPEN_CHANNEL_DETAIL + ':uid'} element={<DetailOpenChannelPage />} />
          <Route path={Routing.SETTINGS.DINARAN_SETTING} element={<ViewDinaranSetting />} />
          <Route path={Routing.SETTINGS.WITHDRAWAL} element={<ViewWithdrawal />} />
          <Route path={Routing.SETTINGS.WITHDRAWAL_DETAIL + ':uid'} element={<DetailWithdrawalPage />} />
          <Route path={Routing.SETTINGS.PPOB} element={<ViewPPOB />} />
          <Route path={Routing.SETTINGS.PPOB_DETAIL + ':vendor_uid/:ppob_product_code'} element={<DetailPPOBPage />} />

          {/* --- CORPORATE PAGE BELOW --- */}
          <Route path={Routing.CORPORATE} element={<ViewCorporate />} />
          <Route path={Routing.CORPORATE_DASHBOARD} element={<ViewCorporateDashboard />} />
          <Route path={Routing.CORPORATE_PROFILE} element={<ViewCorporateProfile />} />
          <Route path={Routing.CORPORATE_TRANSACTION} element={<ViewCorporateTransactionList />} />
          <Route path={Routing.CORPORATE_TRANSACTION} element={<ViewCorporateTransactionList />} />
          <Route path={Routing.CORPORATE_TRANSACTION_BERI_EMAS_MULTIPLE} element={<BeriEmasMultipleUsersPage />} />
          <Route path={Routing.CORPORATE_GOLD_CONVERSION} element={<ViewCorporateGoldConversion />} />
          <Route path={Routing.CORPORATE_REFERRAL_DATA} element={<ViewCorporateReferralData />} />
          <Route path={Routing.CORPORATE_REFERRAL_TRANSACTION} element={<ViewCorporateReferralTransaction />} />
        </Route>

        {/* DRAFT */}
        <Route path={'/draft'} element={<Draft />} />
      </Routes>
    </>
  );
}

export default App;
