import { BaseModel } from '@/domain/model/base_model';
import { GoldRateModel } from '@/domain/model/gold_rate_model.ts';
import { UserModel } from '@/domain/model/user_model.ts';
import { formatDateTime } from '@/infrastructure/helper/formatDate.ts';
import { formatRupiah } from '@/infrastructure/helper/format_currency.ts';
import { STATUS_TRANSACTION } from '@/domain/constant/transaction/status_transaction.ts';
import { ChipType } from '@/domain/constant/component/chip_colors.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { TransactionVendorModel } from '@/domain/model/transaction_vendor_model.ts';
import { TrxPrintModel } from '@/domain/model/trx_print_model.ts';
import { TrxFeeModel } from '@/domain/model/trx_fee_model.ts';
import { TrxShippingModel } from '@/domain/model/trx_shipping_model.ts';
import { VendingMachineModel } from '@/domain/model/vending_machine_model.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';

interface AmountInterface {
  subtotal: number;
  fee: number;
  total: number;
  coin: number;
  gram: number;
}

interface UserInterface {
  uid: string;
  name: string;
  email: string;
  phone: string;
}

interface UserUpdateInterface {
  uid: string;
  name: string;
  email: string;
  phone: string;
}

interface TransactionDateInterface {
  transactionAt: string;
  succeedAt: string;
  settledAt: string;
  reconAt: string;
}

interface PaymentInterface {
  method: string;
  channel: string;
  vendor: string;
  vendorRefID: string;
}

interface PrintLocationInterface {
  location: string;
  type: string;
}

interface GoldPiecesInterface {
  gram: string;
  quantity: number;
}

interface FeePrintInterface {
  printFee: number;
  deliveryFee: number;
}

interface TrxVendingInterface {
  vendingMachine: VendingMachineModel;
}

export class TransactionModel extends BaseModel {
  userUID: string;
  rateUID: string;
  referenceID: string;
  type: string;
  status: string;
  amount: AmountInterface | null;
  gram: string;
  notes: string;
  flow: string;
  relatedUID: string;
  transactionAt: string;
  settledAt: string;
  succeedAt: string;
  reconAt: string;
  rate: GoldRateModel;
  user: UserModel;
  code: string;
  subTotal: number;
  trxVendor: TransactionVendorModel;
  fee: FeePrintInterface | null;
  paymentChannelUID: string;
  trxPrint: TrxPrintModel[];
  trxFee: TrxFeeModel[];
  trxShipping: TrxShippingModel;
  userEmail: UserInterface | null;
  userUpdate: UserUpdateInterface | null;
  transactionDate: TransactionDateInterface | null;
  payment: PaymentInterface | null;
  printLocation: PrintLocationInterface | null;
  goldPieces: GoldPiecesInterface[] | [];
  amountSingle: number;
  succeedAtSingle: string;
  transactionAtSingle: string;
  feeSingle: number;
  trxDepositChannel: string;
  trxVending: TrxVendingInterface | null;
  senderName: string | null;
  senderEmail: string | null;
  receiverName: string | null;
  receiverEmail: string | null;
  systemNotes: string;
  coin: number;
  updater: ProfileModel;

  constructor(
    ID: bigint,
    UID: string,
    UUID: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    deletedAt: string,
    deletedBy: string,
    flags: string[],
    userUID: string,
    rateUID: string,
    referenceID: string,
    type: string,
    status: string,
    amount: AmountInterface | null,
    gram: string,
    notes: string,
    flow: string,
    relatedUID: string,
    transactionAt: string,
    settledAt: string,
    succeedAt: string,
    reconAt: string,
    rate: GoldRateModel,
    user: UserModel,
    code: string,
    subTotal: number,
    trxVendor: TransactionVendorModel,
    fee: FeePrintInterface | null,
    paymentChannelUID: string,
    trxPrint: TrxPrintModel[],
    trxFee: TrxFeeModel[],
    trxShipping: TrxShippingModel,
    userEmail: UserInterface | null,
    userUpdate: UserUpdateInterface | null,
    transactionDate: TransactionDateInterface | null,
    payment: PaymentInterface | null,
    printLocation: PrintLocationInterface | null,
    goldPieces: GoldPiecesInterface[] | [],
    amountSingle: number,
    succeedAtSingle: string,
    transactionAtSingle: string,
    feeSingle: number,
    trxDepositChannel: string,
    trxVending: TrxVendingInterface | null,
    senderName: string | null,
    senderEmail: string | null,
    receiverName: string | null,
    receiverEmail: string | null,
    systemNotes: string,
    coin: number,
    updater: ProfileModel
  ) {
    super(ID, UID, UUID, createdAt, createdBy, updatedAt, updatedBy, deletedAt, deletedBy, flags);
    this.userUID = userUID;
    this.rateUID = rateUID;
    this.referenceID = referenceID;
    this.type = type;
    this.status = status;
    this.amount = amount;
    this.gram = gram;
    this.notes = notes;
    this.flow = flow;
    this.relatedUID = relatedUID;
    this.transactionAt = transactionAt;
    this.settledAt = settledAt;
    this.succeedAt = succeedAt;
    this.reconAt = reconAt;
    this.rate = rate;
    this.user = user;
    this.code = code;
    this.subTotal = subTotal;
    this.trxVendor = trxVendor;
    this.fee = fee;
    this.paymentChannelUID = paymentChannelUID;
    this.trxPrint = trxPrint;
    this.trxFee = trxFee;
    this.trxShipping = trxShipping;
    this.userEmail = userEmail;
    this.userUpdate = userUpdate;
    this.transactionDate = transactionDate;
    this.payment = payment;
    this.printLocation = printLocation;
    this.goldPieces = goldPieces;
    this.amountSingle = amountSingle;
    this.succeedAtSingle = succeedAtSingle;
    this.transactionAtSingle = transactionAtSingle;
    this.feeSingle = feeSingle;
    this.trxDepositChannel = trxDepositChannel;
    this.trxVending = trxVending;
    this.senderName = senderName;
    this.senderEmail = senderEmail;
    this.receiverName = receiverName;
    this.receiverEmail = receiverEmail;
    this.systemNotes = systemNotes;
    this.coin = coin;
    this.updater = updater;
  }

  get getAmountSingle(): string {
    if (this.amountSingle) {
      return formatRupiah(this.amountSingle);
    }

    return formatRupiah(0);
  }

  get getSubtotalSingle(): string {
    if (this.subTotal) {
      return formatRupiah(this.subTotal);
    }

    return formatRupiah(0);
  }

  get getSucceedAtSingle(): string {
    if (this.succeedAtSingle) {
      return formatDateTime(this.succeedAtSingle);
    }

    return '-';
  }

  get getTransactionAtSingle(): string {
    if (this.transactionAtSingle) {
      return formatDateTime(this.transactionAtSingle);
    }

    return '-';
  }

  get getFeeRupiahSingle(): string {
    if (this.feeSingle) {
      return formatRupiah(this.feeSingle);
    }

    return formatRupiah(0);
  }

  get getCreatedAt(): string {
    if (this.createdAt) {
      return formatDateTime(this.createdAt);
    }

    return '-';
  }

  get getSucceedAt(): string {
    if (this.transactionDate?.succeedAt) {
      return formatDateTime(this.transactionDate?.succeedAt);
    }

    return '-';
  }

  get getTransactionAt(): string {
    if (this.transactionDate?.transactionAt) {
      return formatDateTime(this.transactionDate?.transactionAt);
    }

    return '-';
  }

  get getAmountRupiah(): string {
    if (this.amount?.total) {
      return formatRupiah(this.amount.total);
    }

    return '-';
  }

  get getSubTotalRupiah(): string {
    if (this.amount?.subtotal) {
      return formatRupiah(this.amount?.subtotal);
    }

    return '-';
  }

  get getFeeRupiah(): string {
    if (this.amount?.fee) {
      return formatRupiah(this.amount?.fee);
    }

    return '-';
  }

  get getPrintFeeRupiah(): string {
    if (this.fee?.printFee) {
      return formatRupiah(this.fee?.printFee);
    }

    return 'Rp. 0';
  }

  get getPrintDeliveryRupiah(): string {
    if (this.fee?.deliveryFee) {
      return formatRupiah(this.fee?.deliveryFee);
    }

    return 'Rp. 0';
  }

  get getGram(): string {
    if (this.gram) {
      return this.gram + ' ' + 'gr';
    }

    return '-';
  }

  get getStatusColor(): ChipType {
    if (this.status === STATUS_TRANSACTION.SUCCESS) {
      return 'primary-light';
    }

    if (this.status === STATUS_TRANSACTION.PENDING) {
      return 'warning-light';
    }

    if (this.status === STATUS_TRANSACTION.REJECT) {
      return 'danger-lite';
    }

    if (this.status === STATUS_TRANSACTION.FAILED) {
      return 'danger-light';
    }

    if (this.status === STATUS_TRANSACTION.REQUEST) {
      return 'info-light';
    }

    if (this.status === STATUS_TRANSACTION.AWAITING_PICKUP) {
      return 'warning-light';
    }
  }

  get getTypeColor(): ChipType {
    if (this.type === TYPE_TRANSACTION.BUY) {
      return 'warning';
    }

    if (this.type === TYPE_TRANSACTION.COMMISSION) {
      return 'brown';
    }

    if (this.type === TYPE_TRANSACTION.LOCK) {
      return 'danger';
    }

    if (this.type === TYPE_TRANSACTION.DREAM_GOLD) {
      return 'info';
    }

    if (this.type === TYPE_TRANSACTION.TRANSFER) {
      return 'danger-lite';
    }
  }

  public static goldPiecesFromJsonList(data: any): GoldPiecesInterface[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push({
          gram: data[x].gram,
          quantity: data[x].quantity
        });
      }

      return list;
    } catch {
      return [];
    }
  }

  public static fromJson(data: any): any | TransactionModel {
    try {
      return new TransactionModel(
        data.id,
        data.uid,
        data.uuid,
        data.created_at,
        data.created_by,
        data.updated_at,
        data.updated_by,
        data.deleted_at,
        data.deleted_by,
        data.flags,
        data.user_uid,
        data.rate_uid,
        data.reference_id,
        data.type,
        data.status,
        data.amount
          ? {
              subtotal: data.amount.subtotal,
              fee: data.amount.fee,
              total: data.amount.total,
              coin: data.amount.coin,
              gram: data.amount.gram
            }
          : null,
        data.gram,
        data.notes,
        data.flow,
        data.related_uid,
        data.transaction_at,
        data.settled_at,
        data.succeed_at,
        data.recon_at,
        data.rate ? GoldRateModel.fromJson(data.rate) : null,
        data.user ? UserModel.fromJson(data.user) : null,
        data.code,
        data.subtotal,
        data.trx_vendor ? TransactionVendorModel.fromJson(data.trx_vendor) : null,
        data.fee
          ? {
              printFee: data.fee.print_fee,
              deliveryFee: data.fee.delivery_fee
            }
          : null,
        data.payment_channel_uid,
        data.trx_print ? TrxPrintModel.fromJsonList(data.trx_print) : [],
        data.trx_fee ? TrxFeeModel.fromJsonList(data.trx_fee) : [],
        data.trx_shipping ? TrxShippingModel.fromJson(data.trx_shipping) : null,
        data.user_email
          ? {
              uid: data.user_email.uid,
              name: data.user_email.name,
              email: data.user_email.email,
              phone: data.user_email.phone
            }
          : null,
        data.user_update
          ? {
              uid: data.user_update.uid,
              name: data.user_update.name,
              email: data.user_update.email,
              phone: data.user_update.phone
            }
          : null,
        data.transaction_date
          ? {
              transactionAt: data.transaction_date.transaction_at,
              succeedAt: data.transaction_date.succeed_at,
              settledAt: data.transaction_date.settled_at,
              reconAt: data.transaction_date.recon_at
            }
          : null,
        data.payment
          ? {
              method: data.payment.method,
              channel: data.payment.channel,
              vendor: data.payment.vendor,
              vendorRefID: data.payment.vendor_ref_id
            }
          : null,
        data.print_location
          ? {
              location: data.print_location.location,
              type: data.print_location.type
            }
          : null,
        data.gold_pieces ? TransactionModel.goldPiecesFromJsonList(data.gold_pieces) : [],
        data.amount,
        data.succeed_at,
        data.transaction_at,
        data.fee,
        data.trx_deposit?.deposit_channel?.name,
        data.trx_vending
          ? {
              vendingMachine: VendingMachineModel.fromJson(data.trx_vending.vending_machine)
            }
          : null,
        data?.meta?.sender?.first_name || null,
        data?.meta?.sender?.email || null,
        data?.meta?.receiver?.first_name || null,
        data?.meta?.receiver?.email || null,
        data.system_notes,
        data.coin,
        data.updater ? ProfileModel.fromJson(data.updater) : null
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): TransactionModel[] | any {
    try {
      const list = [];

      for (let x = 0; x < data.length; x++) {
        list.push(TransactionModel.fromJson(data[x]));
      }

      return list;
    } catch {
      return [];
    }
  }
}
