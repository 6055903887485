import { getProfile } from '@/infrastructure/helper/auth.ts';
import { useEffect } from 'react';
import { useCorporateDashboardContext } from '@/delivery/ui/corporate/dashboard/context/corporate_dashboard_context.tsx';
import SimpleCard from '@/delivery/components/molecules/simple_card/simple_card.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { TBox, Theader } from '@/delivery/components/molecules/table/table.tsx';
import CardRate from '@/delivery/components/atoms/card_rate/card_rate.tsx';
import { countRateChange, setUpDown } from '@/infrastructure/helper/count_rate_change.ts';
import { formatDate } from '@/infrastructure/helper/formatDate.ts';

export const CorporateDashboard = () => {
  const profile = getProfile();

  const { goldRateLatest, loadingRateGold, handleShowCorporateDashboard, handleGetLatestGoldRate } =
    useCorporateDashboardContext();

  useEffect(() => {
    handleShowCorporateDashboard({ uid: profile?.user_uuid });
    handleGetLatestGoldRate();
  }, []);

  return (
    <div>
      <div className={'grid grid-cols-4 gap-[24px]'}>
        <SimpleCard title="Status Akun Bisnis" item={<Chips label={'Active'} type={'primary-light'} />} />
        <SimpleCard title="Total Member" bigTitle={'16'} />
        <SimpleCard title="Total Saldo" bigTitle={'4'} unit={'gr'} />
        <SimpleCard title="Total Komisi" bigTitle={'17'} unit={'gr'} />
      </div>
      <div className={'mt-[32px]'}>
        <Theader>
          <div className={'flex items-center gap-[20px]'}>
            <span className="text-[16px] font-[600]">Harga Emas Hari Ini</span>
            <Chips label={formatDate(goldRateLatest?.currentRate.createdAt)} removeDot />
          </div>
        </Theader>
        <TBox>
          <div className="flex flex-col sm:flex-row gap-[24px]">
            <CardRate
              loading={loadingRateGold}
              title="Buy Rate"
              ratePrice={goldRateLatest?.currentRate.getBuy}
              ratePercentage={countRateChange(goldRateLatest?.currentRate.buy, goldRateLatest?.previousRate.buy)}
              arrow={setUpDown(goldRateLatest?.currentRate.buy, goldRateLatest?.previousRate.buy)}
            />
            <CardRate
              loading={loadingRateGold}
              title="Sell Rate"
              ratePrice={goldRateLatest?.currentRate.getSell}
              ratePercentage={countRateChange(goldRateLatest?.currentRate.sell, goldRateLatest?.previousRate.sell)}
              arrow={setUpDown(goldRateLatest?.currentRate.sell, goldRateLatest?.previousRate.sell)}
            />
          </div>
        </TBox>
      </div>
    </div>
  );
};

export default CorporateDashboard;
