import { Theader, TBox, Table, Thead, Tr, Th, Tbody, Td } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import { useParams } from 'react-router-dom';
import { useUserHeirContext } from '@/delivery/ui/admin/data_member/member_list/context/user_heir_context.tsx';
import { UserHeirModel } from '@/domain/model/user_heir_model.ts';
import FormAddEditUserHeir from '@/delivery/ui/admin/data_member/member_list/detail/tabs/heir/form_add_edit.tsx';

const Heir = () => {
  const { uuid } = useParams();
  const { handleAddEditUserHeir, addEditUserHeir, userHeirs } = useUserHeirContext();

  return (
    <div>
      <div className={'mt-[20px]'}>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Ahli Waris
            </Typography>
          </div>

          <div className={'cursor-pointer'} onClick={() => handleAddEditUserHeir({ user_uid: uuid })}>
            <span className={'text-text-primary font-[600] text-[14px] cursor-pointer'}>Add or Edit</span>
          </div>
        </Theader>
        <TBox>
          {addEditUserHeir ? (
            <div>
              <FormAddEditUserHeir />
            </div>
          ) : (
            <div>
              {userHeirs.length > 0 ? (
                <div className={'grid grid-cols-2 gap-[20px]'}>
                  {userHeirs.reverse().map((item: UserHeirModel, index) => (
                    <div className={'flex flex-col gap-[20px]'}>
                      <Table noBorder className="rounded-b-[8px]">
                        <Thead>
                          <Tr noBorder>
                            <Th noBorder>
                              <Typography fontWeight={600} size={14} type="primary-invert">
                                Ahli Waris {index + 1} (satu)
                              </Typography>
                            </Th>
                            <Th noBorder>{/*<Button label={'delete'} onClick={() => handleDeleteHeir(item)} />*/}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {/*nama lengkap*/}
                          <Tr>
                            <Td noBorder>
                              <Typography fontWeight={400} size={14} type="tertiary-invert">
                                Nama Lengkap
                              </Typography>
                            </Td>
                            <Td noBorder>
                              <Typography fontWeight={400} size={14} type="primary-invert">
                                : {item.fullName}
                              </Typography>
                            </Td>
                          </Tr>

                          {/*phone*/}
                          <Tr>
                            <Td noBorder>
                              <Typography fontWeight={400} size={14} type="tertiary-invert">
                                Phone
                              </Typography>
                            </Td>
                            <Td noBorder>
                              <Typography fontWeight={400} size={14} type="primary-invert">
                                : {item.phone}
                              </Typography>
                            </Td>
                          </Tr>

                          {/*hubungan*/}
                          <Tr>
                            <Td noBorder>
                              <Typography fontWeight={400} size={14} type="tertiary-invert">
                                Hubungan
                              </Typography>
                            </Td>
                            <Td noBorder>
                              <Typography fontWeight={400} size={14} type="primary-invert">
                                : {item.relation}
                              </Typography>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </div>
                  ))}

                  {/*<div className={'flex flex-col gap-[20px]'}>*/}
                  {/*  <TableSecondHeir />*/}
                  {/*</div>*/}
                </div>
              ) : (
                <div className={'flex justify-center italic'}>
                  <Typography size={12} fontWeight={400} type={'tertiary-invert'}>
                    Belum memiliki ahli waris
                  </Typography>
                </div>
              )}
            </div>
          )}
        </TBox>
      </div>
    </div>
  );
};

export default Heir;
