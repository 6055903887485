import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import { STATUS_TRANSACTION_LIST } from '@/domain/constant/transaction/status_transaction.ts';
import TypeTransactionList from '@/domain/constant/transaction/type_transaction_list.ts';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/corporate/context/corporate_context.tsx';
import { useEffect, useState } from 'react';

const FilterMyTransactionAffiliate = () => {
  const { formFilterMyTransactionAffiliate, setFormFilterMyTransactionAffiliate, handleFetchMyTransactionAffiliate } =
    useCorporateContext();

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterMyTransactionAffiliate({
      search: '',
      status: '',
      type: '',
      transaction_at_start: '',
      transaction_at_end: ''
    });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchMyTransactionAffiliate({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterMyTransactionAffiliate, isResetting]);

  return (
    <div>
      <div className="flex flex-col sm:flex-row gap-[12px]">
        <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
        <ButtonFilter
          onClickApply={() => handleFetchMyTransactionAffiliate({})}
          size={'small'}
          titleFilter={'Filter Transaction'}
          label={'More Filter'}
        >
          <div className={'flex flex-col gap-[20px]'}>
            <TextField
              value={formFilterMyTransactionAffiliate.search}
              search
              placeholder={'Search...'}
              block
              onChange={e =>
                setFormFilterMyTransactionAffiliate({ ...formFilterMyTransactionAffiliate, search: e.target.value })
              }
            />

            <div className={'grid grid-cols-2 gap-[20px]'}>
              <Select2
                value={formFilterMyTransactionAffiliate.status}
                isSearchable={false}
                options={STATUS_TRANSACTION_LIST.map(item => {
                  return {
                    label: item.name,
                    value: item.UUID
                  };
                })}
                block
                label={'Status'}
                onChange={e =>
                  setFormFilterMyTransactionAffiliate({ ...formFilterMyTransactionAffiliate, status: e?.value })
                }
              />
              <Select2
                value={formFilterMyTransactionAffiliate.type}
                isSearchable={false}
                options={TypeTransactionList.map(item => {
                  return {
                    label: item.name,
                    value: item.UUID
                  };
                })}
                block
                label={'Type'}
                onChange={e =>
                  setFormFilterMyTransactionAffiliate({ ...formFilterMyTransactionAffiliate, type: e?.value })
                }
              />
              <Datepicker
                value={formFilterMyTransactionAffiliate.transaction_at_start}
                label={'Start Date'}
                block
                onChange={e =>
                  setFormFilterMyTransactionAffiliate({
                    ...formFilterMyTransactionAffiliate,
                    transaction_at_start: e
                  })
                }
              />
              <Datepicker
                value={formFilterMyTransactionAffiliate.transaction_at_end}
                label={'End Date'}
                block
                onChange={e =>
                  setFormFilterMyTransactionAffiliate({
                    ...formFilterMyTransactionAffiliate,
                    transaction_at_end: e
                  })
                }
              />
            </div>
          </div>
        </ButtonFilter>
      </div>
    </div>
  );
};

export default FilterMyTransactionAffiliate;
