import React, { createContext, useContext, ReactNode, useState } from 'react';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { ProfileRepository } from '@/domain/repository/profile_repository.ts';
import { ProfileModel } from '@/domain/model/profile_model.ts';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { TransactionRepository } from '@/domain/repository/transaction_repository.ts';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateTransactionListValueParams {
  page?: number;
  limit?: number;
  user_uuid?: string;
  file_beri_emas_multiple?: any;
}

interface CorporateTransactionListValue {
  transactionUpload: TransactionModel[];
  profile?: ProfileModel;
  corporateTransactionList: TransactionModel[];
  corporateTransactionListPagination?: PaginationModel;
  handleFetchCorporateTransactionList: (_data: CorporateTransactionListValueParams) => void;
  handleShowProfile: (_data: CorporateTransactionListValueParams) => void;
  handleStoreTransactionPayrollMultiple: (_data: CorporateTransactionListValueParams) => Promise<any>;
  handleStoreTransactionPayrollMultipleConfirm: (_data: CorporateTransactionListValueParams) => void;

  handleAddTransactionModal: ModalObject;
  handleAddBeriEmasModal: ModalObject;
}

const CorporateTransactionListContext = createContext<CorporateTransactionListValue | null>(null);

//REPOSITORY HERE
const corporateTrxRep = new CorporateRepository();
const profileRep = new ProfileRepository();
const transactionRep = new TransactionRepository();

const CorporateTransactionListProvider: React.FC<DialogContextProps> = ({ children }) => {
  const navigation = useNavigate();

  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  //const handleModal = useVisibleComponent(false);
  const [profile, setProfile] = useState<ProfileModel>();

  const [corporateTransactionList, setCorporateTransactionList] = useState<TransactionModel[]>([]);
  const [corporateTransactionListPagination, setCorporateTransactionListPagination] = useState<PaginationModel>();

  const [transactionUpload, setTransactionUpload] = useState<TransactionModel[]>([]);

  const handleAddTransactionModal = useVisibleComponent(false);
  const handleAddBeriEmasModal = useVisibleComponent(false);

  //FUNCTION HERE BELOW
  const handleFetchCorporateTransactionList = async (_data: CorporateTransactionListValueParams) => {
    const params = {
      page: _data.page ?? 1,
      limit: _data.limit ?? 10
    };
    await corporateTrxRep
      .fetchCorporateMyTransactions(params)
      .then((result: any) => {
        setCorporateTransactionList(result.data);
        setCorporateTransactionListPagination(result.pagination);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW USER
  const handleShowProfile = async (_data: CorporateTransactionListValueParams) => {
    const params = {
      uuid: _data.user_uuid
    };
    await profileRep
      .showProfile(params)
      .then((result: any) => {
        setProfile(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE UPLOAD FILE PAYROLL
  const handleStoreTransactionPayrollMultiple = async (_data: CorporateTransactionListValueParams) => {
    const x = alertToast.loadingAlert('');
    const params = {
      parent_uid: _data.user_uuid,
      file: _data.file_beri_emas_multiple,
      preview: true
    };

    try {
      const result: any = await transactionRep.storeTransactionPayroll(params);
      alertToast.updateLoading(x);
      alertToast.successAlert('Upload Success');
      setTransactionUpload(result);
      return result;
    } catch (error: any) {
      alertToast.updateLoading(x);
      alertToast.errorAlert(error);
      return Promise.reject(error);
    }
  };

  //HANDLE UPLOAD BERI EMAS CONFIRM
  const handleStoreTransactionPayrollMultipleConfirm = (_data: CorporateTransactionListValueParams) => {
    alertSweet.questionAlert().then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');

        const params = {
          parent_uid: _data.user_uuid,
          file: _data.file_beri_emas_multiple,
          preview: false
        };

        transactionRep
          .storeTransactionPayroll(params)
          .then(() => {
            alertToast.updateLoading(x);
            alertToast.successAlert('Beri Emas Success');

            setTimeout(() => {
              navigation(Routing.CORPORATE_TRANSACTION);
            }, 1000);
          })
          .catch(error => {
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: CorporateTransactionListValue = {
    transactionUpload,
    handleAddTransactionModal,
    handleAddBeriEmasModal,
    profile,
    corporateTransactionList,
    corporateTransactionListPagination,
    handleFetchCorporateTransactionList,
    handleStoreTransactionPayrollMultiple,
    handleStoreTransactionPayrollMultipleConfirm,
    handleShowProfile
  };

  return (
    <CorporateTransactionListContext.Provider value={contextValue}>{children}</CorporateTransactionListContext.Provider>
  );
};

const useCorporateTransactionListContext = (): CorporateTransactionListValue => {
  const context = useContext(CorporateTransactionListContext);
  if (!context) {
    throw new Error('useCorporateTransactionListContext must be used within a CorporateTransactionListProvider');
  }
  return context;
};

export { CorporateTransactionListProvider, useCorporateTransactionListContext };
