import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';

import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import DataProfile from '@/delivery/ui/admin/data_member/member_list/detail/tabs/data_profile/data_profile.tsx';
import Balance from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance/balance.tsx';
import Heir from '@/delivery/ui/admin/data_member/member_list/detail/tabs/heir/heir.tsx';
import BusinessAccount from '@/delivery/ui/admin/data_member/member_list/detail/tabs/business_account/business_account.tsx';
import Referral from '@/delivery/ui/admin/data_member/member_list/detail/tabs/referral/referral.tsx';

import { Routing } from '@/domain/constant/router_path.ts';
import { useMemberListContext } from '@/delivery/ui/admin/data_member/member_list/context/member_list_context.tsx';
import TableBalanceLockHistory from '@/delivery/ui/admin/data_member/member_list/detail/tabs/balance_lock_history/table_balance_lock_history.tsx';
import { useBalanceContext } from '@/delivery/ui/admin/data_member/member_list/context/balance_context.tsx';
import { useUserHeirContext } from '@/delivery/ui/admin/data_member/member_list/context/user_heir_context.tsx';
import { useBusinessAccountContext } from '@/delivery/ui/admin/data_member/member_list/context/business_account_context.tsx';
import { useReferralContext } from '@/delivery/ui/admin/data_member/member_list/context/referral_context.tsx';

const DetailMember = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { user, handleShowUser, handleShowUserVerification } = useMemberListContext();
  const { handleShowBalance } = useBalanceContext();
  const { handleFetchBalanceLock } = useBalanceContext();
  const { handleFetchUserHeir } = useUserHeirContext();
  const { handleShowCorporate } = useBusinessAccountContext();
  const { handleGetDataUserChildAffiliate, handleGetDataUserParentAffiliate } = useReferralContext();

  const [tabPage, setTabPage] = useState(0);

  //tab profile
  useEffect(() => {
    if (tabPage === 0) {
      if (uuid) {
        handleShowUser({ uuid: uuid });
      }
    }
  }, [uuid, tabPage]);

  useEffect(() => {
    if (tabPage === 0) {
      if (user?.userVerification) {
        handleShowUserVerification(user.userVerification);
      }
    }
  }, [user?.userVerification, tabPage]);

  //tab balance
  useEffect(() => {
    if (tabPage === 1) {
      if (uuid) {
        handleShowBalance({ user_uid: uuid });
      }
    }
  }, [uuid, tabPage]);

  //tab balance lock
  useEffect(() => {
    if (tabPage === 2) {
      if (uuid) {
        handleFetchBalanceLock({ user_uid: uuid });
      }
    }
  }, [uuid, tabPage]);

  //heir
  useEffect(() => {
    if (tabPage === 3) {
      if (uuid) {
        handleFetchUserHeir({ user_uid: uuid });
      }
    }
  }, [uuid, tabPage]);

  //business account

  useEffect(() => {
    if (tabPage === 4) {
      if (uuid) {
        handleShowUser({ uuid: uuid });
      }
    }
  }, [uuid, tabPage]);

  useEffect(() => {
    if (tabPage === 4) {
      if (user?.corporate) {
        handleShowCorporate({ uid: user?.corporate.UID });
      }
    }
  }, [user?.corporate, tabPage]);

  //referral
  useEffect(() => {
    if (tabPage === 5) {
      if (uuid) {
        handleGetDataUserParentAffiliate({ user_uid: uuid });
        handleGetDataUserChildAffiliate({ user_uid: uuid });
      }
    }
  }, [uuid, tabPage]);

  const data = [
    {
      label: 'Data Profile',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Balance',
      index: 1
      // handleClick: () => {}
    },
    {
      label: 'Balance Lock History',
      index: 2
      // handleClick: () => {}
    },
    {
      label: 'Ahli Waris',
      index: 3
      // handleClick: () => {}
    },
    {
      label: 'Akun Bisnis',
      index: 4
      // handleClick: () => {}
    },
    {
      label: 'Referral',
      index: 5
      // handleClick: () => {}
    }
  ];

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.DATA_MEMBER.MEMBER_LIST)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to member list
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Member Detail
        </Typography>
      </div>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <DataProfile />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <Balance />
        </TabContent>
        <TabContent index={2} tab={tabPage}>
          <TableBalanceLockHistory />
        </TabContent>
        <TabContent index={3} tab={tabPage}>
          <Heir />
        </TabContent>
        <TabContent index={4} tab={tabPage}>
          <BusinessAccount />
        </TabContent>
        <TabContent index={5} tab={tabPage}>
          <Referral />
        </TabContent>
      </div>
    </div>
  );
};

export default DetailMember;
