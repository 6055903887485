import { useEffect, useState } from 'react';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import { useCorporateContext } from '@/delivery/ui/admin/corporate/context/corporate_context.tsx';
import MyTransaction from '@/delivery/ui/admin/corporate/tab/my_transaction/my_transaction.tsx';
import MyTransactionAffiliate from '@/delivery/ui/admin/corporate/tab/my_transaction_affiliate/my_transaction_affiliate.tsx';
import MyAffiliate from '@/delivery/ui/admin/corporate/tab/my_affiliate/my_affiliate.tsx';
import DetailCorporate from '@/delivery/ui/admin/corporate/tab/detail_corporate/detail_corporate.tsx';
import { getProfile } from '@/infrastructure/helper/auth.ts';

const Corporate = () => {
  const profile = getProfile();
  const { handleShowCorporate, handleFetchMyAffiliate, handleFetchMyTransaction, handleFetchMyTransactionAffiliate } =
    useCorporateContext();

  const [tabPage, setTabPage] = useState(0);

  const data = [
    {
      label: 'Detail',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'My Transaction',
      index: 1
      // handleClick: () => {}
    },
    {
      label: 'My Transaction Affiliate',
      index: 2
      // handleClick: () => {}
    },
    {
      label: 'My Affiliate',
      index: 3
      // handleClick: () => {}
    }
  ];

  useEffect(() => {
    if (tabPage === 0) {
      handleShowCorporate({ uid: profile?.user_uuid });
    }
    if (tabPage === 1) {
      handleFetchMyTransaction({ page: 1 });
    }
    if (tabPage === 2) {
      handleFetchMyTransactionAffiliate({ page: 1 });
    }
    if (tabPage === 3) {
      handleFetchMyAffiliate({ page: 1 });
    }
  }, [tabPage]);

  return (
    <div>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <DetailCorporate />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <MyTransaction />
        </TabContent>
        <TabContent index={2} tab={tabPage}>
          <MyTransactionAffiliate />
        </TabContent>
        <TabContent index={3} tab={tabPage}>
          <MyAffiliate />
        </TabContent>
      </div>
    </div>
  );
};

export default Corporate;
