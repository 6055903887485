import { useEffect } from 'react';
import CorporateReferralDataPage from '@/delivery/ui/corporate/referral/referral_data';
import { CorporateReferralDataProvider } from '@/delivery/ui/corporate/referral/referral_data/context/corporate_referral_data_context.tsx';

const ViewCorporateReferralData = () => {
  useEffect(() => {
    document.title = 'Data Referral';
  }, []);

  return (
    <div>
      <CorporateReferralDataProvider>
        <CorporateReferralDataPage />
      </CorporateReferralDataProvider>
    </div>
  );
};

export default ViewCorporateReferralData;
