import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import { Routing } from '@/domain/constant/router_path.ts';
import DetailPaymentChannel from '@/delivery/ui/admin/settings/open_channel/detail/payment_channel/detail_payment_channel.tsx';
import { OpenChannelProvider } from '@/delivery/ui/admin/settings/open_channel/context/open_channel_context.tsx';
import DetailPaymentChannelFee from '@/delivery/ui/admin/settings/open_channel/detail/payment_channel_fee/detail_payment_channel_fee.tsx';
import PaymentInstruction from '@/delivery/ui/admin/settings/open_channel/detail/payment_instruction/payment_instruction.tsx';

const DetailOpenChannelPage = () => {
  const navigate = useNavigate();
  const [tabPage, setTabPage] = useState(0);
  const data = [
    {
      label: 'Payment Channel',
      index: 0
      // handleClick: () => {}
    },
    {
      label: 'Payment Fee',
      index: 1
      // handleClick: () => {}
    },
    {
      label: 'Payment Instruction',
      index: 2
      // handleClick: () => {}
    }
  ];
  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.SETTINGS.OPEN_CHANNEL)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to payment channel
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Payment Channel Detail
        </Typography>
      </div>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <OpenChannelProvider>
            <DetailPaymentChannel />
          </OpenChannelProvider>
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <OpenChannelProvider>
            <DetailPaymentChannelFee />
          </OpenChannelProvider>
        </TabContent>
        <TabContent index={2} tab={tabPage}>
          <OpenChannelProvider>
            <PaymentInstruction />
          </OpenChannelProvider>
        </TabContent>
      </div>
    </div>
  );
};

export default DetailOpenChannelPage;
